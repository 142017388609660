import ClassicTableHeader from './ClassicTableHeader';
import ClassicTableColumns from './ClassicTableColumns';
import ClassicTableRows from './ClassicTableRows';
import styles from './ClassicTable.module.scss';
import { ClassicTableProps } from './types';
import { DisplayType, displayTypeValue } from '../../pages/App';
import classNames from 'classnames';

/**
 * Компонент классической таблицы
 */
const ClassicTable = <T extends Record<string, any>, K extends keyof T>(
  props: ClassicTableProps<T, K>,
) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const { columns } = props;

  return (
    <div className={classNames(styles.ClassicTable, isLaptop && styles.laptop)}>
      <ClassicTableHeader {...props} />
      <table className={styles.table}>
        <thead className={styles.header}>
          <ClassicTableColumns
            columns={columns}
            sortOrder={props.sortOrder}
            filters={props.filters}
            withCheckbox={props.withCheckbox}
          />
        </thead>
        <ClassicTableRows {...props} />
      </table>
    </div>
  );
};

export default ClassicTable;
