import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from '../handlers';

export type FrontNotificationState = {
  notifications: NotificationType[];
};

const initialState: FrontNotificationState = {
  notifications: [],
};

export const frontNotificationSlice = createSlice({
  name: 'frontNotification',
  initialState,
  reducers: {
    addFrontNotification(state, action: PayloadAction<NotificationType>) {
      const arr = [...state.notifications];
      arr.push(action.payload);

      state.notifications = arr;
    },
  },
  // extraReducers: (builder) => {},
});

export const { addFrontNotification } = frontNotificationSlice.actions;

export const frontNotificationReducer = frontNotificationSlice.reducer;
