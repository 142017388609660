import React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MachineSnackFillingTableContent.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { MachineSnackFillingTableContentProps } from './types';
import ContentCard from '../../../../../../components/ContentCard';
import MachineSnackFillingTableRow from './MachineSnackFillingTableRow';
import { EditMachineStorageSnackProductCell } from '../../../../../../types/serverInterface/machineDTO';

/**
 * Основная часть таблицы с наполнением снек автомата
 */
const MachineSnackFillingTableContent: FC<MachineSnackFillingTableContentProps> = ({
  groupedSnackCells,
  snackBrandList,
  snackProductsWithPrices,
  handleBrandChange,
  handleProductChange,
  handlePriceChange,
  fillingFormErrors,
  priceMapErrors,
}) => {
  const { t } = useTranslation();

  //render метод
  const renderSnackCells = (
    infoSnackCells: EditMachineStorageSnackProductCell[],
    rowIndex: number,
  ) =>
    infoSnackCells.map((infoSnackCell, cellIndexInRow) => {
      const { productId } = infoSnackCell;
      const priceWithId = snackProductsWithPrices[productId as number];

      const cellErrors = fillingFormErrors?.[rowIndex]?.[cellIndexInRow] || {};
      const priceError = priceMapErrors?.[productId as number] || {};

      return (
        <MachineSnackFillingTableRow
          key={infoSnackCell.id}
          cellIndexInRow={cellIndexInRow}
          info={infoSnackCell}
          snackBrandList={snackBrandList}
          priceWithId={priceWithId}
          onBrandChange={handleBrandChange(rowIndex)(cellIndexInRow)}
          onProductChange={handleProductChange(rowIndex)(cellIndexInRow)}
          onPriceChange={handlePriceChange}
          cellError={cellErrors}
          priceError={priceError}
        />
      );
    });

  return (
    <div className={styles.MachineSnackFillingTableWrapper}>
      <tbody className={styles.content}>
        {groupedSnackCells.map((row, rowIndex) => (
          <ContentCard className={styles.ContentCard} key={rowIndex}>
            <div className={styles.ContentCardHeader}>
              <Text view="secondary" size="s" weight="semibold">
                {rowIndex + 1} {t('machineControl.machine.snackFilling.table.content.row.title')}
              </Text>
            </div>
            {renderSnackCells(row, rowIndex)}
          </ContentCard>
        ))}
      </tbody>
    </div>
  );
};

export default MachineSnackFillingTableContent;
