import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectMachineStorageInfo } from '../../../../state/machineControl/selectors';
import { getMachineStorageInfoAction } from '../../../../state/machineControl/actions';
import { MachineStorageInfoSnackCell } from '../../../../types/serverInterface/machineDTO';

/**
 * Преобразование списка товаров для снек автомата
 *
 * @param machineId id автомата
 */
export const useMachineSnackRefill = (
  machineId: number,
): [MachineStorageInfoSnackCell[][], number[]] => {
  const dispatch = useAppDispatch();

  const { state: machineStorageInfo } = useAppSelector(selectMachineStorageInfo());

  useEffect(() => {
    if (machineId) {
      dispatch(getMachineStorageInfoAction(machineId));
    }
  }, [dispatch, machineId]);

  return useMemo(() => {
    const maxRowNumber =
      machineStorageInfo?.snackCells.reduce((max, cell) => Math.max(max, cell.rowNumber), 0) || 0;

    const cellsByRow: MachineStorageInfoSnackCell[][] = Array.from(
      { length: maxRowNumber + 1 },
      () => [],
    );

    machineStorageInfo?.snackCells.forEach((cell) => {
      const { rowNumber } = cell;
      cellsByRow[rowNumber].push(cell);
    });

    const sortedSnackSchemeStorage = cellsByRow.filter((row) => row.length > 0);

    const maxMotorsLengths = sortedSnackSchemeStorage.map((row) =>
      row.reduce((acc, cell) => acc + cell.motorIds.length, 0),
    );

    return [sortedSnackSchemeStorage, maxMotorsLengths];
  }, [machineStorageInfo]);
};
