import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import DevTable from './DevTable';
import DevTableTest from './DevTableTest';

/**
 * Страница для тестирования новых компонентов
 */
const DevPage: FC = () => {
  return (
    <Routes>
      <Route path="/table" element={<DevTable />} />
      <Route path="/tableTest" element={<DevTableTest />} />
    </Routes>
  );
};

export default DevPage;
