import { useMemo } from 'react';
import { MachineSnackCell } from '../../../../../types/serverInterface/machineListDTO';
import { SortedSnackMatrix, useSortedSnackMatrixProps } from './types';

/**
 * Хук для сортировки массива продуктов снек
 */
const useSortedSnackMatrix = (
  snackCells: useSortedSnackMatrixProps,
): [SortedSnackMatrix, number[]] => {
  const sortedSnackMatrix = useMemo(() => {
    const compareByMinMotorId = (a: MachineSnackCell, b: MachineSnackCell) => {
      const minMotorIdA = Math.min(...a.motorIds);
      const minMotorIdB = Math.min(...b.motorIds);
      return minMotorIdA - minMotorIdB;
    };

    const rowMap = snackCells.reduce<Record<number, MachineSnackCell[]>>((acc, cell) => {
      const { rowNumber, motorIds, ...rest } = cell;
      if (!acc[rowNumber]) {
        acc[rowNumber] = [];
      }
      acc[rowNumber].push({ ...rest, motorIds, rowNumber });
      return acc;
    }, {});

    const sortedRowArrays = Object.values(rowMap).map((rowArray) =>
      rowArray.sort(compareByMinMotorId),
    );

    const transformedRowArrays = sortedRowArrays.map((rowArray) =>
      rowArray.map((cell) => ({
        ...cell,
        motorsLength: cell.motorIds.length,
      })),
    );

    return transformedRowArrays.sort((a, b) => a[0].rowNumber - b[0].rowNumber);
  }, [snackCells]);

  const maxMotorsLengths = useMemo(() => {
    return sortedSnackMatrix.map((row) => row.reduce((acc, cell) => acc + cell.motorsLength, 0));
  }, [sortedSnackMatrix]);

  return [sortedSnackMatrix, maxMotorsLengths];
};

export default useSortedSnackMatrix;
