import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import { getSnackBrandListAction } from '../../../state/productBase/actions';
import { selectSnackBrandList } from '../../../state/productBase/selectors';
import HorizontalContainer from '../../../components/HorizontalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { useTranslation } from 'react-i18next';
import VerticalContainer from '../../../components/VerticalContainer';
import SnackBrandList from './SnackBrandList';
import CreateSnackProduct from './CreateSnackProduct';

/**
 * Базовая страница списка базы товаров(СНЕК)
 */
const SnackProductBaseList: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const organizationId = getOrganizationId();

  const { state } = useAppSelector(selectSnackBrandList());

  const [searchText, setSearchText] = useState<string | null>(null);
  const [isCreateSnackProductOpen, setIsCreateSnackProductOpen] = useState(false);

  const filterBrandList = useMemo(() => {
    if (!searchText) return state?.brandList || [];

    return state
      ? state.brandList?.filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase()))
      : [];
  }, [searchText, state]);

  useEffect(() => {
    organizationId && dispatch(getSnackBrandListAction(organizationId));
  }, [dispatch, organizationId]);

  // Обработчики
  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchText(value);
  };

  const handleAddButtonClick = () => {
    setIsCreateSnackProductOpen(true);
  };

  const handleCloseCreateSnackProduct = () => {
    setIsCreateSnackProductOpen(false);
  };

  // render методы
  const renderHeader = () => (
    <HorizontalContainer>
      <Button
        label={t('snackProductBase.add.button.label')}
        iconLeft={IconAdd as any}
        onClick={handleAddButtonClick}
      />
      <TextField
        placeholder={t('snackProductBase.search.input.placeholder')}
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchText}
        onChange={handleSearchChange}
      />
    </HorizontalContainer>
  );

  const renderContent = () => <SnackBrandList brandList={filterBrandList} />;

  const renderCreateSnackProduct = () => {
    return (
      isCreateSnackProductOpen && (
        <CreateSnackProduct
          brandList={filterBrandList}
          organizationId={organizationId}
          onClose={handleCloseCreateSnackProduct}
        />
      )
    );
  };

  return (
    <VerticalContainer>
      {renderHeader()}
      {renderContent()}
      {renderCreateSnackProduct()}
    </VerticalContainer>
  );
};

export default SnackProductBaseList;
