import React, { FC, useEffect, useMemo, useState } from 'react';
import VerticalContainer from '../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconProfileAccepted } from '../../../../assets/icon/iconProfileAccepted';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  toggleKeyAction,
  getEmployeeWithKeyListAction,
} from '../../../../state/organization/actions';
import TableCardWithBackground from '../../../../components/TableCardWithBackground';
import { useTranslation } from 'react-i18next';
import { IconQR } from '../../../../assets/icon/iconQR';
import { IconLock } from '../../../../assets/icon/iconLock';
import { IconUnlock } from '../../../../assets/icon/iconUnlock';
import { IconEdit2 } from '../../../../assets/icon/iconEdit2';
import { useNavigate } from 'react-router-dom';
import AddKeys from './AddKeys';
import { selectEmployeeWithKeysList } from '../../../../state/organization/selectors';
import QrKeyModal from '../QrKeyModal';
import { EmployeeWithKeyDTO } from '../../../../types/serverInterface/employeeWithKeyDTO';
import BlockUnblockModal from '../BlockUnblockModal';
import { KeysTableProps } from './types';

/**
 * Таблица ключей доступов к автоматам
 */
const KeysTable: FC<KeysTableProps> = ({ organizationId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const keysList = useAppSelector(selectEmployeeWithKeysList());

  const [searchQuery, setSearchQuery] = useState('');
  const [isAddKeyOpen, setIsAddKeyOpen] = useState(false);
  const [isOpenQrUuid, setIsOpenQrUuid] = useState<string | null>(null);
  const [selectedKeyForToggle, setSelectedKeyForToggle] = useState<EmployeeWithKeyDTO | null>(null);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isBlockAction, setIsBlockAction] = useState(false);

  const filterKeysList = useMemo(() => {
    return keysList.filter(({ name, surname, patronymic }) =>
      `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, keysList]);

  const keysMapByUuid = useMemo(() => {
    const map: Record<string, EmployeeWithKeyDTO> = {};

    keysList.forEach((key) => {
      map[key.uuid] = key;
    });

    return map;
  }, [keysList]);

  const selectedKey = isOpenQrUuid && keysMapByUuid[isOpenQrUuid];

  useEffect(() => {
    dispatch(getEmployeeWithKeyListAction(organizationId));
  }, [dispatch, organizationId]);

  // Обработчики
  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleBlockClick = (isActiveKey: boolean, uuid: string) => {
    const selectedKey = keysMapByUuid[uuid];
    setSelectedKeyForToggle(selectedKey);
    setIsBlockAction(isActiveKey);
    setIsBlockModalOpen(true);
  };

  const handleQrModalOpen = ({ uuid }: EmployeeWithKeyDTO) => {
    setIsOpenQrUuid(uuid);
  };

  const handleQrModalClose = () => {
    dispatch(getEmployeeWithKeyListAction(organizationId)).then(() => {
      setIsOpenQrUuid(null);
    });
  };

  const handleAddClose = () => {
    setIsAddKeyOpen(false);
  };

  const handleAddKeyOpen = () => {
    setIsAddKeyOpen(true);
  };

  const handleBlockUnblockModalClose = () => {
    setIsBlockModalOpen(false);
  };

  const handleModalSubmit = () => {
    if (selectedKeyForToggle) {
      dispatch(toggleKeyAction(selectedKeyForToggle.key, organizationId)).then(() => {
        setIsBlockModalOpen(false);
      });
    }
  };

  // render методы
  const renderTableActions = () => (
    <HorizontalContainer space="xl">
      <Button
        label={t('organization.employees.keys.add.button.label')}
        iconLeft={IconProfileAccepted as any}
        onClick={handleAddKeyOpen}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </HorizontalContainer>
  );

  const renderQrModal = () =>
    selectedKey && (
      <QrKeyModal
        isOpen={!!selectedKey}
        organizationId={organizationId}
        userUuid={selectedKey.uuid}
        isActive={selectedKey.isActiveKey}
        userName={`${selectedKey.surname || ''} ${selectedKey.name || ''} ${
          selectedKey.patronymic || ''
        }`}
        onClose={handleQrModalClose}
      />
    );

  const renderBlockUnblockModal = () => (
    <BlockUnblockModal
      isOpen={isBlockModalOpen}
      isBlockAction={isBlockAction}
      onClose={handleBlockUnblockModalClose}
      onSubmit={handleModalSubmit}
    />
  );

  const renderTable = () => (
    <TableCardWithBackground
      rows={filterKeysList}
      columns={{
        key: {
          key: 'key',
          title: t('organization.employees.keys.table.key.column.title'),
          alignment: 'left',
          width: '6%',
          renderCell: (data) => (
            <Button
              view="clear"
              size="m"
              onlyIcon
              iconLeft={IconQR as any}
              onClick={(e) => {
                e.stopPropagation();
                handleQrModalOpen(data);
              }}
            />
          ),
        },
        name: {
          key: 'name',
          title: t('organization.employees.keys.table.fullName.column.title'),
          alignment: 'left',
          width: '28%',
          getItemLabel: ({ name, surname, patronymic }) =>
            `${surname || ''} ${name || ''} ${patronymic || ''}`,
        },
        position: {
          key: 'position',
          title: t('organization.employees.keys.table.role.column.title'),
          alignment: 'left',
          width: '28%',
          getItemLabel: ({ position }) => t(`roles.${position}`),
        },
        lastActivity: {
          key: 'lastActivity',
          title: t('organization.employees.keys.table.lastActivity.column.title'),
          alignment: 'left',
          width: '26%',
          getItemLabel: ({ lastActivity }) => lastActivity,
        },
        uuid: {
          key: 'uuid',
          title: '',
          alignment: 'left',
          width: '10%',
          renderCell: ({ isActiveKey, uuid }) => (
            <HorizontalContainer space="s">
              <Button onlyIcon view="clear" size="m" iconLeft={IconEdit2 as any} />
              <Button
                onlyIcon
                iconLeft={(isActiveKey ? IconUnlock : IconLock) as any}
                view="clear"
                size="m"
                onClick={(e) => {
                  e.stopPropagation();
                  handleBlockClick(isActiveKey, uuid);
                }}
              />
            </HorizontalContainer>
          ),
        },
      }}
      onRowClick={({ uuid }) => {
        navigate(`/organization/members/memberWithKey/${uuid}`);
      }}
    />
  );

  return (
    <VerticalContainer space="3xl">
      <AddKeys isOpen={isAddKeyOpen} organizationId={organizationId} onClose={handleAddClose} />
      {renderQrModal()}
      {renderBlockUnblockModal()}
      {renderTableActions()}
      {renderTable()}
    </VerticalContainer>
  );
};

export default KeysTable;
