import { FC } from 'react';
import StorageSchemeGroup from './StorageSchemeGroup';
import styles from './MachineStorage.module.scss';
import { MachineStoragePageTypeOld } from './MachineStorage';
import { SeparatedMachineStorageHomeType, SeparatedMachineStorageType } from './types';
import { ProductGroup } from '../../../types/serverInterface/storageDTO';
import { MachineCellGroup, MachineCellGroupUnion } from '../../../types/serverInterface/machineDTO';
import stylesGroup from './StorageSchemeGroup/StorageSchemeGroup.module.scss';
import classNames from 'classnames';
import StorageSchemeCard from './StorageSchemeGroup/StorageSchemeCard';
import VerticalContainer from '../../VerticalContainer';

/**
 * Свойства компонента MachineStorageScheme
 */
type MachineStorageSchemeProps = {
  /**
   * Тип страницы
   */
  pageType: MachineStoragePageTypeOld;
  /**
   * Распределённый по группам склад автомата
   */
  storage: SeparatedMachineStorageType | SeparatedMachineStorageHomeType;
  /**
   * Массив групп в порядке сортировки по номеру контейнера
   */
  sortedGroup: Array<ProductGroup>;
  /**
   * Обработчик нажатия на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onClick: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчик hover на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHover: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчки hover leave на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHoverLeave: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчик пополнения контейнера
   */
  onValueChange: (
    group: MachineCellGroupUnion,
  ) => (index: number) => (value: number | string | null) => void;
};

/**
 * Схема склада автомата
 */
const MachineStorageScheme: FC<MachineStorageSchemeProps> = ({
  pageType,
  storage,
  sortedGroup,
  onClick,
  onHover,
  onHoverLeave,
  onValueChange,
}) => {
  const isRefill = pageType === MachineStoragePageTypeOld.REFILL;

  // render методы
  const renderProduct = () =>
    sortedGroup
      .filter((group) => storage.cells[group]?.length !== 1)
      .map((group) => (
        <StorageSchemeGroup
          key={group}
          pageType={pageType}
          storage={storage.cells[group]}
          group={group}
          onClick={onClick}
          onHover={onHover}
          onHoverLeave={onHoverLeave}
          onValueChange={onValueChange}
        />
      ));

  const renderSingleProduct = () =>
    sortedGroup
      .filter((group) => storage.cells[group]?.length === 1)
      .map((group) => (
        <StorageSchemeGroup
          key={group}
          pageType={pageType}
          storage={storage.cells[group]}
          group={group}
          isHorizontal
          onClick={onClick}
          onHover={onHover}
          onHoverLeave={onHoverLeave}
          onValueChange={onValueChange}
        />
      ));

  const renderDisposable = () => (
    <div className={stylesGroup.horizontalGroup}>
      {storage.cellWaters?.map((storage, index) => (
        <div
          key={index}
          className={classNames(
            stylesGroup.storageSchemeGroup,
            stylesGroup.horizontal,
            isRefill && stylesGroup.isRefill,
          )}
        >
          <StorageSchemeCard
            pageType={pageType}
            storageCell={storage}
            isHorizontal
            cellGroup={MachineCellGroup.WATER}
            onValueChange={onValueChange(MachineCellGroup.WATER)(index)}
          />
        </div>
      ))}
      {storage.cellCups?.map((storage, index) => (
        <div
          key={index}
          className={classNames(stylesGroup.storageSchemeGroup, stylesGroup.horizontal)}
        >
          <StorageSchemeCard
            pageType={pageType}
            storageCell={storage}
            isHorizontal
            cellGroup={MachineCellGroup.CUP}
            onValueChange={onValueChange(MachineCellGroup.CUP)(index)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.scheme}>
      <VerticalContainer space="l">
        <div className={classNames(styles.product, isRefill && styles.isRefill)}>
          {renderProduct()}
        </div>
        {renderSingleProduct()}
        {renderDisposable()}
      </VerticalContainer>
    </div>
  );
};

export default MachineStorageScheme;
