import { FC } from 'react';
import { CreateCupModelCell } from '../../../../../../../types/serverInterface/machineModelDTO';
import styles from '../../CreateModelCellsForm.module.scss';
import HorizontalContainer from '../../../../../../../components/HorizontalContainer';
import TextFieldWithTooltip from '../../../../../../../components/withTooltip/TextField';
import { getInputNumberValue } from '../../../../../../../helpers/ inputHelpers';
import VerticalContainer from '../../../../../../../components/VerticalContainer';
import { Switch } from '@consta/uikit/Switch';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconCopy } from '@consta/uikit/IconCopy';
import { CupCellsFieldError } from '../../../types';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ModelCupCellFormItem
 */
type ModelCupCellFormItemProps = {
  /**
   * Ячейка
   */
  cell: CreateCupModelCell;
  /**
   * Ошибки
   */
  error: CupCellsFieldError | undefined;
  /**
   * Обработчик изменения ячейки стакана
   *
   * @param key ключ поля в форме изменения ячейки стакана модели автомата
   * @param value новое значение поля
   */
  onChange: (key: keyof CreateCupModelCell) => (value: string | null) => void;
  /**
   * Обработчик удаления ячейки стакана
   */
  onDeleteClick: () => void;
  /**
   * Обработчик копирования ячейки стакана
   */
  onCopyClick: () => void;
};

/**
 * Форма ячейки стакана модели автомата
 */
const ModelCupCellFormItem: FC<ModelCupCellFormItemProps> = ({
  cell,
  error,
  onChange,
  onDeleteClick,
  onCopyClick,
}) => {
  const { t } = useTranslation();

  const { cupVolume, maxVolume, minVolume, cellNumber, isCount } = cell;

  // Обработчики
  const handleChange =
    (key: keyof CreateCupModelCell) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };
  // t('machineControl.model.create.cells.cup.add.button.label')
  return (
    <div className={styles.formItem}>
      <VerticalContainer space="xs">
        <HorizontalContainer space="s" align="end">
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.cellNumber?.isError
                ? t('machineControl.model.create.cells.cup.cellNumber.tooltip.label')
                : t(error.cellNumber?.label || ''),
            }}
            status={!error ? undefined : error.cellNumber?.status}
            label={t('machineControl.model.create.cells.cup.cellNumber.input.label')}
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(cellNumber)}
            onChange={handleChange('cellNumber')}
          />
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.cupVolume?.isError
                ? t('machineControl.model.create.cells.cup.cupVolume.tooltip.label')
                : t(error.cupVolume?.label || ''),
            }}
            status={!error ? undefined : error.cupVolume?.status}
            label={t('machineControl.model.create.cells.cup.cupVolume.input.label')}
            rightSide={t(
              'machineControl.model.create.cells.cup.volume.unit.ml.input.rightSide.label',
            )}
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(cupVolume)}
            onChange={handleChange('cupVolume')}
          />
          <Button iconLeft={IconTrash as any} onlyIcon view="ghost" onClick={onDeleteClick} />
        </HorizontalContainer>
        <HorizontalContainer space="s" align="end">
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.maxVolume?.isError
                ? t('machineControl.model.create.cells.cup.maxVolume.tooltip.label')
                : t(error.maxVolume?.label || ''),
            }}
            status={!error ? undefined : error.maxVolume?.status}
            label={t('machineControl.model.create.cells.cup.maxVolume.input.label')}
            rightSide={t(
              'machineControl.model.create.cells.cup.volume.unit.pcs.input.rightSide.label',
            )}
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(maxVolume)}
            onChange={handleChange('maxVolume')}
          />
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.minVolume?.isError
                ? t('machineControl.model.create.cells.cup.minVolume.tooltip.label')
                : t(error.minVolume?.label || ''),
            }}
            status={!error ? undefined : error.minVolume?.status}
            label={t('machineControl.model.create.cells.cup.minVolume.input.label')}
            rightSide={t(
              'machineControl.model.create.cells.cup.volume.unit.pcs.input.rightSide.label',
            )}
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(minVolume)}
            onChange={handleChange('minVolume')}
          />
          <Button iconLeft={IconCopy as any} onlyIcon view="ghost" onClick={onCopyClick} />
        </HorizontalContainer>
        <Switch
          label={t('machineControl.model.create.cells.cup.isCount.switch.label')}
          checked={isCount}
        />
      </VerticalContainer>
    </div>
  );
};

export default ModelCupCellFormItem;
