import {
  EditMachineVendistaSettingDTO,
  EditVendistaDTO,
} from '../../types/serverInterface/vendistaDTO';
import { AppDispatch } from '../../app/store';
import {
  checkVendistaIntegrationThunk,
  checkVendistaTokenThunk,
  editMachineVendistaSettingThunk,
  editVendistaTokenThunk,
  getMachineVendistaSettingThunk,
} from './thunk';

/**
 * Изменение токена vendista
 *
 * @param data данные формы изменения токена
 * @param organizationId id организации
 */
export const editVendistaTokenAction =
  (data: EditVendistaDTO, organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(editVendistaTokenThunk({ data, organizationId })).then(() => {
      dispatch(checkVendistaTokenAction(organizationId));
    });

/**
 * Получение информации о состоянии токена vendista
 *
 * @param organizationId id организации
 */
export const checkVendistaTokenAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(checkVendistaTokenThunk(organizationId));

/**
 * Получение номера терминала vendista автомата
 *
 * @param machineId id автомата
 */
export const getMachineVendistaSettingAction = (machineId: number) => (dispatch: AppDispatch) =>
  dispatch(getMachineVendistaSettingThunk(machineId));

/**
 * Изменения номера терминала vendista автомата
 *
 * @param machineId id автомата
 * @param data данные формы изменения настроек vendista автомата
 */
export const editMachineVendistaSettingAction =
  (machineId: number, data: EditMachineVendistaSettingDTO) => (dispatch: AppDispatch) =>
    dispatch(editMachineVendistaSettingThunk({ machineId, data })).then(() => {
      dispatch(getMachineVendistaSettingAction(machineId));
    });

/**
 * Проверка наличия интеграции vendista
 *
 * @param organizationId id организации
 */
export const checkVendistaIntegrationAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(checkVendistaIntegrationThunk(organizationId));
