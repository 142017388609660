import { BaseTableDataRowCellProps } from './types';

import classNames from 'classnames';
import styles from './BaseTableDataRowCell.module.scss';

const getJustifyClassName = (align: 'left' | 'center' | 'right') => {
  switch (align) {
    case 'left':
      return styles.left;
    case 'center':
      return styles.center;
    case 'right':
      return styles.right;
  }
};

/**
 * Ячейка базовой таблицы
 */
const BaseTableDataRowCell = <T,>({ row, column, index }: BaseTableDataRowCellProps<T>) => {
  const { baseTableDataRowCellClassName = '', justify = 'left' } = column;

  return (
    <div
      className={classNames(
        styles.BaseTableDataRowCell,
        baseTableDataRowCellClassName,
        getJustifyClassName(justify),
      )}
    >
      {column.renderCell(row, index)}
    </div>
  );
};

export default BaseTableDataRowCell;
