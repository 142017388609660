import styles from './MachineStorageSnackMatrixItem.module.scss';

/**
 * Получение цвета ячейки в снековом автомате
 *
 * @param minVolume минимальный объём ячейке(красный статус)
 * @param warningVolume объём ячейки с предупреждением(жёлтый статус)
 * @param volume остаток в ячейке
 */
export const getColorByVolume = (
  minVolume: number,
  warningVolume: number,
  volume: number,
): string => {
  if (volume <= minVolume) {
    return styles.backgroundAlert;
  }

  if (volume <= warningVolume) {
    return styles.backgroundCaution;
  }

  return styles.backgroundSuccess;
};
