import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './MachineStorageSnackMatrixItem.module.scss';
import { getColorByVolume } from './helpers';
import { MachineStorageSnackMatrixItemProps } from './types';

/**
 * Ячейка с остатками товара в снековом автомате
 */
const MachineStorageSnackMatrixItem: FC<MachineStorageSnackMatrixItemProps> = ({ snackCell }) => {
  const { minVolume, warningVolume, volume, motorsLength } = snackCell;

  return (
    <div
      className={classNames(styles.gridCell, getColorByVolume(minVolume, warningVolume, volume))}
      style={{
        gridColumn: `span ${motorsLength}`,
      }}
    />
  );
};

export default MachineStorageSnackMatrixItem;
