import { FC } from 'react';
import DefaultModal from '../../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import AlertButton from '../../../../../components/AlertButton';
import { useAppDispatch } from '../../../../../app/hooks/store';
import { deleteTelegramNotificationSettingAction } from '../../../../../state/telemetryNotification/action';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './DeleteTelegramNotificationSetting.module.scss';
import { DeleteTelegramNotificationSettingProps } from './types';
import { useTranslation } from 'react-i18next';

/**
 * Модальное окно для отключения уведомлений в Telegram, которые приходят из телеметрии
 */
const DeleteTelegramNotificationSetting: FC<DeleteTelegramNotificationSettingProps> = ({
  selectedIdForDeletion,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // Обработчики
  const handleDelete = () => {
    dispatch(deleteTelegramNotificationSettingAction(selectedIdForDeletion, organizationId)).then(
      () => {
        onClose();
      },
    );
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        size="l"
        view="clear"
        label={t('integration.telegram.delete.cancel.button.label')}
        onClick={onClose}
      />
      <AlertButton
        size="l"
        label={t('integration.telegram.delete.turnOff.button.label')}
        onClick={handleDelete}
      />
    </>
  );

  return (
    <DefaultModal
      modalTitle={t('integration.telegram.delete.modal.title')}
      onClose={onClose}
      renderActions={renderActions}
      className={styles.DeleteTelegramNotificationSettings}
    >
      <Text size="l">{t('integration.telegram.delete.information.label')}</Text>
    </DefaultModal>
  );
};

export default DeleteTelegramNotificationSetting;
