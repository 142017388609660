import { FC } from 'react';
import { LeftMenuItemType } from '../../type';
import styles from './LeftMenuItem.module.scss';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type LeftMenuIteProps = {
  item: LeftMenuItemType;
};

const LeftMenuItem: FC<LeftMenuIteProps> = ({ item }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isSelect = pathname === item.path;

  // Обработчики
  const handleClick = () => {
    navigate(item.path);
  };

  return (
    <div
      className={classNames(styles.LeftMenuItem, isSelect && styles.select)}
      onClick={handleClick}
    >
      {item.renderIcon()}
      <Text weight="medium" view="secondary" size="s">
        {t(item.label)}
      </Text>
    </div>
  );
};

export default LeftMenuItem;
