import React, { FC, useState } from 'react';
import ContentCard from '../../../../components/ContentCard';
import VerticalContainer from '../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import TabsBadge from '../../../../components/TabsBadge';
import styles from './InstructionsInfo.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Табы страницы управления интеграцией
 */
enum IntegrationTabs {
  VENDISTA = 'VENDISTA',
  TELEGRAM = 'TELEGRAM',
}

/**
 * Инструкции интеграций
 */
const InstructionsInfo: FC = () => {
  const { t } = useTranslation();

  // TODO: в идеале в будущем перейти на url
  const [selectedTab, setSelectedTab] = useState<IntegrationTabs>(IntegrationTabs.TELEGRAM);
  const [isShowTab, setIsShowTab] = useState(true);

  // Список табов
  const tabsList = [
    {
      label: t(`integration.instructions.info.tabs.${IntegrationTabs.VENDISTA}`),
      isSelect: selectedTab == IntegrationTabs.VENDISTA,
      onClick: () => {
        setSelectedTab(IntegrationTabs.VENDISTA);
      },
    },
    {
      label: t(`integration.instructions.info.tabs.${IntegrationTabs.TELEGRAM}`),
      isSelect: selectedTab == IntegrationTabs.TELEGRAM,
      onClick: () => {
        setSelectedTab(IntegrationTabs.TELEGRAM);
      },
    },
  ];

  //render методы
  const renderVendistaInstructions = () => (
    <ContentCard className={styles.instructionsInfoContent}>
      <VerticalContainer space={0}>
        <Text>1. Текст вендиста</Text>
        <Text>2. Текст</Text>
        <Text>3. Текст</Text>
        <Text>4. Текст</Text>
        <Text>5. Текст</Text>
      </VerticalContainer>
    </ContentCard>
  );

  const renderTelegramInstructions = () => (
    <ContentCard className={styles.instructionsInfoContent}>
      <VerticalContainer space={0}>
        <Text>1. Текст тг</Text>
        <Text>2. Текст</Text>
        <Text>3. Текст</Text>
        <Text>4. Текст</Text>
        <Text>5. Текст</Text>
      </VerticalContainer>
    </ContentCard>
  );

  const renderSomeInstructions = () => {
    switch (selectedTab) {
      case IntegrationTabs.VENDISTA:
        return renderVendistaInstructions();
      case IntegrationTabs.TELEGRAM:
        return renderTelegramInstructions();
    }
  };

  return (
    <ContentCard className={styles.InstructionsInfo}>
      <VerticalContainer align="start" space="m" isAutoWidth>
        <Text size="xl" weight="semibold">
          {t('integration.instructions.info.title')}
        </Text>
        {isShowTab && <TabsBadge size="s" tabsList={tabsList} />}
        {renderSomeInstructions()}
      </VerticalContainer>
    </ContentCard>
  );
};

export default InstructionsInfo;
