import React, { FC, useRef, useState } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import styles from './MachineSettingsGroupAction.module.scss';
import { getIconByName } from '../../../../../../helpers/getIcon';
import { ActionGroup } from '../../../../../../types/serverInterface/machineDTO';

/**
 * Свойства компонента MachineSettingsGroupAction
 */
type MachineSettingsGroupActionProps = {
  /**
   * Группа кастомных действий
   */
  group: ActionGroup;
  onActionClick: (code: string) => void;
};

/**
 * Группа кастомных действий
 */
const MachineSettingsGroupAction: FC<MachineSettingsGroupActionProps> = ({
  group,
  onActionClick,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  //const [isRestartEditOpen, setIsRestartEditOpen] = useState(false);

  // Обработчики
  const handleOpenContextMenu = () => {
    setIsOpen(true);
  };

  const handleCloseContextMenu = () => {
    setIsOpen(false);
  };

  const handleItemClick = (item: { key: string }) => {
    onActionClick(item.key);
  };

  /*const handleRestartEditOpenClick = () => {
    setIsRestartEditOpen(true);
  };

  const handleRestartEditClose = () => {
    setIsRestartEditOpen(false);
  };

  const handleItemClick = (item: { key: string }) => {
    if (item.key === group.actions[0].key) {
      handleRestartEditOpenClick();
    }
    onActionClick(item.key);
  };

  // render методы
  const renderModalAction = () => (
    <>
      <ContentCard label="Отмена" size="l" view="clear" onClick={handleRestartEditClose} />
      <ContentCard label="Перезагрузить" size="l" />
    </>
  );

  const renderRestartModal = () => (
    <DefaultModal
      className={styles.editModal}
      isOpen={isRestartEditOpen}
      modalTitle="Подтверждение действия"
      onClose={handleRestartEditClose}
      renderActions={renderModalAction}
    >
      <Text size="l">Уверены, что хотите выполнить действие «Перезагрузить»?</Text>
    </DefaultModal>
  );*/

  return (
    <>
      <Button
        size="m"
        label={group.label}
        onlyIcon
        iconLeft={getIconByName(group.icon) as any}
        view="clear"
        ref={ref}
        onClick={handleOpenContextMenu}
      />
      <ContextMenu
        className={styles.contextMenu}
        isOpen={isOpen}
        direction="downStartLeft"
        anchorRef={ref}
        items={group.actions}
        getItemLabel={({ label }) => label}
        getItemLeftIcon={({ icon }) => getIconByName(icon) as any}
        onClickOutside={handleCloseContextMenu}
        onItemClick={({ item }) => handleItemClick(item)}
      />
      {/*{renderRestartModal()}*/}
    </>
  );
};

export default MachineSettingsGroupAction;
