import { FC, useState } from 'react';
import { Combobox } from '@consta/uikit/Combobox';
import { Checkbox } from '@consta/uikit/Checkbox';
import DefaultModal from '../../../../../../components/DefaultModal';
import { useAppDispatch } from '../../../../../../app/hooks/store';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import styles from './DisconnectModal.module.scss';
import AlertButton from '../../../../../../components/AlertButton';
import { disconnectMachineAction } from '../../../../../../state/machineControl/actions';
import { enumToArray } from '../../../../../../types/enums';
import { ShutdownReasonFilters } from '../../../../../../types/serverInterface/machineListDTO';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента DisconnectModal
 */
type DisconnectModalProps = {
  /**
   * id автомата
   */
  machineId: number;
  /**
   * Флаг открытия модального окна отключения автомата от телеметрии
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия модального окна отключения автомата от телеметрии
   */
  onDisconnectModalClose: () => void;
};

/**
 * Форма отключения автомата от телеметрии
 */
type DisconnectModalForm = {
  /**
   * Причина отключения
   */
  shutdownReason: ShutdownReasonFilters | null;
  /**
   * Флаг сброса остатков
   */
  isDropStock: boolean;
};

/**
 * Дефолтное значения формы отключения автомата от телеметрии
 */
const formInitialValue: DisconnectModalForm = {
  isDropStock: false,
  shutdownReason: ShutdownReasonFilters.IN_STOCK,
};

/**
 * Список причин отключения автомата от телеметрии
 */
const disconnectTypeList = enumToArray(ShutdownReasonFilters);

const getItemLabel = (item: ShutdownReasonFilters, t: (key: string) => string) => {
  switch (item) {
    case ShutdownReasonFilters.IN_STOCK:
      return t('machineControl.machine.settings.disconnect.shutdownReason.IN_STOCK');
    case ShutdownReasonFilters.ON_REPAIR:
      return t('machineControl.machine.settings.disconnect.shutdownReason.ON_REPAIR');
  }
};

/**
 * Модальное окно отключения автомата от телеметрии
 */
const DisconnectModal: FC<DisconnectModalProps> = ({
  machineId,
  isOpen,
  onDisconnectModalClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [form, setForm] = useState(formInitialValue);

  // Обработчики
  const handleShutdownReasonChange = ({ value }: { value: string | null }) => {
    setForm((prevState) => ({
      ...prevState,
      shutdownReason: value ? (value as ShutdownReasonFilters) : null,
    }));
  };

  const handleIsDropStockChange = ({ checked }: { checked: boolean }) =>
    setForm((prevState) => ({ ...prevState, isDropStock: checked }));

  const handleSubmit = () => {
    form.shutdownReason &&
      dispatch(disconnectMachineAction(machineId, form.shutdownReason, form.isDropStock)).then(
        () => {
          onDisconnectModalClose();
        },
      );
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        size="l"
        label={t('machineControl.machine.settings.disconnect.cancel.button.label')}
        view="clear"
        onClick={onDisconnectModalClose}
      />
      <AlertButton
        size="l"
        label={t('machineControl.machine.settings.disconnect.disconnect.button.label')}
        onClick={handleSubmit}
      />
    </>
  );

  return (
    <DefaultModal
      className={styles.DisconnectModal}
      isOpen={isOpen}
      modalTitle={t('machineControl.machine.settings.disconnect.modal.title')}
      onClose={onDisconnectModalClose}
      renderActions={renderActions}
    >
      <Combobox
        size="l"
        className={styles.field}
        label={t('machineControl.machine.settings.disconnect.shutdownReason.label')}
        items={disconnectTypeList}
        value={form.shutdownReason}
        getItemLabel={(item: ShutdownReasonFilters) => getItemLabel(item, t)}
        getItemKey={(item: ShutdownReasonFilters) => item}
        onChange={handleShutdownReasonChange}
      />
      <Checkbox
        size="l"
        checked={form.isDropStock}
        label={t('machineControl.machine.settings.disconnect.dropStock.label')}
        onChange={handleIsDropStockChange}
      />
    </DefaultModal>
  );
};

export default DisconnectModal;
