import * as Yup from 'yup';
import { CreateInviteEmployeeDTO } from '../../../../../types/serverInterface/invitationDTO';
import { Roles } from '../../../../../types/serverInterface/cabinetDTO';

export const createMemberValidationScheme: Yup.ObjectSchema<CreateInviteEmployeeDTO> = Yup.object({
  email: Yup.string().email('Введите email'),
  name: Yup.string().required('Это поле обязательное'),
  surname: Yup.string().required('Это поле обязательное'),
  patronymic: Yup.string(),
  position: Yup.mixed<Roles>().oneOf(Object.values(Roles)).required('Это поле обязательное'),
  phone: Yup.string().required('Это поле обязательное'),
});
