import { FC } from 'react';
import styles from './DevTable.module.scss';
import { devTableRows } from './const';
import Table from '../../../components/Table';

/**
 * Компонент разработки таблицы
 */
const DevTable: FC = () => {
  return (
    <Table
      isLoading
      rows={devTableRows}
      columns={[
        {
          title: 'test',
          columnWidth: '40%',
          renderCell: (data) => 'test' + data,
        },
        {
          title: 'test2',
          renderCell: (data) => 'test2' + data,
        },
        {
          title: 'test3',
          renderCell: (data) => 'test3' + data,
        },
      ]}
      renderActions={() => (
        <>
          <div className={styles.action}>actions1</div>
          <div className={styles.action}>actions2</div>
          <div className={styles.action}>actions3</div>
        </>
      )}
    />
  );
};

export default DevTable;
