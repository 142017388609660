import { FC } from 'react';
import { EditTelegramNotificationSettingDTO } from '../../../../../types/serverInterface/notificationDTO';
import DefaultModal from '../../../../../components/DefaultModal';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import {
  editTelegramNotificationSettingAction,
  getTelegramNotificationSettingsByOrganizationIdAction,
} from '../../../../../state/telemetryNotification/action';
import { useFormik } from 'formik';
import { useAppDispatch } from '../../../../../app/hooks/store';
import { editTelegramNotificationValidationScheme } from './editTelegramNotificationValidationScheme';
import { EditTelegramNotificationSettingProps } from './types';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { IconLogoFull } from '../../../../../assets/icon/iconLogoFull';
import styles from './EditTelegramNotificationSettings.module.scss';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { IconArrowNext } from '../../../../../assets/icon/iconArrowNext';
import { IconLogoTelegram } from '../../../../../assets/icon/iconLogoTelegram';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconQuestion } from '../../../../../assets/icon/iconQuestion';
import { useTranslation } from 'react-i18next';

/**
 * Модальное окно для изменений настроек подключения уведомлений в телеграме
 */
const EditTelegramNotificationSetting: FC<EditTelegramNotificationSettingProps> = ({
  organizationId,
  editedSetting,
  onClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    validationSchema: editTelegramNotificationValidationScheme,
    initialValues: editedSetting,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: EditTelegramNotificationSettingDTO) {
    organizationId &&
      dispatch(editTelegramNotificationSettingAction(data, editedSetting.id)).then(() => {
        dispatch(getTelegramNotificationSettingsByOrganizationIdAction(organizationId));
        onClose();
      });
  }

  // render методы
  const renderActions = () => (
    <>
      <Button
        size="l"
        view="clear"
        label={t('integration.telegram.edit.back.button.label')}
        onClick={onClose}
      />
      <Button
        size="l"
        label={t('integration.telegram.edit.save.button.label')}
        onClick={() => formik.handleSubmit()}
      />
    </>
  );

  const renderHeader = () => (
    <HorizontalContainer space="l" justify="center">
      <IconLogoFull className={styles.logoShaker} {...defaultIconProps} />
      <VerticalContainer justify="center">
        <IconArrowNext size="xs" className={styles.arrowNextIcon} {...defaultIconProps} />
      </VerticalContainer>
      <IconLogoTelegram className={styles.logoTelegram} {...defaultIconProps} />
    </HorizontalContainer>
  );

  const renderModalTitle = () => (
    <HorizontalContainer className={styles.modalTitle} justify="center">
      <Text size="xl" weight="semibold">
        {t('integration.telegram.edit.modal.title')}
      </Text>
    </HorizontalContainer>
  );

  // TODO: надо передвинуть знак вопроса в TextField в конец

  const renderTextFields = () => (
    <VerticalContainer space="l">
      <TextField
        label={t('integration.telegram.edit.chatId.input.label')}
        name="chatId"
        width="full"
        placeholder={t('integration.telegram.edit.chatId.input.placeholder')}
        size="l"
        required
        labelIcon={IconQuestion as any}
        value={formik.values.chatId}
        status={formik.errors.chatId ? 'alert' : undefined}
        caption={formik.errors.chatId}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <TextField
        label={t('integration.telegram.edit.botToken.input.label')}
        name="botToken"
        width="full"
        placeholder={t('integration.telegram.edit.botToken.input.placeholder')}
        size="l"
        required
        labelIcon={IconQuestion as any}
        value={formik.values.botToken}
        status={formik.errors.botToken ? 'alert' : undefined}
        caption={formik.errors.botToken}
        onChange={({ e }) => formik.handleChange(e)}
      />
    </VerticalContainer>
  );

  return (
    <DefaultModal
      className={styles.EditTelegramNotificationSettings}
      withHeader={false}
      onClose={onClose}
      renderActions={renderActions}
    >
      <VerticalContainer space="3xl">
        {renderHeader()}
        {renderModalTitle()}
        {renderTextFields()}
      </VerticalContainer>
    </DefaultModal>
  );
};

export default EditTelegramNotificationSetting;
