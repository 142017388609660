import React, { FC, useEffect, useState } from 'react';
import styles from './PromoCodeInfo.module.scss';
import VerticalContainer from '../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../components/HorizontalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconCopy } from '@consta/uikit/IconCopy';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { getPromoCodeAction } from '../../../state/promoCode/actions';
import { selectPromoCodeInfo } from '../../../state/promoCode/selectors';
import { TypeDiscountEnum } from '../../../types/serverInterface/promoCodeDTO';
import { IconEditSquare } from '../../../assets/icon/iconEditSquare';
import SchedulesListByInfo from './SchedulesListByInfo';
import PromoCodeProducts from './PromoCodeProducts';
import QrPromoCodeModal from './QrPromoCodeModal';
import { IconQR } from '../../../assets/icon/iconQR';
import { useTranslation } from 'react-i18next';

const PromoCodeInfo: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isOpenQr, setIsOpenQr] = useState(false);

  const { promoCodeId } = useParams<{ promoCodeId: string }>();
  const promoCodeIdFormatted = Number(promoCodeId);

  const dispatch = useAppDispatch();

  const { state: promoCode } = useAppSelector(selectPromoCodeInfo());

  useEffect(() => {
    promoCodeIdFormatted && dispatch(getPromoCodeAction(promoCodeIdFormatted));
  }, [dispatch, promoCodeIdFormatted]);

  if (!promoCode) return null;

  // Вспомогательные методы
  const getDiscountString = () => {
    if (promoCode?.discount.type === TypeDiscountEnum.FREE) {
      return t('promoCode.amountType.FREE');
    }

    if (promoCode?.discount.type === TypeDiscountEnum.FIXED) {
      return `${promoCode?.discount.amount} ${t('promoCode.info.discount.label.FIXED')}`;
    }

    return `${promoCode?.discount.amount} ${t('promoCode.info.discount.label.PERCENT')}`;
  };

  // Обработчики
  const handleOpenQr = () => {
    setIsOpenQr(true);
  };

  const handleCloseQr = () => {
    setIsOpenQr(false);
  };

  return (
    <VerticalContainer className={styles.PromoCodeForm} space="5xl">
      <QrPromoCodeModal
        isOpen={isOpenQr && !!promoCode.code}
        promoCode={promoCode.code || ''}
        onClose={handleCloseQr}
      />
      {/* Промокод */}
      <VerticalContainer className={styles.card} space="3xl">
        <HorizontalContainer space="auto">
          <Text size="2xl" weight="semibold">
            {t('promoCode.info.promoCode.title')}
          </Text>
          <Button
            label={t('promoCode.info.edit.button.label')}
            iconLeft={IconEditSquare as any}
            onClick={() => {
              navigate(`/promoCode/edit/${promoCodeId}`);
            }}
          />
        </HorizontalContainer>
        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text>{t('promoCode.info.promoCode.label')}</Text>
            <HorizontalContainer space="s" className={styles.fieldWrapper} align="center">
              <Text weight="semibold">{promoCode.code}</Text>
              <Button
                view="clear"
                iconLeft={IconCopy as any}
                size="s"
                onClick={() => {
                  promoCode?.code && navigator.clipboard.writeText(promoCode?.code);
                }}
              />
              <Button
                view="clear"
                size="m"
                onlyIcon
                iconLeft={IconQR as any}
                onClick={handleOpenQr}
              />
            </HorizontalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text>{t('promoCode.info.discount.label')}</Text>
            <HorizontalContainer className={styles.fieldWrapper}>
              <Text weight="semibold">{getDiscountString()}</Text>
            </HorizontalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text>{t('promoCode.info.periodUse.label')}</Text>
            <HorizontalContainer className={styles.fieldWrapper}>
              <Text weight="semibold">{`${promoCode.periodUse.from}-${promoCode.periodUse.to}`}</Text>
            </HorizontalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text>{t('promoCode.info.status.label')}</Text>
            <HorizontalContainer className={styles.fieldWrapper}>
              <Text weight="semibold">
                {promoCode.isActive
                  ? t('promoCode.info.status.on.label')
                  : t('promoCode.info.status.off.label')}
              </Text>
            </HorizontalContainer>
          </HorizontalContainer>
        </VerticalContainer>

        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text>{t('promoCode.info.qty.label')}</Text>
            <HorizontalContainer className={styles.fieldWrapper}>
              <Text weight="semibold">
                {promoCode.qty ? `${promoCode.used || 0}/${promoCode.qty}` : '-'}
              </Text>
            </HorizontalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text>{t('promoCode.info.groupUse.label')}</Text>
            <HorizontalContainer className={styles.fieldWrapper}>
              <Text weight="semibold">{promoCode.group ? promoCode.group?.name : '-'}</Text>
            </HorizontalContainer>
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text>{t('promoCode.info.description.label')}</Text>
            <HorizontalContainer className={styles.fieldWrapper}>
              <Text weight="semibold">{promoCode.description || '-'}</Text>
            </HorizontalContainer>
          </HorizontalContainer>
        </VerticalContainer>
      </VerticalContainer>
      <SchedulesListByInfo list={promoCode.schedules} />
      {/* Доступные автоматы */}
      <VerticalContainer className={styles.card} space="3xl">
        <Text size="2xl" weight="semibold">
          {t('promoCode.info.availableMachines.title')}
        </Text>
        <HorizontalContainer className={styles.wrapper} space="auto">
          {promoCode.machines?.length ? (
            <>
              <Text>{t('promoCode.info.machines.label')}</Text>
              <HorizontalContainer className={styles.fieldWrapper}>
                <Text weight="semibold">
                  {promoCode.machines?.map(({ name }) => name).join(', ')}
                </Text>
              </HorizontalContainer>
            </>
          ) : (
            <Text>{t('promoCode.info.allMachines.label')}</Text>
          )}
        </HorizontalContainer>
      </VerticalContainer>
      <PromoCodeProducts product={promoCode?.product} />
    </VerticalContainer>
  );
};

export default PromoCodeInfo;
