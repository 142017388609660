import { FC, useMemo } from 'react';
import {
  MachineCellsDTO,
  ProductCellsByList,
} from '../../../../../../../types/serverInterface/machineListDTO';
import { ProductGroup } from '../../../../../../../types/serverInterface/storageDTO';
import { getSortedProductGroup } from '../../../../../../../components/Machine/MachineStorage/helpers';
import HorizontalContainer from '../../../../../../../components/HorizontalContainer';
import MachineStorageDefaultCellItem from '../../../../../../MachineControlPage/MachineList/MachineStorageListCell/MachineStorageDefaultScheme/MachineStorageDefaultCellItem';
import { MachineCellGroup } from '../../../../../../../types/serverInterface/machineDTO';
import VerticalContainer from '../../../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconBottle } from '../../../../../../../assets/icon/iconBottle';
import { defaultIconProps } from '../../../../../../../consts/defaultIconProps';
import { IconFaucet } from '../../../../../../../assets/icon/iconFaucet';
import { IconShakerCup } from '../../../../../../../assets/icon/iconShakerCup';
import styles from './MachineListInOutletStorageCell.module.scss';
import IconWithTooltip from '../../../../../../../components/withTooltip/Icon';
import { IconLock } from '../../../../../../../assets/icon/iconLock';
import { useTranslation } from 'react-i18next';
import { OutletMachineListStorageCellProps } from './types';

/**
 * Дефолтное значение ячеек склада автомата
 */
const initialStorage: {
  [key in ProductGroup]: ProductCellsByList[];
} = {
  [ProductGroup.CONCENTRATE]: [],
  [ProductGroup.POWDER]: [],
  [ProductGroup.COFFEE]: [],
};

/**
 * Схема ячеек склада на списке автоматов торговой точки
 */
const MachineListInOutletStorageCell: FC<OutletMachineListStorageCellProps> = ({
  machineCells,
}) => {
  const { t } = useTranslation();

  const { cells, cellWaters, cellCups } = machineCells;

  const sortedCells = useMemo(() => {
    const separateStorageInfoByGroup = (storageInfoArray: ProductCellsByList[] | null) => {
      const sortedCells = [...(storageInfoArray || [])];
      sortedCells.sort((a, b) => a.cellNumber - b.cellNumber);

      const separatedStorageInfo: {
        [key in ProductGroup]: ProductCellsByList[];
      } = JSON.parse(JSON.stringify(initialStorage));

      sortedCells.forEach((storageInfo) => {
        separatedStorageInfo[storageInfo.group].push(storageInfo);
      });

      return separatedStorageInfo;
    };

    return separateStorageInfoByGroup(cells);
  }, [cells]);

  const sortedGroup = useMemo(() => getSortedProductGroup(sortedCells), [sortedCells]);

  // render методы
  const renderWaterCellsItems = () => (
    <HorizontalContainer space="2xs">
      {cellWaters?.map(
        (cell, index) =>
          cell.isActive && (
            <HorizontalContainer className={styles.waterCell} space={0} key={index}>
              <Text weight="semibold" size="m" className={styles.text}>
                {cell.isCount ? cell.volume || 0 : '∞'}
              </Text>
              {cell.isCount ? (
                <IconBottle {...defaultIconProps} size="s" />
              ) : (
                <IconFaucet {...defaultIconProps} size="s" />
              )}
            </HorizontalContainer>
          ),
      )}
    </HorizontalContainer>
  );

  const renderCupsCellsItems = () => (
    <HorizontalContainer space="2xs">
      {cellCups?.map((cell, index) => (
        <HorizontalContainer className={styles.cupCell} space={0} key={index}>
          <Text weight="semibold" size="m" className={styles.text}>
            {cell.isCount ? cell.volume || 0 : '∞'}
          </Text>
          <IconShakerCup {...defaultIconProps} size="s" />
        </HorizontalContainer>
      ))}
    </HorizontalContainer>
  );

  const renderProductCells = () => (
    <HorizontalContainer space="s">
      {sortedGroup.map((key) => (
        <HorizontalContainer space="2xs" key={key}>
          {sortedCells[key].map((cell, index) => (
            <MachineStorageDefaultCellItem
              key={index}
              cell={{
                ...cell,
                volume: cell.ingredientCell?.ingredientId == null ? 0 : cell.volume || 0,
              }}
            />
          ))}
        </HorizontalContainer>
      ))}
      <HorizontalContainer space="2xs">
        {cellWaters?.map(
          (cell, index) =>
            cell.isCount && (
              <MachineStorageDefaultCellItem
                key={index}
                cell={{ ...cell, group: MachineCellGroup.WATER }}
              />
            ),
        )}
      </HorizontalContainer>
      <HorizontalContainer space="2xs">
        {cellCups?.map(
          (cell, index) =>
            cell.isCount && (
              <MachineStorageDefaultCellItem
                key={index}
                cell={{ ...cell, group: MachineCellGroup.CUP }}
              />
            ),
        )}
      </HorizontalContainer>
    </HorizontalContainer>
  );

  const renderStorageCell = (machine: MachineCellsDTO) => {
    const blockedCellIndices = (machine.cells || [])
      .filter((cell) => cell.isActive === false)
      .map((cell) => cell.cellNumber)
      .sort((a, b) => a - b);

    const blockedCellLength = `: ${blockedCellIndices.length}`;
    const blockedCellIndicesString = `№ ${blockedCellIndices.join(', ')}`;

    const renderTooltipContent = () =>
      (
        <VerticalContainer space="3xs">
          <HorizontalContainer>
            <Text size="xs">
              {t('outlet.list.tooltip.iconLock.label', {
                blockedCellLength,
              })}
            </Text>
          </HorizontalContainer>
          <HorizontalContainer>
            <Text size="xs">{blockedCellIndicesString}</Text>
          </HorizontalContainer>
        </VerticalContainer>
      ) as any;

    return (
      <HorizontalContainer>
        {blockedCellIndices.length > 0 && (
          <IconWithTooltip
            tooltipProps={{
              content: renderTooltipContent(),
            }}
          >
            <IconLock className={styles.iconLock} size="s" {...defaultIconProps} />
          </IconWithTooltip>
        )}
      </HorizontalContainer>
    );
  };

  return (
    <HorizontalContainer className={styles.OutletMachineListStorageCell} space="l" align="center">
      <HorizontalContainer space="s">
        <VerticalContainer space={0} align="end" justify="center">
          {renderWaterCellsItems()}
          {renderCupsCellsItems()}
        </VerticalContainer>
        {renderProductCells()}
      </HorizontalContainer>
      {renderStorageCell(machineCells)}
    </HorizontalContainer>
  );
};

export default MachineListInOutletStorageCell;
