import { FC, useMemo } from 'react';
import styles from './LeftMenu.module.scss';
import { IconLogoShaker } from '../../assets/icon/iconLogo';
import { defaultIconProps } from '../../consts/defaultIconProps';
import { leftMenuGroupList } from './consts';
import LeftMenuItemGroup from './LeftMenuItemGroup/LeftMenuItemGroup';
import VerticalContainer from '../VerticalContainer';
import { useAppSelector } from '../../app/hooks/store';
import { selectOrganizationId } from '../../state/organization/selectors';
import { selectCabinet, selectRole } from '../../state/user/selectors';
import { Roles } from '../../types/serverInterface/cabinetDTO';
import HorizontalContainer from '../HorizontalContainer';
import Eye from '../Eye';
import classNames from 'classnames';

const LeftMenu: FC = () => {
  const organizationId = useAppSelector(selectOrganizationId());
  const role = useAppSelector(selectRole());
  const cabinet = useAppSelector(selectCabinet());

  const isStudio = cabinet?.login === 'studio@mail.ru';

  const menuItemsList = useMemo(() => {
    return leftMenuGroupList.map((group) => ({
      ...group,
      listItems:
        group.listItems.filter((item) => {
          return (
            !!item.role.find((roleItem) => roleItem === role || role === Roles.ROOT) &&
            (!item.requiredOrganization || organizationId)
          );
        }) || [],
    }));
  }, [role, organizationId]);

  // render методы

  return (
    <div className={styles.LeftMenu}>
      <VerticalContainer space={0}>
        <div className={classNames(styles.logoWrapper, isStudio && styles.isStudio)}>
          {isStudio ? (
            <HorizontalContainer>
              <Eye />
              <Eye />
            </HorizontalContainer>
          ) : (
            <IconLogoShaker className={styles.logo} size="s" {...defaultIconProps} />
          )}
        </div>
        <div className={styles.groupList}>
          {menuItemsList.map((group) => (
            <LeftMenuItemGroup group={group} key={group.label} />
          ))}
        </div>
      </VerticalContainer>
    </div>
  );
};

export default LeftMenu;
