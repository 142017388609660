import { FC, useCallback } from 'react';
import styles from './MachineList.module.scss';
import { useNavigate } from 'react-router-dom';
import { ConnectionStatus, StatusColor } from '../../../types/serverInterface/machineDTO';
import MachineListStatusBar from './MachineListStatusBar';
import MachineListHeader from './MachineListHeader';
import VerticalContainer from '../../../components/VerticalContainer';
import HorizontalContainer from '../../../components/HorizontalContainer';
import { Text, TextPropView } from '@consta/uikit/__internal__/src/components/Text';
import MachineStorageListCell from './MachineStorageListCell';
import classNames from 'classnames';
import { machineListInitialFilters, useMachineList } from './hook';
import { useTranslation } from 'react-i18next';
import {
  MachineListItem,
  SalesFilters,
  ShutdownReasonFilters,
} from '../../../types/serverInterface/machineListDTO';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import MachineArchivedTabs from './MachineArchivedTabs';
import { SortDirection } from '../../../types/enums/sortDirection';
import { containsRussianCharacters } from '../../../helpers/containsRussianCharacters';
import { IconSparkles } from '../../../assets/icon/iconSparkles';
import BadgeWithTooltip from '../../../components/withTooltip/Badge';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import { IconReceipt } from '../../../assets/icon/iconReceipt';
import Table from '../../../components/Table';
import { IconLock } from '../../../assets/icon/iconLock';
import IconWithTooltip from '../../../components/withTooltip/Icon';
import { Radio } from '@consta/uikit/Radio';
import { enumToArray } from '../../../types/enums';

const saleTypesList = enumToArray(SalesFilters);

const getStatus = (color: StatusColor): TextPropView => {
  switch (color) {
    case StatusColor.ERROR:
      return 'alert';
    case StatusColor.WARNING:
      return 'warning';
    case StatusColor.SUCCESS:
      return 'success';
  }
  return 'alert';
};

const rowHeight = 64;

/**
 * Список автоматов организации (минимальный)
 */
const MachineList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const organizationId = getOrganizationId();

  const {
    isLoading,
    machineList,
    machineAtModelsCount,
    machineAtStatusesCount,
    machineAtConnectionStatusesCount,
    archivedCount,
    machineAtShutdownCount,
    filters,
    setFilters,
  } = useMachineList(organizationId);

  // Вспомогательные методы
  const getConnectionStatus = (connectionStatus: ConnectionStatus) => {
    switch (connectionStatus) {
      case ConnectionStatus.ONLINE:
        return styles.connectionStatus_online;
      case ConnectionStatus.OFFLINE:
        return styles.connectionStatus_offline;
    }
  };

  // Обработчики
  const handleInfoClick = (machineId: number) => {
    navigate(`/machineControl/machine/${machineId}`);
  };

  const handleRowClick = ({ id }: { id: number }) => {
    handleInfoClick(id);
  };

  const handleSearchChange = useCallback(
    (value: string) => {
      setFilters((prevState) => ({ ...prevState, name: value }));
    },
    [setFilters],
  );

  const handleSelectModelId = (modelIdList: number[] | null) => {
    setFilters((prevState) => ({ ...prevState, machineModelIds: modelIdList }));
  };

  const handleSelectedStatus = (status: StatusColor | null) => {
    setFilters((prevState) => ({
      ...prevState,
      statusColors: status ? [status] : null,
    }));
  };

  const handleSelectedConnectionStatus = (status: ConnectionStatus | null) => {
    setFilters((prevState) => ({
      ...prevState,
      connectionStatus: status ? [status] : null,
    }));
  };

  const handleSaleTypeChange = (salesFilter: SalesFilters) => {
    setFilters((prevState) => ({ ...prevState, salesFilter }));
  };

  const handleShutdownStatusChange = (shutdownReasonFilter: ShutdownReasonFilters | null) => {
    setFilters((prevState) => ({ ...prevState, shutdownReasonFilter }));
  };

  const handleSortClick = useCallback(
    (sortKey: keyof typeof filters) => {
      setFilters((prevState) => ({
        ...prevState,
        [sortKey]:
          prevState[sortKey] === SortDirection.ASC
            ? SortDirection.DESC
            : prevState[sortKey] === SortDirection.DESC
              ? null
              : SortDirection.ASC,
      }));
    },
    [setFilters],
  );

  const handleArchiverChange = (isArchived: boolean | null) => () => {
    setFilters(() => ({
      ...machineListInitialFilters,
      isArchived,
      organizationId,
    }));
  };

  // render методы
  const renderNameCell = ({
    name,
    model,
    serialNumber,
    connectionStatus,
    isNew,
  }: MachineListItem) => {
    const isErrorSerialNumber = containsRussianCharacters(serialNumber);

    return (
      <VerticalContainer space={0}>
        <HorizontalContainer space="2xs" align="center">
          {/*{!filters.isArchived && (
            <BadgeWithTooltip
              size="xs"
              status="error"
              iconLeft={IconAlert as any}
              tooltipProps={{
                content: t('machineControl.machine.list.badge.warning.label', { wasOnlineHours }),
              }}
            />
          )}*/}
          {!filters.isArchived && isNew && (
            <BadgeWithTooltip
              size="xs"
              status="warning"
              iconLeft={IconSparkles as any}
              tooltipProps={{ content: t('machineControl.machine.list.badge.new.label') }}
            />
          )}
          <Text className={styles.text} weight="medium">
            {name}
          </Text>
          {/*{!filters.isArchived && (
            <ButtonWithTooltip
              size="s"
              className={styles.buttonInfo}
              onlyIcon
              view="clear"
              iconLeft={IconInfo as any}
              tooltipProps={{
                content: t('machineControl.machine.list.versionNumber.label', { version }),
              }}
            />
          )}*/}
        </HorizontalContainer>
        <HorizontalContainer space="xs" align="center">
          <div
            className={classNames(styles.connectionStatus, getConnectionStatus(connectionStatus))}
          />
          <HorizontalContainer space="xs">
            <Text size="s" className={styles.text} view="secondary">
              {model?.name}
            </Text>
            <Text
              size="s"
              className={styles.text}
              view={isErrorSerialNumber ? 'alert' : 'secondary'}
            >
              {serialNumber}
            </Text>
          </HorizontalContainer>
        </HorizontalContainer>
      </VerticalContainer>
    );
  };

  const renderSalesCell = ({ salePeriodResult }: MachineListItem) => (
    <VerticalContainer space={0}>
      {/*<Text size="m" weight="medium">
        {t('machineControl.machine.list.machineSales.label', { machineSales })}
      </Text>*/}
      <HorizontalContainer space="2xs" align="center">
        <IconReceipt size="s" className={styles.iconNewCheck} {...defaultIconProps} />
        <Text size="s" view="secondary">
          {salePeriodResult?.qty}
        </Text>
      </HorizontalContainer>
    </VerticalContainer>
  );

  const renderStorageCell = (machine: MachineListItem) => {
    const blockedCellIndices = (machine.cells || [])
      .filter((cell) => cell.isActive === false)
      .map((cell) => cell.cellNumber)
      .sort((a, b) => a - b);

    const blockedCellLength = `: ${blockedCellIndices.length}`;
    const blockedCellIndicesString = `№ ${blockedCellIndices.join(', ')}`;

    const renderTooltipContent = () =>
      (
        <VerticalContainer space="3xs">
          <HorizontalContainer>
            <Text size="xs">
              {t('machineControl.machine.list.tooltip.iconLock.label', {
                blockedCellLength,
              })}
            </Text>
          </HorizontalContainer>
          <HorizontalContainer>
            <Text size="xs">{blockedCellIndicesString}</Text>
          </HorizontalContainer>
        </VerticalContainer>
      ) as any;

    return (
      <HorizontalContainer space="l" align="center">
        <MachineStorageListCell machine={machine} />
        {blockedCellIndices.length > 0 && (
          <IconWithTooltip
            tooltipProps={{
              content: renderTooltipContent(),
            }}
          >
            <IconLock className={styles.iconLock} size="s" {...defaultIconProps} />
          </IconWithTooltip>
        )}
      </HorizontalContainer>
    );
  };

  const renderStatusCell = ({ status, shutdownReason }: MachineListItem) => (
    <VerticalContainer space={0} align="end">
      {!filters.isArchived && (
        <Text className={styles.text} weight="medium" view={getStatus(status?.color)}>
          {status?.text}
        </Text>
      )}
      {filters?.isArchived && (
        <Text className={styles.text} size="m" weight="medium" view="primary">
          {shutdownReason}
        </Text>
      )}
      <Text className={styles.text} view="secondary" size="s">
        {status?.date}
      </Text>
    </VerticalContainer>
  );

  const renderArchivedTabs = () => (
    <MachineArchivedTabs
      isArchived={filters.isArchived}
      isLoading={isLoading}
      archivedCount={archivedCount}
      onArchiverChange={handleArchiverChange}
    />
  );

  const renderMachineListStatusBar = () => (
    <MachineListStatusBar
      isLoading={isLoading}
      listCount={filters.items}
      successCount={machineAtStatusesCount?.success || 0}
      warningCount={machineAtStatusesCount?.warning || 0}
      alertCount={machineAtStatusesCount?.error || 0}
      offlineCount={machineAtConnectionStatusesCount?.offline || 0}
      selectedStatus={filters.statusColors?.[0] || null}
      selectedConnectionStatus={filters.connectionStatus?.[0] || null}
      onSelectedStatus={handleSelectedStatus}
      onSelectConnectionStatus={handleSelectedConnectionStatus}
    />
  );

  const renderMachineListHeader = () => (
    <MachineListHeader
      isArchived={!!filters.isArchived}
      isLoading={isLoading}
      listCount={filters.items}
      successCount={machineAtStatusesCount?.success || 0}
      warningCount={machineAtStatusesCount?.warning || 0}
      alertCount={machineAtStatusesCount?.error || 0}
      searchText={filters.name || ''}
      modelList={machineAtModelsCount || null}
      selectedModelId={filters.machineModelIds}
      selectedStatus={filters.statusColors?.[0] || null}
      selectedSaleType={filters.salesFilter}
      inStockNumber={machineAtShutdownCount?.in_STOCK || 0}
      selectedShutdownStatus={filters.shutdownReasonFilter}
      repairNumber={machineAtShutdownCount?.on_REPAIR || 0}
      soldNumber={machineAtShutdownCount?.sold || 0}
      scrapNumber={machineAtShutdownCount?.scrap || 0}
      onSearchChange={handleSearchChange}
      onSaleTypeChange={handleSaleTypeChange}
      onSelectedStatus={handleSelectedStatus}
      onSelectModelId={handleSelectModelId}
      onSelectShutdownStatus={handleShutdownStatusChange}
    />
  );

  // TODO: выпилить после проверки правильности работы этой таблицы
  // const renderTableCardWithBackground = (machineList: MachineListItem[]) => (
  //   <TableCardWithBackground
  //     className={styles.table}
  //     isLoading={isLoading}
  //     rowHeight={rowHeight}
  //     rowClassName={styles.machineListTableRow}
  //     rows={machineList}
  //     sortOrder={{
  //       name: filters.sortNameDirection,
  //       salePeriodResult: filters.sortSaleDirection,
  //       cells: null,
  //       status: filters.sortStatusDirection,
  //     }}
  //     columns={{
  //       name: {
  //         key: 'name',
  //         title: t('machineControl.machine.list.table.column.machines'),
  //         sortable: true,
  //         type: 'default',
  //         onSortClick: () => handleSortClick('sortNameDirection'),
  //         className: classNames(styles.name, filters.isArchived && styles.noRenderName),
  //         renderCell: renderNameCell,
  //       },
  //       salePeriodResult: {
  //         key: 'salePeriodResult',
  //         title: t('machineControl.machine.list.table.column.sales'),
  //         isNoRender: filters.isArchived || false,
  //         alignment: 'left',
  //         sortable: true,
  //         type: 'default',
  //         onSortClick: () => handleSortClick('sortSaleDirection'),
  //         className: styles.salePeriodResult,
  //         renderCell: renderSalesCell,
  //       },
  //       cells: {
  //         key: 'cells',
  //         title: t('machineControl.machine.list.table.column.cells'),
  //         alignment: 'left',
  //         sortable: true,
  //         type: 'default',
  //         onSortClick: () => handleSortClick('sortWaterDirection'),
  //         /*renderSort: () => (
  //          <VerticalContainer space="l">
  //          <Radio
  //          label={t('machineControl.machine.list.filter.cells.item.increaseWater')}
  //          checked={filters.sortWaterDirection === SortDirection.ASC}
  //          onChange={({ checked }) => {
  //          checked &&
  //          setFilters((prevState) => ({
  //          ...prevState,
  //          sortCupsDirection: null,
  //          sortWaterDirection: SortDirection.ASC,
  //          }));
  //          }}
  //          />
  //          <Radio
  //          label={t('machineControl.machine.list.filter.cells.item.increaseCups')}
  //          checked={filters.sortCupsDirection === SortDirection.ASC}
  //          onChange={({ checked }) => {
  //          checked &&
  //          setFilters((prevState) => ({
  //          ...prevState,
  //          sortCupsDirection: SortDirection.ASC,
  //          sortWaterDirection: null,
  //          }));
  //          }}
  //          />
  //          <Radio
  //          label={t('machineControl.machine.list.filter.cells.item.waneWater')}
  //          checked={filters.sortWaterDirection === SortDirection.DESC}
  //          onChange={({ checked }) => {
  //          checked &&
  //          setFilters((prevState) => ({
  //          ...prevState,
  //          sortCupsDirection: null,
  //          sortWaterDirection: SortDirection.DESC,
  //          }));
  //          }}
  //          />
  //          <Radio
  //          label={t('machineControl.machine.list.filter.cells.item.waneCups')}
  //          checked={filters.sortCupsDirection === SortDirection.DESC}
  //          onChange={({ checked }) => {
  //          checked &&
  //          setFilters((prevState) => ({
  //          ...prevState,
  //          sortCupsDirection: SortDirection.DESC,
  //          sortWaterDirection: null,
  //          }));
  //          }}
  //          />
  //          </VerticalContainer>
  //          ),*/
  //         className: styles.cells,
  //         renderCell: renderStorageCell,
  //       },
  //       status: {
  //         key: 'status',
  //         title: t('machineControl.machine.list.table.column.statusView'),
  //         alignment: 'right',
  //         sortable: true,
  //         type: 'default',
  //         onSortClick: () => handleSortClick('sortStatusDirection'),
  //         className: styles.status,
  //         renderCell: renderStatusCell,
  //       },
  //     }}
  //     onRowClick={handleRowClick}
  //   />
  // );

  const renderStorageCustomSort = () => (
    <VerticalContainer space="l">
      <Radio
        label={t('machineControl.machine.list.filter.cells.item.increaseWater')}
        checked={filters.sortWaterDirection === SortDirection.ASC}
        onChange={({ checked }) => {
          checked &&
            setFilters((prevState) => ({
              ...prevState,
              sortCupsDirection: null,
              sortWaterDirection: SortDirection.ASC,
            }));
        }}
      />
      <Radio
        label={t('machineControl.machine.list.filter.cells.item.increaseCups')}
        checked={filters.sortCupsDirection === SortDirection.ASC}
        onChange={({ checked }) => {
          checked &&
            setFilters((prevState) => ({
              ...prevState,
              sortCupsDirection: SortDirection.ASC,
              sortWaterDirection: null,
            }));
        }}
      />
      <Radio
        label={t('machineControl.machine.list.filter.cells.item.waneWater')}
        checked={filters.sortWaterDirection === SortDirection.DESC}
        onChange={({ checked }) => {
          checked &&
            setFilters((prevState) => ({
              ...prevState,
              sortCupsDirection: null,
              sortWaterDirection: SortDirection.DESC,
            }));
        }}
      />
      <Radio
        label={t('machineControl.machine.list.filter.cells.item.waneCups')}
        checked={filters.sortCupsDirection === SortDirection.DESC}
        onChange={({ checked }) => {
          checked &&
            setFilters((prevState) => ({
              ...prevState,
              sortCupsDirection: SortDirection.DESC,
              sortWaterDirection: null,
            }));
        }}
      />
    </VerticalContainer>
  );

  const renderSaleFilter = () => (
    <VerticalContainer space="s">
      {saleTypesList.map((item) => (
        <Radio
          key={item}
          checked={filters.salesFilter === item}
          label={t(`machineControl.machine.list.filter.sales.item.${item}`)}
          onClick={() => handleSaleTypeChange(item)}
        />
      ))}
    </VerticalContainer>
  );

  const renderTable = (machineList: MachineListItem[]) => (
    <Table
      rowHeight={rowHeight}
      isLoading={isLoading}
      withoutSettingsBar
      rows={machineList}
      onRowClick={handleRowClick}
      columns={[
        {
          columnWidth: '28.6%',
          title: t('machineControl.machine.list.table.column.machines'),
          titleTextProp: { weight: 'semibold', size: 'm', view: 'secondary' },
          withSort: true,
          sortDirection: filters.sortNameDirection,
          onSortClick: () => handleSortClick('sortNameDirection'),
          renderCell: renderNameCell,
        },
        {
          columnWidth: '14.3%',
          title: t('machineControl.machine.list.table.column.sales'),
          titleTextProp: { weight: 'semibold', size: 'm', view: 'secondary' },
          withSort: true,
          sortDirection: filters.sortSaleDirection,
          withFilter: true,
          renderFilterContextMenu: renderSaleFilter,
          onSortClick: () => handleSortClick('sortSaleDirection'),
          renderCell: renderSalesCell,
        },
        {
          columnWidth: '35.7%',
          title: t('machineControl.machine.list.table.column.cells'),
          titleTextProp: { weight: 'semibold', size: 'm', view: 'secondary' },
          withSort: true,
          renderSortContextMenu: renderStorageCustomSort,
          renderCell: renderStorageCell,
        },
        {
          title: t('machineControl.machine.list.table.column.statusView'),
          titleTextProp: { weight: 'semibold', size: 'm', view: 'secondary' },
          justify: 'right',
          withSort: true,
          sortDirection: filters.sortStatusDirection,
          onSortClick: () => handleSortClick('sortStatusDirection'),
          renderCell: renderStatusCell,
        },
      ]}
    />
  );

  if (!machineList) return null;

  return (
    <div className={styles.MachineList}>
      {renderArchivedTabs()}
      <VerticalContainer className={styles.verticalContainer} space="2xl">
        {!filters.isArchived && renderMachineListStatusBar()}
        {renderMachineListHeader()}
        {/*{renderTableCardWithBackground(machineList)}*/}
        {renderTable(machineList)}
      </VerticalContainer>
    </div>
  );
};

export default MachineList;
