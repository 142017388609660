import { EditMachineStorageSnackProductCell } from '../../../../types/serverInterface/machineDTO';
import { SnackCellPriceWithCellId } from './types';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  selectMachinePricesInfo,
  selectMachineStorageInfo,
} from '../../../../state/machineControl/selectors';
import { useEffect, useMemo } from 'react';
import {
  getMachinePricesInfoAction,
  getMachineStorageInfoAction,
} from '../../../../state/machineControl/actions';
import { getOrganizationId } from '../../../../helpers/getOrganizationId';
import { selectSnackBrandList } from '../../../../state/productBase/selectors';
import { getSnackBrandListAction } from '../../../../state/productBase/actions';
import { getSnackProductsWithPrices } from './helpers';

/**
 * Преобразование списка товаров для снек автомата
 *
 * @param machineId id автомата
 */
export const useMachineSnackFilling = (
  machineId: number,
): [EditMachineStorageSnackProductCell[][], Record<number, SnackCellPriceWithCellId>] => {
  const dispatch = useAppDispatch();

  const { state: machineStorageInfo } = useAppSelector(selectMachineStorageInfo());
  const { state: machinePricesInfo } = useAppSelector(selectMachinePricesInfo());

  useEffect(() => {
    if (machineId) {
      dispatch(getMachineStorageInfoAction(machineId));
      dispatch(getMachinePricesInfoAction(machineId));
    }
  }, [dispatch, machineId]);

  const snackCellsGroupedByRowNumber = useMemo(() => {
    const maxRowNumber =
      machineStorageInfo?.snackCells.reduce((max, cell) => Math.max(max, cell.rowNumber), 0) || 0;

    const cellsByRow: EditMachineStorageSnackProductCell[][] = Array.from(
      { length: maxRowNumber + 1 },
      () => [],
    );

    (machineStorageInfo?.snackCells || []).forEach((cell) => {
      const {
        rowNumber,
        snackBrandId,
        snackProductId,
        id,
        maxVolume,
        warningVolume,
        minVolume,
        isManuallyDisabled,
      } = cell;

      const editCell: EditMachineStorageSnackProductCell = {
        id,
        brandId: snackBrandId,
        productId: snackProductId,
        maxVolume,
        warningVolume,
        minVolume,
        isManuallyDisabled,
      };

      cellsByRow[rowNumber].push(editCell);
    });

    return cellsByRow.filter((row) => row.length > 0);
  }, [machineStorageInfo]);

  const snackProductsWithPrices = useMemo(() => {
    return getSnackProductsWithPrices(machinePricesInfo);
  }, [machinePricesInfo]);

  return [snackCellsGroupedByRowNumber, snackProductsWithPrices];
};

/**
 * Получение списка брендов для снек автомата
 */
export const useSnackBrandList = () => {
  const dispatch = useAppDispatch();
  const organizationId = getOrganizationId();
  const { state: snackBrandList } = useAppSelector(selectSnackBrandList());

  useEffect(() => {
    organizationId && dispatch(getSnackBrandListAction(organizationId));
  }, [dispatch, organizationId]);

  return useMemo(() => {
    return snackBrandList || { brandList: [] };
  }, [snackBrandList]);
};
