import { FC, useEffect, useMemo, useState } from 'react';
import styles from './PromoCodeProductFormBrandItem.module.scss';
import { Combobox } from '@consta/uikit/Combobox';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '../../../../assets/icon/iconTrash';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { useAppDispatch } from '../../../../app/hooks/store';
import {
  getBrandListByCategoryViewAction,
  getIngredientLineListByCategoryViewAction,
  getIngredientListByCategoryViewAction,
} from '../../../../state/productBase/actions';
import {
  BrandId,
  IngredientLineId,
  ProductBaseItemDTO,
} from '../../../../types/serverInterface/promoCodeDTO';
import { useTranslation } from 'react-i18next';

type PromoCodeProductFormBrandItemProps = {
  isAllProduct: boolean;
  categoryIds: number[];
  viewIds: number[];
  brand: BrandId;
  onBrandChange: (brandId: number | null) => void;
  onIngredientLineChange: (lineIds: number[] | null) => void;
  onIngredientChange: (ingredientIds: IngredientLineId[] | null) => void;
  onDeleteClick: () => void;
};

const PromoCodeProductFormBrandItem: FC<PromoCodeProductFormBrandItemProps> = ({
  isAllProduct,
  viewIds,
  categoryIds,
  brand,
  onBrandChange,
  onIngredientLineChange,
  onIngredientChange,
  onDeleteClick,
}) => {
  const { t } = useTranslation();

  const [brandList, setBrandList] = useState<ProductBaseItemDTO[]>([]);
  const [ingredientLineList, setIngredientLineList] = useState<ProductBaseItemDTO[]>([]);
  const [ingredientList, setIngredientList] = useState<(ProductBaseItemDTO & { lineId: number })[]>(
    [],
  );

  const { id: brandId, ingredientLines } = brand;

  const dispatch = useAppDispatch();

  const brandComboboxValue = useMemo(
    () => brandList.find(({ id }) => id === brandId),
    [brandList, brandId],
  );

  useEffect(() => {
    dispatch(getBrandListByCategoryViewAction(categoryIds, viewIds)).then((res) => {
      setBrandList(res);
    });
  }, [dispatch, categoryIds, viewIds]);

  useEffect(() => {
    brandId &&
      dispatch(getIngredientLineListByCategoryViewAction(categoryIds, viewIds, brandId)).then(
        (res) => {
          setIngredientLineList(res);
        },
      );
  }, [dispatch, categoryIds, viewIds, brandId]);

  useEffect(() => {
    const fetchIngredients = async () => {
      if (ingredientLines) {
        const allItems = [];

        for (const { id } of ingredientLines) {
          const res = await dispatch(
            getIngredientListByCategoryViewAction(categoryIds, viewIds, [id]),
          );
          const newItems = res.map((item) => ({
            ...item,
            lineId: id,
          }));
          allItems.push(...newItems);
        }

        setIngredientList(allItems);
      }
    };

    fetchIngredients();
  }, [JSON.stringify(ingredientLines?.map(({ id }) => id)), dispatch, categoryIds, viewIds]);

  // Вспомогательные методы
  const getIngredientLineById = (ingredientLineId: number): ProductBaseItemDTO =>
    ingredientLineList.find(({ id }) => id === ingredientLineId) || ({} as ProductBaseItemDTO);

  const getIngredientsValue = (): (ProductBaseItemDTO & { lineId: number })[] | null => {
    const map: Record<number, number[] | null> = {};

    if (!ingredientLines) return null;

    ingredientLines.forEach(({ ingredients, id }) => {
      map[id] = ingredients;
    });

    return ingredientList.filter(({ id, lineId }) =>
      (map?.[lineId] || []).find((ingredientId) => ingredientId === id),
    );
  };

  // Обработчики
  const handleIngredientChange = ({
    value,
  }: {
    value: (ProductBaseItemDTO & { lineId: number })[] | null;
  }) => {
    const map: Record<number, number[]> = {};

    value?.forEach(({ id, lineId }) => {
      if (map?.[lineId]) {
        map[lineId] = [...map[lineId], id];
      }

      if (!map?.[lineId]) {
        map[lineId] = [id];
      }
    });

    if (!ingredientLines) return null;

    const ingredientLineList: IngredientLineId[] = ingredientLines?.map(
      ({ id }): IngredientLineId => ({ ingredients: map?.[id] || null, id }),
    );

    onIngredientChange(ingredientLineList);
  };

  return (
    <HorizontalContainer className={styles.tertiaryCard} align="end">
      <Combobox
        disabled={isAllProduct}
        label={t('promoCode.product.form.brandItem.brand.combobox.label')}
        items={brandList}
        placeholder={t('promoCode.product.form.brandItem.brand.combobox.placeholder')}
        getItemLabel={({ name }) => name}
        getItemKey={({ id }) => id}
        value={brandComboboxValue}
        onChange={({ value }) => {
          onBrandChange(value && value.id);
        }}
      />
      <Combobox
        disabled={isAllProduct}
        label={t('promoCode.product.form.brandItem.ingredientLine.combobox.label')}
        items={ingredientLineList}
        placeholder={t('promoCode.product.form.brandItem.ingredientLine.combobox.placeholder')}
        multiple
        getItemLabel={({ name }) => name}
        getItemKey={({ id }) => id}
        value={ingredientLines?.map(({ id }) => getIngredientLineById(id)) || []}
        onChange={({ value }) => {
          onIngredientLineChange(value && value.map(({ id }) => id));
        }}
      />
      <Combobox
        disabled={isAllProduct}
        label={t('promoCode.product.form.brandItem.product.combobox.label')}
        items={ingredientList}
        groups={ingredientLineList}
        value={getIngredientsValue()}
        multiple
        placeholder={t('promoCode.product.form.brandItem.product.combobox.placeholder')}
        getItemLabel={({ name }) => name}
        getItemKey={({ id }) => id}
        getGroupLabel={({ name }) => name}
        getGroupKey={({ id }) => id}
        getItemGroupKey={({ lineId }) => lineId}
        onChange={handleIngredientChange}
      />
      <Button view="ghost" iconLeft={IconTrash as any} onlyIcon onClick={onDeleteClick} />
    </HorizontalContainer>
  );
};

export default PromoCodeProductFormBrandItem;
