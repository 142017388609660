import React, { FC, useEffect, useState } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import VendistaSignInForm from './VendistaSignInForm';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconClose } from '@consta/uikit/IconClose';
import VerticalContainer from '../../../../components/VerticalContainer';
import ContentCard from '../../../../components/ContentCard';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import styles from './VendistaSettings.module.scss';
import { IconLogoVendista } from '../../../../assets/icon/iconLogoVendista';
import { defaultIconProps } from '../../../../consts/defaultIconProps';
import { VendistaSettingsProps } from './types';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectCabinet } from '../../../../state/user/selectors';
import { getCabinetAction } from '../../../../state/user/actions';
import VendistaSignOutForm from './VendistaSignOutForm';
import { useTranslation } from 'react-i18next';

/**
 * Страница настройки Vendista
 */
const VendistaSettings: FC<VendistaSettingsProps> = ({
  organizationId,
  isOpenSignInModal,
  handleSignInVendistaModalClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectCabinet());

  const [isOpenDeleteVendistaModal, setIsOpenDeleteVendistaModal] = useState(false);

  const userLabel = `${user?.name} ${user?.surname}`;

  useEffect(() => {
    dispatch(getCabinetAction());
  }, [dispatch]);

  const handleCloseDeleteVendistaModal = () => {
    setIsOpenDeleteVendistaModal(false);
  };

  const handleOpenDeleteVendistaModal = () => {
    setIsOpenDeleteVendistaModal(true);
  };

  // render методы
  const renderVendistaInfo = () => (
    <HorizontalContainer space="m">
      <IconLogoVendista size="m" className={styles.VendistaLogo} {...defaultIconProps} />
      <VerticalContainer space={0}>
        <Text size="l" weight="medium">
          {userLabel}
        </Text>
        <Text size="m" view="success">
          {t('integration.vendista.info.status.connected.label')}
        </Text>
      </VerticalContainer>
    </HorizontalContainer>
  );

  const renderDeleteButton = () => (
    <HorizontalContainer space="s">
      <Button
        size="m"
        view="clear"
        onClick={handleOpenDeleteVendistaModal}
        iconLeft={IconClose as any}
        onlyIcon
      />
    </HorizontalContainer>
  );

  const renderVendistaSettings = () => (
    <VerticalContainer space="m">
      <ContentCard className={styles.VendistaNotification}>
        <HorizontalContainer isAutoSpace>
          {renderVendistaInfo()}
          {renderDeleteButton()}
        </HorizontalContainer>
      </ContentCard>
    </VerticalContainer>
  );

  const renderSignInForm = () =>
    isOpenSignInModal &&
    organizationId && (
      <VendistaSignInForm
        organizationId={organizationId}
        onClose={handleSignInVendistaModalClose}
      />
    );

  const renderSignOutForm = () => (
    <VendistaSignOutForm
      isOpen={isOpenDeleteVendistaModal}
      onClose={handleCloseDeleteVendistaModal}
    />
  );

  return (
    <VerticalContainer space="3xl" className={styles.VendistaSettings}>
      {renderVendistaSettings()}
      {renderSignInForm()}
      {renderSignOutForm()}
    </VerticalContainer>
  );
};

export default VendistaSettings;
