import { FC } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import styles from './MachineSnackFillingTable.module.scss';
import MachineSnackFillingTableHeader from './MachineSnackFillingTableHeader';
import MachineSnackFillingTableContent from './MachineSnackFillingTableContent';
import { MachineSnackFillingTableProps } from './types';

/**
 * Таблица с наполнением снек автомата
 */
const MachineSnackFillingTable: FC<MachineSnackFillingTableProps> = ({
  groupedSnackCells,
  snackBrandList,
  snackProductsWithPrices,
  handleBrandChange,
  handleProductChange,
  handlePriceChange,
  fillingFormErrors,
  priceMapErrors,
}) => {
  return (
    <ContentCard className={styles.MachineSnackFillingTable}>
      <table className={styles.table}>
        <MachineSnackFillingTableHeader />
        <MachineSnackFillingTableContent
          groupedSnackCells={groupedSnackCells}
          snackBrandList={snackBrandList}
          snackProductsWithPrices={snackProductsWithPrices}
          handleBrandChange={handleBrandChange}
          handleProductChange={handleProductChange}
          handlePriceChange={handlePriceChange}
          fillingFormErrors={fillingFormErrors}
          priceMapErrors={priceMapErrors}
        />
      </table>
    </ContentCard>
  );
};

export default MachineSnackFillingTable;
