import { AppDispatch } from '../../app/store';
import {
  createTelegramNotificationSettingThunk,
  deleteTelegramNotificationSettingThunk,
  editTelegramNotificationSettingThunk,
  getTelegramNotificationSettingsByOrganizationIdThunk,
} from './thunk';
import {
  CreateTelegramNotificationSettingDTO,
  EditTelegramNotificationSettingDTO,
} from '../../types/serverInterface/notificationDTO';

/**
 * Получение списка настроек телеграм уведомлений по id организации
 *
 * @param organizationId id организации
 */
export const getTelegramNotificationSettingsByOrganizationIdAction =
  (organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(getTelegramNotificationSettingsByOrganizationIdThunk(organizationId));

/**
 * Создание настройки телеграм уведомления
 *
 * @param data данные формы
 */
export const createTelegramNotificationSettingAction =
  (data: CreateTelegramNotificationSettingDTO) => (dispatch: AppDispatch) =>
    dispatch(createTelegramNotificationSettingThunk(data));

/**
 * Изменения настройки телеграм уведомления
 *
 * @param data данные формы
 * @param settingId id настройки
 */
export const editTelegramNotificationSettingAction =
  (data: EditTelegramNotificationSettingDTO, settingId: number) => (dispatch: AppDispatch) =>
    dispatch(editTelegramNotificationSettingThunk({ data, settingId }));

/**
 *
 * Удаление телеграм уведомления
 *
 * @param settingId id телеграм уведомления
 * @param organizationId id организации
 */
export const deleteTelegramNotificationSettingAction =
  (settingId: number, organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(deleteTelegramNotificationSettingThunk(settingId)).then(() =>
      dispatch(getTelegramNotificationSettingsByOrganizationIdAction(organizationId)),
    );
