import { FC, useEffect, useState } from 'react';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import styles from './MachineListHeader.module.scss';
import ModelFilter from '../../../../components/Machine/ModelFilter';
import StatusFilter from '../../../../components/Machine/StatusFilter';
import { useTranslation } from 'react-i18next';
import MachineRegistrationModal from './MachineRegistrationModal';
import useDebounce from '../../../../hoooks/useDebounce';
import { IconAdd } from '../../../../assets/icon/iconAdd';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import ShutdownFilter from '../../../../components/Machine/ShutdownFilter';
import { MachineListHeaderProps } from './types';
import { SkeletonBrick } from '@consta/uikit/Skeleton';

const machineListHeaderLoaderHeight = 40;

/**
 * Шапка таблицы списка автоматов
 */
const MachineListHeader: FC<MachineListHeaderProps> = ({
  isArchived,
  isLoading,
  searchText,
  listCount,
  successCount,
  warningCount,
  alertCount,
  selectedModelId,
  modelList,
  selectedStatus,
  selectedShutdownStatus,
  inStockNumber,
  scrapNumber,
  soldNumber,
  repairNumber,
  selectedSaleType,
  onSearchChange,
  onSelectModelId,
  onSelectShutdownStatus,
  onSelectedStatus,
  onSaleTypeChange,
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState(searchText);
  const [isOpenKey, setIsOpenKey] = useState(false);
  const debouncedSearch = useDebounce(searchValue, 1000);

  useEffect(() => {
    onSearchChange(debouncedSearch);
  }, [debouncedSearch, onSearchChange]);

  // Обработчики
  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchValue(value || '');
  };

  const handleOpenKey = () => {
    setIsOpenKey(true);
  };

  const handleCloseKey = () => {
    setIsOpenKey(false);
  };

  // render методы
  const renderAddButton = () =>
    !isArchived && (
      <Button
        label={t('machineControl.machine.list.add.button.label')}
        iconLeft={IconAdd as any}
        onClick={handleOpenKey}
      />
    );

  const renderSearchField = () => (
    <TextField
      width="full"
      placeholder={t('machineControl.machine.list.search.input.placeholder')}
      leftSide={IconSearch as any}
      value={searchValue}
      onChange={handleSearchChange}
    />
  );

  const renderModelFilter = () => (
    <ModelFilter
      modelList={modelList}
      selectedModelId={selectedModelId}
      onSelectModelId={onSelectModelId}
    />
  );

  const renderStatusFilter = () =>
    !isArchived ? (
      <StatusFilter
        listCount={listCount}
        successMachineNumber={successCount}
        alertMachineNumber={alertCount}
        warningMachineNumber={warningCount}
        selectedStatus={selectedStatus}
        onSelectedStatus={onSelectedStatus}
      />
    ) : (
      <ShutdownFilter
        listCount={listCount}
        inStockNumber={inStockNumber}
        repairNumber={repairNumber}
        selectedShutdownStatus={selectedShutdownStatus}
        scrapNumber={scrapNumber}
        soldNumber={soldNumber}
        onSelectShutdownStatus={onSelectShutdownStatus}
      />
    );

  const renderSkeletonLoader = () => (
    <SkeletonBrick
      className={styles.MachineListHeaderLoader}
      height={machineListHeaderLoaderHeight}
    />
  );

  return (
    <div className={styles.MachineListHeader}>
      {!isLoading ? (
        <>
          {renderAddButton()}
          <MachineRegistrationModal isOpenKey={isOpenKey} onClose={handleCloseKey} />
          {renderSearchField()}
          {renderModelFilter()}
          {renderStatusFilter()}
        </>
      ) : (
        renderSkeletonLoader()
      )}
    </div>
  );
};

export default MachineListHeader;
