import { FC, useRef } from 'react';
import {
  LogMachines,
  LogMachinesTypeEnum,
} from '../../../../../../types/serverInterface/machineDTO';
import HorizontalContainer from '../../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { transformDate } from '../../../../../../helpers/transformDate';
import { IconCheck } from '../../../../../../assets/icon/iconCheck';
import { defaultIconProps } from '../../../../../../consts/defaultIconProps';
import { IconWarning } from '../../../../../../assets/icon/iconWarning';
import { IconTrash } from '@consta/uikit/IconTrash';
import TextWithTooltip from '../../../../../../components/withTooltip/Text';
import classNames from 'classnames';
import styles from './MachineHomePageLog.module.scss';
import { IconAlert } from '../../../../../../assets/icon/iconAlert';

/**
 * Свойства компонента MachineHomePageLog
 */
type MachineHomePageLogProps = {
  /**
   * Лог автомата
   */
  log: LogMachines;
};

/**
 * Сообщение автомата
 */
const MachineHomePageLog: FC<MachineHomePageLogProps> = ({ log }) => {
  const descriptionRef = useRef<HTMLDivElement>(null);

  const { description, date: logDate, type, name } = log;
  const [date, time] = transformDate(logDate).split(' ');

  const isTextTruncated =
    (descriptionRef.current?.scrollWidth || 0) > (descriptionRef.current?.clientWidth || 0) ||
    (descriptionRef.current?.scrollHeight || 0) > (descriptionRef.current?.clientHeight || 0);

  const renderDescription = () =>
    isTextTruncated ? (
      <TextWithTooltip
        tooltipProps={{
          content: description,
          size: 'l',
        }}
        ref={descriptionRef}
        className={styles.text}
        size="s"
        view="secondary"
      >
        {description}
      </TextWithTooltip>
    ) : (
      <Text ref={descriptionRef} className={styles.text} size="s" view="secondary">
        {description}
      </Text>
    );

  // Вспомогательные методы
  const getIcon = (type: LogMachinesTypeEnum) => {
    switch (type) {
      case LogMachinesTypeEnum.SUCCESS:
        return (
          <IconCheck
            size="m"
            className={classNames(styles.successIcon, styles.icon)}
            {...defaultIconProps}
          />
        );
      case LogMachinesTypeEnum.WARNING:
        return (
          <IconAlert
            size="m"
            className={classNames(styles.warningIcon, styles.icon)}
            {...defaultIconProps}
          />
        );
      case LogMachinesTypeEnum.ALERT:
        return (
          <IconWarning
            size="m"
            className={classNames(styles.alertIcon, styles.icon)}
            {...defaultIconProps}
          />
        );
      default:
        return <IconTrash size="m" className={styles.icon} {...defaultIconProps} />;
    }
  };

  return (
    <HorizontalContainer className={styles.MachineHomePageLog} isAutoSpace align="center">
      <HorizontalContainer space="s" align="center">
        {getIcon(type)}
        <VerticalContainer space="3xs">
          <Text className={styles.text} size="m" weight="semibold">
            {name}
          </Text>
          {renderDescription()}
        </VerticalContainer>
      </HorizontalContainer>
      <VerticalContainer align="end" space="2xs">
        <Text className={styles.text} size="s" view="secondary">
          {date}
        </Text>
        <Text className={styles.text} size="s" view="secondary">
          {time}
        </Text>
      </VerticalContainer>
    </HorizontalContainer>
  );
};

export default MachineHomePageLog;
