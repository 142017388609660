import styles from './MachineStorage.module.scss';
import { ProductGroup, ProductView } from '../../../types/serverInterface/storageDTO';
import { MachineCellGroup, MachineCellGroupUnion } from '../../../types/serverInterface/machineDTO';
import { MachineModelProductCellByList } from '../../../types/serverInterface/machineModelDTO';
import {
  MachineStorageCupCellType,
  MachineStorageDisposableCellType,
  MachineStorageProductCellType,
  MachineStorageWaterCellType,
  SeparatedMachineCellUnionType,
  SeparatedMachineHomeCellUnionType,
  SeparatedMachineStorageType,
} from './types';
import { ProductCellsByList } from '../../../types/serverInterface/machineListDTO';

/**
 * Сортировка групп склада по номеру контейнера
 *
 * @param storage склад
 */
export const getSortedGroup = (storage: SeparatedMachineStorageType) => {
  const arr: Array<ProductGroup> = [];

  for (const key in storage.cells) {
    const minCellNumber = storage.cells[key as ProductGroup]?.length
      ? storage.cells[key as ProductGroup].reduce(
          (acc, item) => (!acc || item.info.cellNumber < acc ? item.info.cellNumber : acc),
          0,
        )
      : null;

    if (minCellNumber !== null) {
      arr[minCellNumber] = key as ProductGroup;
    }
  }

  return arr.splice(1).filter(Boolean);
};

/**
 * Сортировка групп продуктов склада по номеру контейнера
 *
 * @param storage склад
 */
export const getSortedProductGroup = (storage: {
  [key in ProductGroup]: (ProductCellsByList | MachineModelProductCellByList)[];
}) => {
  const arr: Array<ProductGroup> = [];

  for (const key in storage) {
    const minCellNumber = storage[key as ProductGroup].reduce(
      (acc, item) => (!acc || item.cellNumber < acc ? item.cellNumber : acc),
      0,
    );

    arr[minCellNumber] = key as ProductGroup;
  }

  return arr.splice(1).filter(Boolean);
};

/**
 * Получить тему для ячейки
 *
 * @param group группа
 * @param view вариант внешнего вида
 */
export const getViewStorageItemClassName = (
  group: MachineCellGroupUnion | null,
  view: ProductView | undefined,
) => {
  switch (view) {
    case ProductView.PRIMARY:
      return styles.viewPrimary;
    case ProductView.SECONDARY:
      return styles.viewSecondary;
    case ProductView.COFFEE:
      return styles.viewCoffee;
    case ProductView.DEFAULT:
      return styles.viewDefault;
  }

  switch (group) {
    case MachineCellGroup.WATER:
      return styles.viewWater;
    case MachineCellGroup.CUP:
      return styles.viewDisposable;
    case MachineCellGroup.DISPOSABLE:
      return styles.viewDisposable;
  }
};

export const isWater = (
  storageCell: SeparatedMachineCellUnionType | SeparatedMachineHomeCellUnionType,
  group: MachineCellGroupUnion,
): storageCell is MachineStorageWaterCellType => {
  return group === MachineCellGroup.WATER;
};

export const isProduct = (
  storageCell: SeparatedMachineCellUnionType | SeparatedMachineHomeCellUnionType,
  group: MachineCellGroupUnion,
): storageCell is MachineStorageProductCellType => {
  return (
    group === ProductGroup.CONCENTRATE ||
    group === ProductGroup.POWDER ||
    group === ProductGroup.COFFEE
  );
};

export const isCup = (
  storageCell: SeparatedMachineCellUnionType | SeparatedMachineHomeCellUnionType,
  group: MachineCellGroupUnion,
): storageCell is MachineStorageCupCellType => {
  return group === MachineCellGroup.CUP;
};

export const isDisposable = (
  storageCell: SeparatedMachineCellUnionType | SeparatedMachineHomeCellUnionType,
  group: MachineCellGroupUnion,
): storageCell is MachineStorageDisposableCellType => {
  return group === MachineCellGroup.DISPOSABLE;
};

export const getCell = (
  storageCell: SeparatedMachineCellUnionType | SeparatedMachineHomeCellUnionType,
  cellGroup: MachineCellGroupUnion,
): {
  name?: string | null | undefined;
  brandName?: string | null | undefined;
  ingredientLineName?: string | null | undefined;
  ingredientName?: string | null | undefined;
  cellNumber: number;
  valuePercent: number | undefined;
  view: ProductView | undefined;
  volume: number | undefined;
  maxVolume: number | undefined;
  cupVolume: number | undefined;
  cellCategoryId: number | undefined;
  isActive: boolean | null | undefined;
  isCount: boolean | undefined;
} => {
  if (isProduct(storageCell, cellGroup)) {
    const {
      maxVolume,
      volume,
      cellCategoryId,
      cellNumber,
      isActive,
      brandName,
      ingredientLineName,
      ingredientName,
    } = storageCell.info;

    return {
      brandName,
      ingredientLineName,
      ingredientName,
      isActive,
      view: storageCell.info.view,
      cellCategoryId,
      cellNumber,
      cupVolume: undefined,
      isCount: true,
      maxVolume,
      volume,
      valuePercent: volume ? (maxVolume ? (volume / maxVolume) * 100 : 100) : 0,
    };
  }

  if (isWater(storageCell, cellGroup) && storageCell.info.cellNumber) {
    const { maxVolume, volume, cellNumber, isCount } = storageCell.info;

    return {
      isActive: undefined,
      view: undefined,
      cellCategoryId: undefined,
      cellNumber: cellNumber,
      cupVolume: undefined,
      isCount,
      maxVolume,
      volume,
      valuePercent: isCount ? (volume ? (maxVolume ? (volume / maxVolume) * 100 : 100) : 0) : 100,
    };
  }

  if (isCup(storageCell, cellGroup)) {
    const { maxVolume, volume, cellNumber, isCount, cupVolume } = storageCell.info;

    return {
      name: undefined,
      isActive: undefined,
      view: undefined,
      cellCategoryId: undefined,
      cellNumber,
      cupVolume,
      isCount,
      maxVolume,
      volume,
      valuePercent: volume ? (maxVolume ? (volume / maxVolume) * 100 : 100) : 0,
    };
  }

  if (isDisposable(storageCell, cellGroup)) {
    const { volume, cellNumber, name, isCount } = storageCell.info;

    return {
      name,
      isActive: undefined,
      view: undefined,
      cellCategoryId: undefined,
      cellNumber,
      cupVolume: undefined,
      isCount,
      maxVolume: undefined,
      volume,
      valuePercent: undefined,
    };
  }

  return {
    name: undefined,
    isActive: undefined,
    view: undefined,
    cellCategoryId: undefined,
    cellNumber: 0,
    cupVolume: undefined,
    isCount: undefined,
    maxVolume: undefined,
    volume: undefined,
    valuePercent: undefined,
  };
};
