import * as Yup from 'yup';

/**
 * Схема валидации создания продукта
 */
export const creationValidationScheme = Yup.object().shape({
  name: Yup.string().required('Название обязательно'),
  brandId: Yup.number().required('ID продукта обязателен'),
  calories: Yup.number().nullable().min(0, 'Калории не могут быть отрицательными'),
  proteins: Yup.number().nullable().min(0, 'Белки не могут быть отрицательными'),
  fats: Yup.number().nullable().min(0, 'Жиры не могут быть отрицательными'),
  carbohydrates: Yup.number().nullable().min(0, 'Углеводы не могут быть отрицательными'),
  barcode: Yup.string().required('Штрих код обязателен'),

  vendorCode: Yup.string(),
  compound: Yup.string(),
  allergens: Yup.string(),
  description: Yup.string(),
});
