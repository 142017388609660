import { FC, useState } from 'react';
import { Text, TextPropView } from '@consta/uikit/__internal__/src/components/Text';
import ContentCard from '../../../../../components/ContentCard';
import React from 'react';
import MachineSettingsGroupAction from './MachineSettingsGroupAction';
import styles from './MachineSettingsActions.module.scss';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { ConnectionStatus, StatusColor } from '../../../../../types/serverInterface/machineDTO';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import { sendMachineCustomActionAction } from '../../../../../state/machineControl/actions';
import { selectMachineBaseInfo } from '../../../../../state/machineControl/selectors';
import MachineOfflineBlockModal from '../../MachineOfflineBlockModal';
import { MachineSettingsActionsProps } from './MachineSettingsGroupAction/types';
import { useTranslation } from 'react-i18next';

const getMachineLogoFileName = (modelName: string) => {
  switch (modelName) {
    case 'ShakerTouch':
      return '/img/ShakerTouch.png';
    default:
      return '/img/milk.png';
  }
};

const getStatus = (color: StatusColor | undefined): TextPropView => {
  switch (color) {
    case StatusColor.ERROR:
      return 'alert';
    case StatusColor.WARNING:
      return 'warning';
    case StatusColor.SUCCESS:
      return 'success';
  }
  return 'alert';
};

const MachineSettingsActions: FC<MachineSettingsActionsProps> = ({
  machine,
  actions,
  machineId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());
  const { serialNumber, modelName } = machine;

  const [isActionCodeOfflineBlock, setIsActionCodeOfflineBlock] = useState<string | null>(null);
  const logoImagePath = process.env.PUBLIC_URL + getMachineLogoFileName(modelName);

  const handleActionClick = (code: string) => {
    if (machineBaseInfo && machineBaseInfo.connectionStatus === ConnectionStatus.OFFLINE) {
      setIsActionCodeOfflineBlock(code);
      return null;
    }
    dispatch(sendMachineCustomActionAction({ machineId, code }));
  };

  const handleEditOfflineBlockModalClose = () => {
    setIsActionCodeOfflineBlock(null);
  };

  const renderMachineInfo = () => (
    <ContentCard className={styles.MachineSettingsInfo}>
      <MachineOfflineBlockModal
        isOpen={!!isActionCodeOfflineBlock}
        onCancel={handleEditOfflineBlockModalClose}
      />
      <HorizontalContainer space="l" align="center">
        <div className={styles.machineImgWrapper}>
          <img src={logoImagePath} alt="machine-logo" />
        </div>
        <VerticalContainer space={0} align="start">
          <Text size="l" weight="medium">
            {modelName}
          </Text>
          <Text size="s" weight="medium" view="secondary">
            {serialNumber}
          </Text>
        </VerticalContainer>
      </HorizontalContainer>
      {renderCustomActions()}
    </ContentCard>
  );

  const renderCustomActions = () => (
    <HorizontalContainer space="2xs">
      {actions?.map((group, index) => (
        <MachineSettingsGroupAction key={index} group={group} onActionClick={handleActionClick} />
      ))}
    </HorizontalContainer>
  );

  // TODO: написать handler для кнопки
  const renderMachineStatus = () => (
    <ContentCard className={styles.MachineSettingsStatus}>
      <VerticalContainer space="2xs" align="start">
        <Text size="m" weight="medium">
          {t('machineControl.machine.settings.status.label')}
        </Text>
        <Text size="m" view={getStatus(machineBaseInfo?.status.color)}>
          {machineBaseInfo?.status.text}
        </Text>
      </VerticalContainer>
      {/*<Button size="m" view="clear" iconLeft={IconRing as any} />*/}
    </ContentCard>
  );

  // TODO: исправить номер версии после внесения правок в бек
  /*const renderVersionNumber = () => (
    <ContentCard className={styles.MachineSettingsVersionNumber}>
      <VerticalContainer space="2xs">
        <Text size="m" weight="medium">
          {t('machineControl.machine.settings.versionNumber.label')}
        </Text>
        <Text size="s" view="secondary">
          24.1.2
        </Text>
      </VerticalContainer>
    </ContentCard>
  );*/

  return (
    <HorizontalContainer space="s" className={styles.MachineSettingsActions}>
      {renderMachineInfo()}
      {renderMachineStatus()}
      {/*{renderVersionNumber()}*/}
    </HorizontalContainer>
  );
};

export default MachineSettingsActions;
