import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CheckVendistaTokenDTO,
  EditMachineVendistaSettingDTO,
  EditVendistaDTO,
  MachineVendistaSettingDTO,
} from '../../types/serverInterface/vendistaDTO';
import { api } from '../../app/api';

/**
 * Изменение токена vendista
 *
 * @param data данные формы изменения токена
 * @param organizationId id организации
 */
export const editVendistaTokenThunk = createAsyncThunk<
  EditVendistaDTO,
  { data: EditVendistaDTO; organizationId: number }
>('editVendistaToken', async ({ data, organizationId }) => {
  return await api.vendista.editVendistaToken(data, organizationId);
});

/**
 * Получение информации о состоянии токена vendista
 *
 * @param organizationId id организации
 */
export const checkVendistaTokenThunk = createAsyncThunk<CheckVendistaTokenDTO, number>(
  'checkVendistaToken',
  async (organizationId) => {
    return await api.vendista.checkVendistaToken(organizationId);
  },
);

/**
 * Получение номера терминала vendista автомата
 *
 * @param machineId id автомата
 */
export const getMachineVendistaSettingThunk = createAsyncThunk<MachineVendistaSettingDTO, number>(
  'getMachineVendistaSetting',
  async (machineId) => {
    return await api.vendista.getMachineVendistaSetting(machineId);
  },
);

/**
 * Изменения номера терминала vendista автомата
 *
 * @param machineId id автомата
 * @param data данные формы изменения настроек vendista автомата
 */
export const editMachineVendistaSettingThunk = createAsyncThunk<
  number,
  { machineId: number; data: EditMachineVendistaSettingDTO }
>('editMachineVendistaSetting', async ({ machineId, data }) => {
  return await api.vendista.editMachineVendistaSetting(machineId, data);
});

/**
 * Проверка наличия интеграции vendista
 *
 * @param organizationId id организации
 */
export const checkVendistaIntegrationThunk = createAsyncThunk<boolean, number>(
  'checkVendistaIntegration',
  async (organizationId) => {
    return await api.vendista.checkVendistaIntegration(organizationId);
  },
);
