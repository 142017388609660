import BaseTable from '../BaseTable';

import { TableProps } from './types';

import { useMemo } from 'react';

import classNames from 'classnames';
import styles from './Table.module.scss';

/**
 * Основная таблицы
 */
const Table = <T,>({
  isLoading,

  withoutSettingsBar,
  withoutHeader,
  withoutData,

  tableContainerClassName = '',
  baseTableSettingsBarClassName = '',
  baseTableSettingsBarActionsClassName = '',
  baseTableSettingsBarPaginationClassName = '',
  baseTableHeaderClassName = '',
  gridContainerClassName = '',
  tableDataClassName = '',

  rowHeight,
  rowClassName = '',
  baseTableDataLoaderClassName = '',

  rows,
  columns,

  renderActions,
  onRowClick,
}: TableProps<T> & { tableContainerClassName?: string }) => {
  const formatedColumn = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        baseTableDataRowCellClassName: classNames(
          styles.baseTableDataRowCellClassName,
          column.baseTableDataRowCellClassName,
        ),
        baseTableHeaderColumnClassName: classNames(
          styles.baseTableHeaderColumnClassName,
          column.baseTableHeaderColumnClassName,
        ),
        baseTableSortButtonClassName: classNames(
          styles.baseTableSortButtonClassName,
          column.baseTableSortButtonClassName,
        ),
        baseTableSortButtonContextModelClassName: classNames(
          styles.baseTableSortButtonContextModelClassName,
          column.baseTableSortButtonContextModelClassName,
        ),
        baseTableFilterButtonContextModalClassName: classNames(
          styles.baseTableFilterButtonContextModalClassName,
          column.baseTableFilterButtonContextModalClassName,
        ),
        baseTableFilterButtonClassName: classNames(
          styles.baseTableFilterButtonClassName,
          column.baseTableFilterButtonClassName,
        ),
        baseTableFilterButtonActiveClassName: classNames(
          styles.baseTableFilterButtonActiveClassName,
          column.baseTableFilterButtonActiveClassName,
        ),
      })),
    [columns],
  );

  return (
    <div
      className={classNames(
        styles.tableContainer,
        isLoading && styles.isLoading,
        tableContainerClassName,
      )}
    >
      <BaseTable
        rowHeight={rowHeight}
        isLoading={isLoading}
        withoutSettingsBar={withoutSettingsBar}
        withoutHeader={withoutHeader}
        withoutData={withoutData}
        baseTableDataLoaderClassName={classNames(
          styles.baseTableDataLoaderClassName,
          baseTableDataLoaderClassName,
        )}
        baseTableSettingsBarClassName={classNames(
          styles.baseTableSettingsBarClassName,
          baseTableSettingsBarClassName,
        )}
        baseTableSettingsBarActionsClassName={classNames(
          styles.baseTableSettingsBarActionsClassName,
          baseTableSettingsBarActionsClassName,
        )}
        baseTableSettingsBarPaginationClassName={classNames(
          styles.baseTableSettingsBarPaginationClassName,
          baseTableSettingsBarPaginationClassName,
        )}
        baseTableHeaderClassName={classNames(
          styles.baseTableHeaderClassName,
          baseTableHeaderClassName,
        )}
        gridContainerClassName={classNames(styles.gridContainerClassName, gridContainerClassName)}
        rowClassName={classNames(styles.rowClassName, rowClassName)}
        tableDataClassName={classNames(styles.tableDataClassName, tableDataClassName)}
        rows={rows}
        columns={formatedColumn}
        renderActions={renderActions}
        onRowClick={onRowClick}
      />
    </div>
  );
};

export default Table;
