import { FC } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './MachineHomePageLogs.module.scss';
import { LogMachines } from '../../../../../types/serverInterface/machineDTO';
import MachineHomePageLog from './MachineHomePageLog';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента MachineHomePageLogs
 */
type MachineHomePageLogsProps = {
  /**
   * Сообщения автомата
   */
  logs: LogMachines[];
};

/**
 * Сообщения автомата
 */
const MachineHomePageLogs: FC<MachineHomePageLogsProps> = ({ logs }) => {
  const { t } = useTranslation();

  // render методы
  const renderLog = (log: LogMachines) => <MachineHomePageLog log={log} />;

  return (
    <ContentCard className={styles.MachineHomePageLogs}>
      <Text size="l" weight="semibold">
        {t('machineControl.machine.home.logs.title')}
      </Text>
      {logs?.map((log) => renderLog(log))}
    </ContentCard>
  );
};

export default MachineHomePageLogs;
