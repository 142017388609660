import { FC } from 'react';
import DefaultModal from '../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './MachineOfflineBlockModal.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента MachineOfflineBlockModal
 */
type MachineOfflineBlockModalProps = {
  /**
   * Флаг открытия модального окна
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия модального окна
   */
  onCancel: () => void;
};

/**
 * Модальное окно с предупреждением о том, что автомат не подключен к интернету
 */
const MachineOfflineBlockModal: FC<MachineOfflineBlockModalProps> = ({ isOpen, onCancel }) => {
  const { t } = useTranslation();

  const renderActions = () => (
    <>
      <Button
        size="l"
        label={t('machineControl.machine.modal.offlineBlock.ok.button.label')}
        onClick={onCancel}
      />
    </>
  );

  return (
    <DefaultModal
      className={styles.MachineOfflineBlockModal}
      isOpen={isOpen}
      modalTitle={t('machineControl.machine.modal.offlineBlock.title')}
      renderActions={renderActions}
      onClose={onCancel}
    >
      <Text size="l">{t('machineControl.machine.modal.offlineBlock.warning.text')}</Text>
    </DefaultModal>
  );
};

export default MachineOfflineBlockModal;
