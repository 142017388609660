import React, { FC, useMemo } from 'react';
import TabsBadge from '../../../../components/TabsBadge';
import { TabProps } from '../../../../components/TabsBadge/TabBadge/TabBadge';
import { useTranslation } from 'react-i18next';
import { MachineArchivedTabsProps } from './types';

/**
 * Табы переключения архивного и не архивного автомата
 */
const MachineArchivedTabs: FC<MachineArchivedTabsProps> = ({
  isArchived,
  isLoading,
  archivedCount,
  onArchiverChange,
}) => {
  const { t } = useTranslation();

  const tabsList = useMemo(
    (): TabProps[] => [
      {
        label: t('machineControl.machine.list.tabs.connectedMachines'),
        isSelect: !isArchived,
        value: archivedCount?.active,
        onClick: onArchiverChange(false),
      },
      {
        label: t('machineControl.machine.list.tabs.disconnectedMachines'),
        isSelect: !!isArchived,
        value: archivedCount?.archived,
        onClick: onArchiverChange(true),
      },
    ],
    [isArchived, onArchiverChange, archivedCount?.archived, archivedCount?.active, t],
  );

  return <TabsBadge tabsList={tabsList} isLoading={isLoading} size="m" />;
};

export default MachineArchivedTabs;
