import * as ExcelJS from 'exceljs';
import { useAppDispatch } from '../../../../../app/hooks/store';
import { getMachineSaLesListByPrintAction } from '../../../../../state/machineControl/actions';
import { PromoCodeBySaleDto, SaleMethod } from '../../../../../types/serverInterface/machineDTO';
import { TypeDiscountEnum } from '../../../../../types/serverInterface/promoCodeDTO';
import { useTranslation } from 'react-i18next';

export const useExportSalesFromExcel = ({
  serialNumber,
  page,
  limit,
}: {
  serialNumber: string;
  page: number;
  limit: number;
}): { handleExcelExportClick: () => void } => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return {
    handleExcelExportClick: () => {
      dispatch(getMachineSaLesListByPrintAction(serialNumber, page, limit)).then((res) => {
        const salesList = res?.data;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(
          t('machineControl.machine.sales.exportSales.salesList.label') + serialNumber,
        );
        worksheet.getCell('A1').value = t(
          'machineControl.machine.sales.exportSales.drinkName.label',
        );
        worksheet.getCell('B1').value = t(
          'machineControl.machine.sales.exportSales.dateTime.label',
        );
        worksheet.getCell('C1').value = t('machineControl.machine.sales.exportSales.price.label');
        worksheet.getCell('D1').value = t(
          'machineControl.machine.sales.exportSales.discountedPrice.label',
        );
        worksheet.getCell('E1').value = t(
          'machineControl.machine.sales.exportSales.paymentMethod.label',
        );
        worksheet.getCell('F1').value = t('machineControl.machine.sales.exportSales.volume.label');
        worksheet.getCell('G1').value = t(
          'machineControl.machine.sales.exportSales.promoCode.label',
        );
        worksheet.getCell('H1').value = t(
          'machineControl.machine.sales.exportSales.discount.label',
        );

        //Начинаем работать с внешним видом excel файла
        worksheet.eachRow((row) => {
          row.eachCell((cell) => {
            cell.style.font = { size: 16 };
            // Включаем автоматический перенос текста по словам
            cell.alignment = { wrapText: true, horizontal: 'left' };
          });
        });

        worksheet.getColumn('A').width = 29;
        worksheet.getColumn('B').width = 18;
        worksheet.getColumn('C').width = 19;
        worksheet.getColumn('D').width = 21;
        worksheet.getColumn('E').width = 22;
        worksheet.getColumn('F').width = 11;
        worksheet.getColumn('G').width = 16;
        worksheet.getColumn('H').width = 10;

        const getSaleMethod = (method: SaleMethod): string => {
          switch (method) {
            case SaleMethod.CARD:
              return t('machineControl.machine.sales.exportSales.salesMethod.CARD');
            case SaleMethod.CASH:
              return t('machineControl.machine.sales.exportSales.salesMethod.CASH');
            case SaleMethod.QR_CODE:
              return t('machineControl.machine.sales.exportSales.salesMethod.QR_CODE');
            case SaleMethod.RF_ID:
              return t('machineControl.machine.sales.exportSales.salesMethod.RF_ID');
          }
        };

        const getSalesLabel = (promoCode: PromoCodeBySaleDto): string => {
          if (promoCode.type === TypeDiscountEnum.FREE) {
            return t('promoCode.list.column.discount.label.FREE');
          }

          if (promoCode.type === TypeDiscountEnum.FIXED) {
            return `${promoCode.amount} ${t('promoCode.list.column.discount.label.FIXED')}`;
          }

          return `${promoCode.amount} ${t('promoCode.list.column.discount.label.PERCENT')}`;
        };

        salesList?.forEach(
          ({ name, unit, volume, dateSale, price, discountPrices, promoCode }, index) => {
            const rowExcelIndex = index + 2;
            worksheet.getCell(`A${rowExcelIndex}`).value = name;
            worksheet.getCell(`B${rowExcelIndex}`).value = dateSale;
            worksheet.getCell(`C${rowExcelIndex}`).value = price;
            worksheet.getCell(`D${rowExcelIndex}`).value =
              discountPrices?.reduce((acc, item) => {
                return acc + item.price;
              }, 0) || 0;
            worksheet.getCell(`E${rowExcelIndex}`).value = discountPrices
              ? discountPrices?.map((item) => getSaleMethod(item?.method as SaleMethod))?.join(', ')
              : t('machineControl.machine.sales.exportSales.salesMethod.NO_PAYMENT_METHOD');
            worksheet.getCell(`F${rowExcelIndex}`).value =
              volume + ' ' + unit === 'ML'
                ? t('machineControl.machine.sales.exportSales.volume.type.ml')
                : t('machineControl.machine.sales.exportSales.volume.unit.pcs');
            worksheet.getCell(`G${rowExcelIndex}`).value = promoCode?.code || '-';
            worksheet.getCell(`H${rowExcelIndex}`).value = promoCode?.code
              ? getSalesLabel(promoCode)
              : '-';
          },
        );

        const currentDate = new Date();

        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');

        const formattedDateTime = `${day}.${month}.${year} ${hours}-${minutes}-${seconds}`;

        //создание файла exel
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${t('machineControl.machine.sales.exportSales.machineSales.label')} ${serialNumber} ${formattedDateTime}.xlsx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      });
    },
  };

  // return {
  //   handleExcelExportClick: () => {
  //     console.log('serverError');
  //   },
  // };
};
