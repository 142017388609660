import TableColumns from './TableColumns';
import TableRow from './TableRow';
import styles from './TableCardWithBackground.module.scss';
import { useMemo, useRef, useState, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { ClassicTableProps } from '../ClassicTable/types';
import { SortDirection } from '../../types/enums/sortDirection';
import TableHeader from './TableHeader';
import { SkeletonBrick } from '@consta/uikit/Skeleton';

const defaultRowHeight = 48;
const headerHeight = 48;
const columnLoaderHeight = 32;

/**
 * Компонент таблицы вида "карточки с фоном"
 */
const TableCardWithBackground = <T extends Record<string, any>, K extends keyof T>(
  props: ClassicTableProps<T, K>,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [loaderRowCount, setLoaderRowCount] = useState<number>(0);

  const {
    withHeader = false,
    className,
    rows,
    columns,
    onRowClick,
    isLoading,
    rowHeight = defaultRowHeight,
  } = props;

  useLayoutEffect(() => {
    if (isLoading && containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      if (containerHeight > 0) {
        const count = Math.floor(containerHeight / rowHeight) - 1;
        if (count !== loaderRowCount) {
          setLoaderRowCount(count);
        }
      }
    }
  }, [isLoading]);

  const [sortOrder, setSortOrder] = useState<Record<K, SortDirection | null>>(
    {} as Record<K, SortDirection | null>,
  );

  const handleSortOrderChange = (newSortOrder: Record<K, SortDirection | null>) => {
    setSortOrder(newSortOrder);
  };

  const tableRows = useMemo(() => {
    return rows.map((data, index) => ({ ...data, index }));
  }, [rows]);

  // TODO: как то сделать это опционально. В целом даже бессмыслено
  const sortedRows = useMemo(() => {
    const sorted = [...tableRows];

    const compareFunction = (a: T, b: T) => {
      for (const key in sortOrder) {
        if (sortOrder?.[key] !== null) {
          const order = sortOrder?.[key] === SortDirection.ASC ? 1 : -1;
          const valueA = String(a[key]).toLowerCase();
          const valueB = String(b[key]).toLowerCase();

          if (valueA < valueB) return -order;
          if (valueA > valueB) return order;
        }
      }
      return 0;
    };

    sorted.sort(compareFunction);

    return sorted;
  }, [tableRows, sortOrder]);

  // Вспомогательные render методы
  const renderTableHeader = () =>
    withHeader &&
    (isLoading ? (
      <SkeletonBrick className={styles.loaderHeader} height={headerHeight} />
    ) : (
      <TableHeader {...props} />
    ));

  const renderTableColumns = () =>
    isLoading ? (
      <SkeletonBrick className={styles.loaderColumns} height={columnLoaderHeight} />
    ) : (
      <TableColumns columns={columns} sortOrder={sortOrder} onSortClick={handleSortOrderChange} />
    );

  const renderTableRows = () =>
    !isLoading &&
    sortedRows.map((data) => (
      <TableRow
        key={data.index}
        rowIndex={data.index}
        row={data}
        columns={columns}
        onRowClick={onRowClick}
      />
    ));

  const renderLoadingRows = () =>
    isLoading && (
      <div ref={containerRef} className={styles.loaderContainer}>
        {Array.from({ length: loaderRowCount }).map((_, index) => (
          <SkeletonBrick className={styles.loaderRow} key={index} height={rowHeight} />
        ))}
      </div>
    );

  return (
    <div
      className={classNames(
        styles.TableCardWithBackground,
        className,
        isLoading && styles.isLoading,
      )}
    >
      {renderTableHeader()}
      <table className={classNames(styles.table, withHeader && styles.withHeader)}>
        <thead className={styles.header}>{renderTableColumns()}</thead>
        <tbody className={styles.ClassicTableWithBackgroundRows}>{renderTableRows()}</tbody>
      </table>
      {renderLoadingRows()}
    </div>
  );
};

export default TableCardWithBackground;
