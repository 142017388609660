import { FC } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useTranslation } from 'react-i18next';

/**
 * Домашняя страница
 */
const HomePage: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Text>{t('homePage.title')}</Text>
    </div>
  );
};

export default HomePage;
