import React, { FC, useState } from 'react';
import VerticalContainer from '../../VerticalContainer';
import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ConnectionStatus } from '../../../types/serverInterface/machineDTO';
import HorizontalContainer from '../../HorizontalContainer';
import { Badge } from '@consta/uikit/Badge';
import { useAppSelector } from '../../../app/hooks/store';
import { selectMachineBaseInfo } from '../../../state/machineControl/selectors';
import styles from './PageNavigate.module.scss';
import { IconEdit2 } from '../../../assets/icon/iconEdit2';
import MachineNameEdit from './MachineNameEdit';
import { useTranslation } from 'react-i18next';

type PageNavigate = {
  label: string;
  href: string;
  // TODO: заменить на enum
  storageKey?: string;
  breadcrumbs: {
    label: string;
    href: string;
  }[];
};

const pageNavigateList: PageNavigate[] = [
  {
    label: 'components.pageNavigate.organizations',
    href: '/organization/list',
    breadcrumbs: [],
  },
  {
    label: 'components.pageNavigate.account',
    href: '/cabinet/*',
    breadcrumbs: [
      {
        label: 'components.pageNavigate.home',
        href: '/',
      },
      {
        label: 'components.pageNavigate.personalAccount',
        href: '/',
      },
    ],
  },
  {
    label: 'components.pageNavigate.machinePage',
    href: '/machineControl/machine/:machineId',
    storageKey: 'machineBaseInfo',
    breadcrumbs: [
      {
        label: 'components.pageNavigate.machines',
        href: '/machineControl/machine',
      },
      {
        label: 'components.pageNavigate.machineControl',
        href: '/machineControl/machine/:machineId',
      },
    ],
  },
  {
    label: 'components.pageNavigate.machinePage',
    href: '/machineControl/outlet/machine/:machineId',
    storageKey: 'machineBaseInfo',
    breadcrumbs: [
      {
        label: 'components.pageNavigate.retailOutlets',
        href: '/store/list',
      },
      {
        label: 'components.pageNavigate.machineControl',
        href: '/machineControl/outlet/machine/:machineId',
      },
    ],
  },
  {
    label: 'components.pageNavigate.organizationMachinesList',
    href: '/machineControl/machine',
    breadcrumbs: [],
  },
  {
    label: 'components.pageNavigate.employees',
    href: '/organization/members/list',
    breadcrumbs: [],
  },
  {
    label: 'components.pageNavigate.employees',
    href: '/organization/members/keys',
    breadcrumbs: [],
  },
  {
    label: 'components.pageNavigate.employee',
    href: '/organization/members/memberWithKey/:uuid',
    breadcrumbs: [
      {
        label: 'components.pageNavigate.employees',
        href: `/organization/members/list`,
      },
      {
        label: 'components.pageNavigate.vendingMachineAccess',
        href: `/organization/members/keys`,
      },
      {
        label: 'components.pageNavigate.employee',
        href: '/organization/members/memberWithKey/:uuid',
      },
    ],
  },
  {
    label: 'components.pageNavigate.promoCodes',
    href: '/promoCode/list',
    breadcrumbs: [],
  },
  {
    label: 'components.pageNavigate.promoCodeInformation',
    href: '/promoCode/info/:promoCodeId',
    breadcrumbs: [
      {
        label: 'components.pageNavigate.promoCodes',
        href: `/promoCode/list`,
      },
      {
        label: 'components.pageNavigate.promoCodeInformation',
        href: `/promoCode/info/:promoCodeId`,
      },
    ],
  },
  {
    label: 'components.pageNavigate.editPromoCode',
    href: '/promoCode/edit/:promoCodeId',
    breadcrumbs: [
      {
        label: 'components.pageNavigate.promoCodes',
        href: `/promoCode/list`,
      },
      // {
      //   label: 'Информация о промокоде',
      //   href: `/promoCode/info/${localStorage.getItem('promoCodeId')}`,
      // },
      {
        label: 'components.pageNavigate.editPromoCode',
        href: `/promoCode/edit/:promoCodeId`,
      },
    ],
  },
  {
    label: 'components.pageNavigate.createPromoCode',
    href: '/promoCode/create',
    breadcrumbs: [
      {
        label: 'components.pageNavigate.promoCodes',
        href: `/promoCode/list`,
      },
      {
        label: 'components.pageNavigate.createPromoCode',
        href: `/promoCode/create`,
      },
    ],
  },
  {
    label: 'components.pageNavigate.productBase',
    href: '/snackProductBase',
    breadcrumbs: [],
  },
  {
    label: 'components.pageNavigate.integrations',
    href: '/integrationSettings',
    breadcrumbs: [],
  },
];

/**
 * Верхнее меню блок навигации
 */
const PageNavigate: FC = () => {
  const { t } = useTranslation();

  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());
  const navigate = useNavigate();

  const [isOpenMachineNameEdit, setIsOpenMachineNameEdit] = useState(false);

  // Обработчики
  const handleBreadcrumbClick = (href: string) => {
    navigate(href);
  };

  // render методы
  const renderPageNavigate = (data: PageNavigate) => (
    <VerticalContainer space={0} justify="center">
      {data.breadcrumbs.length > 0 && (
        <Breadcrumbs
          className={styles.breadcrumbs}
          size="s"
          items={data.breadcrumbs.map((breadcrumb) => ({
            ...breadcrumb,
            label: t(breadcrumb.label),
            onClick: (e: React.MouseEvent) => {
              e.preventDefault();
              handleBreadcrumbClick(breadcrumb.href);
            },
          }))}
        />
      )}
      <HorizontalContainer align="center" space="xs" className={styles.machineBaseInfoContainer}>
        {machineBaseInfo && data.storageKey === 'machineBaseInfo' && (
          <Badge
            className={styles.badge}
            size="s"
            minified
            status={
              machineBaseInfo.connectionStatus === ConnectionStatus.ONLINE ? 'normal' : 'system'
            }
          />
        )}
        <Text size="2xl" weight="semibold">
          {machineBaseInfo?.machine.name && data.storageKey === 'machineBaseInfo'
            ? machineBaseInfo?.machine.name
            : t(data.label)}
        </Text>
        {machineBaseInfo && data.storageKey === 'machineBaseInfo' && (
          <Button
            onlyIcon
            view="clear"
            iconLeft={IconEdit2 as any}
            onClick={() => setIsOpenMachineNameEdit(true)}
          />
        )}
        {machineBaseInfo && data.storageKey === 'machineBaseInfo' && isOpenMachineNameEdit && (
          <MachineNameEdit
            machineBaseInfo={machineBaseInfo}
            onClose={() => setIsOpenMachineNameEdit(false)}
          />
        )}
      </HorizontalContainer>
    </VerticalContainer>
  );

  return (
    <>
      <Routes>
        {pageNavigateList.map((item) => (
          <Route key={item.href} path={item.href} element={renderPageNavigate(item)} />
        ))}
        <Route path="/*" element={<div />} />
      </Routes>
    </>
  );
};

export default PageNavigate;
