import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectMachineList } from '../../../state/machineControl/selectors';
import { useEffect, useState } from 'react';
import { MachineFiltersDTO, SalesFilters } from '../../../types/serverInterface/machineListDTO';
import { getMachineListWithFiltersAction } from '../../../state/machineControl/actions';

/**
 * Дефолтное значение фильтра списка автоматов
 */
export const machineListInitialFilters: MachineFiltersDTO = {
  name: null,
  isArchived: false,
  machineModelIds: null,
  salesFilter: SalesFilters.Last30Days,
  shutdownReasonFilter: null,
  organizationId: null,
  sortSaleDirection: null,
  sortCupsDirection: null,
  sortNameDirection: null,
  sortStatusDirection: null,
  sortWaterDirection: null,
  statusColors: null,
  connectionStatus: null,
  page: 1,
  limit: 1000,
  items: 10,
};

/**
 * hoc получения всех необходимых данный для страницы списка автоматов
 */
export const useMachineList = (organizationId: number | null) => {
  const dispatch = useAppDispatch();

  const { state, isLoading } = useAppSelector(selectMachineList());

  const [filters, setFilters] = useState<MachineFiltersDTO>({
    ...machineListInitialFilters,
    organizationId,
  });

  useEffect(() => {
    state &&
      setFilters({
        ...state.parameters,
        page: state.page,
        limit: state.limit,
        items: state.items,
      });
  }, [state]);

  useEffect(() => {
    dispatch(getMachineListWithFiltersAction(filters));

    // Это поведение верное. Таймер нужен для регулярного обновления списка автоматов
    // Это требование бизнеса. В дальнейшем переделается на push уведомления или на websocket
    const interval = setInterval(() => {
      organizationId && dispatch(getMachineListWithFiltersAction(filters));
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, organizationId, JSON.stringify(filters)]);

  return {
    machineList: state?.results,
    machineAtStatusesCount: state?.machineAtStatusesCount,
    machineAtConnectionStatusesCount: state?.machineAtConnectionStatusesCount,
    machineAtModelsCount: state?.machineAtModel,
    archivedCount: state?.archivedCount,
    machineAtShutdownCount: state?.machineAtShutdownCount,
    isLoading,
    filters,
    setFilters,
  };
};
