import { BaseTableSettingsBarActionsProps } from './types';

import { FC } from 'react';

import classNames from 'classnames';
import styles from './BaseTableSettingsBarActions.module.scss';

/**
 * Действия базовой таблицы
 */
const BaseTableSettingsBarActions: FC<BaseTableSettingsBarActionsProps> = ({
  baseTableSettingsBarActionsClassName,

  renderActions = () => <></>,
}) => {
  return (
    <div
      className={classNames(
        styles.BaseTableSettingsBarActions,
        baseTableSettingsBarActionsClassName,
      )}
    >
      {renderActions()}
    </div>
  );
};

export default BaseTableSettingsBarActions;
