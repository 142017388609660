import React, { FC, useState } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import styles from './MemberKeyProfile.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import VerticalContainer from '../../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconQR } from '../../../../../assets/icon/iconQR';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@consta/uikit/Avatar';
import { IconUnlock } from '../../../../../assets/icon/iconUnlock';
import { IconLock } from '../../../../../assets/icon/iconLock';
import { toggleKeyAction } from '../../../../../state/organization/actions';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import { MemberKeyProfileProps } from './types';
import BlockUnblockModal from '../../BlockUnblockModal';
import { selectEmployeeWithKeysList } from '../../../../../state/organization/selectors';

/**
 * Компонент с информацией о ключе пользователя
 */
const MemberKeyProfile: FC<MemberKeyProfileProps> = ({ employee, organizationId, onOpenQr }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const keysList = useAppSelector(selectEmployeeWithKeysList());

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { name, surname, patronymic, position, uuid, isActiveKey } = employee;

  const fullName = `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`;
  const key = keysList.find((key) => key.uuid === uuid)?.key;

  // Обработчики
  const handleBlockClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmitModal = () => {
    key &&
      dispatch(toggleKeyAction(key, organizationId)).then(() => {
        setIsModalOpen(false);
      });
  };

  // render методы
  const renderAvatarSection = () => (
    <HorizontalContainer space="m" align="center">
      <Avatar className={styles.avatar} name={fullName} />
      <VerticalContainer space={0}>
        <Text className={styles.text} size="l">
          {fullName}
        </Text>
        <Text className={styles.text} size="m" view="secondary">
          {t(`roles.${position}`)}
        </Text>
      </VerticalContainer>
    </HorizontalContainer>
  );

  const renderActionButtons = () => (
    <HorizontalContainer align="end" space="xs">
      <Button
        view="clear"
        size="m"
        onlyIcon
        iconLeft={(isActiveKey ? IconUnlock : IconLock) as any}
        onClick={handleBlockClick}
      />
      <Button view="clear" size="m" onlyIcon iconLeft={IconQR as any} onClick={onOpenQr} />
    </HorizontalContainer>
  );

  const renderModal = () => (
    <BlockUnblockModal
      isOpen={isModalOpen}
      isBlockAction={isActiveKey}
      onClose={handleModalClose}
      onSubmit={handleSubmitModal}
    />
  );

  return (
    <>
      {renderModal()}
      <ContentCard className={styles.MemberKeyProfile}>
        <Text size="l" weight="semibold">
          {t('organization.employee.key.profile.title')}
        </Text>
        <HorizontalContainer className={styles.profileCard} isAutoSpace align="center">
          {renderAvatarSection()}
          {renderActionButtons()}
        </HorizontalContainer>
      </ContentCard>
    </>
  );
};

export default MemberKeyProfile;
