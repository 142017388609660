import { FC } from 'react';
import ContentCard from '../../../ContentCard';
import { MachineStorageSnackSchemeProps } from './types';
import styles from './MachineStorageSnackScheme.module.scss';
import MachineStorageSnackSchemeItem from './MachineStorageSnackSchemeItem';

/**
 * Схема наполнения ячеек на главной странице снек автомата
 */
const MachineStorageSnackScheme: FC<MachineStorageSnackSchemeProps> = ({
  snackSchemeStorage,
  maxMotorsLengths,
}) => {
  // render метод
  const renderGrid = () => {
    return snackSchemeStorage.map((row, rowIndex) => (
      <div
        key={rowIndex}
        className={styles.gridRow}
        style={{
          gridTemplateColumns: `repeat(${maxMotorsLengths[rowIndex]}, 1fr)`,
        }}
      >
        {row.map((snackSchemeItem, cellIndex) => (
          <MachineStorageSnackSchemeItem key={cellIndex} snackSchemeItem={snackSchemeItem} />
        ))}
      </div>
    ));
  };

  return (
    <ContentCard className={styles.machineStorageSnackScheme}>
      <div className={styles.gridContainer}>{renderGrid()}</div>
    </ContentCard>
  );
};

export default MachineStorageSnackScheme;
