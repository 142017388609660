import { CreateSnackProductDTO } from '../../../../types/serverInterface/SnackProductBaseDTO';

/**
 * Дефолтное значение
 */
export const initialSnackProductValues: CreateSnackProductDTO = {
  brandId: null,
  name: '',
  barcode: '',
  vendorCode: '',
  calories: null,
  proteins: null,
  fats: null,
  carbohydrates: null,
  compound: '',
  allergens: '',
  description: '',
};
