import { FC, useEffect, useState } from 'react';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import Chips from '../../../../../components/Chips';
import { enumToArray } from '../../../../../types/enums';
import ModelProductCellForm from './ModelProductCellForm';
import ModelWaterCellForm from './ModelWaterCellForm';
import ModelCupCellForm from './ModelCupCellForm';
import ModelDisposableCellForm from './ModelDisposableCellForm';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { CreateModelCellsFormProps, CellGroupByCreateModelForm } from './types';
import { FieldError, FieldErrorStatus } from '../../../../../types/types';
import { useTranslation } from 'react-i18next';

/**
 * Список групп ячеек
 */
const cellGroupList = enumToArray(CellGroupByCreateModelForm);

/**
 * Мапа веса статуса
 */
const mapOfStatus: Record<'success' | 'alert' | 'warning', number> = {
  alert: 3,
  warning: 2,
  success: 1,
};

/**
 * Получения статуса группы ячеек
 *
 * @param cells ячейки
 */
const getGroupStatus: <
  TCells extends Record<number, TCell>,
  TCell extends Record<string, FieldError>,
>(
  cells: TCells,
) => FieldErrorStatus = (cells) => {
  let fieldStatus: FieldErrorStatus = undefined;

  for (const key in cells) {
    const cell = cells[key];
    for (const key in cell) {
      if (cell[key]?.status) {
        if (fieldStatus) {
          fieldStatus =
            mapOfStatus[cell[key].status || 'success'] > mapOfStatus[fieldStatus || 'success']
              ? cell[key].status
              : fieldStatus;
        } else {
          fieldStatus = cell[key].status;
        }
      }
    }
  }

  return fieldStatus;
};

/**
 * Шаг со структурой ячеек автомата для формы создания модели автомата
 */
const CreateModelCellsForm: FC<CreateModelCellsFormProps> = ({
  value,
  errors,
  onProductChange,
  onAddProductClick,
  onDeleteProductClick,
  onCopyProductClick,
  onWaterChange,
  onCupChange,
  onAddCupClick,
  onDeleteCupClick,
  onCopyCupClick,
  onDisposableChange,
  onAddDisposableClick,
  onDeleteDisposableClick,
  onCopyDisposableClick,
}) => {
  const { t } = useTranslation();

  const [selectedStep, setSelectedStep] = useState<CellGroupByCreateModelForm>(
    CellGroupByCreateModelForm.PRODUCT,
  );
  const [steps, setSteps] = useState<
    {
      status: FieldErrorStatus;
      label: CellGroupByCreateModelForm;
    }[]
  >(cellGroupList.map((item) => ({ label: item, status: undefined })));

  useEffect(() => {
    const getStatus = (key: CellGroupByCreateModelForm) => {
      switch (key) {
        case CellGroupByCreateModelForm.PRODUCT:
          return getGroupStatus(errors.cell);
        case CellGroupByCreateModelForm.WATER:
          return getGroupStatus(errors.waterCell);
        case CellGroupByCreateModelForm.CUP:
          return getGroupStatus(errors.cupCell);
        case CellGroupByCreateModelForm.DISPOSABLE:
          return getGroupStatus(errors.disposableCell);
      }
    };

    setSteps((prevState) =>
      prevState.map((item) => ({ label: item.label, status: getStatus(item.label) })),
    );
  }, [errors]);

  // Обработчики
  const handleChipsClick = (group: string) => {
    setSelectedStep(group as CellGroupByCreateModelForm);
  };

  const handleAddProductClick = onAddProductClick;

  const handleDeleteProductClick = onDeleteProductClick;

  const handleCopyProductClick = onCopyProductClick;

  // render методы
  const renderContent = () => {
    switch (selectedStep) {
      case CellGroupByCreateModelForm.PRODUCT:
        return (
          <ModelProductCellForm
            cellList={value.cells ?? []}
            errors={errors.cell}
            onChange={onProductChange}
            onAddClick={handleAddProductClick}
            onDeleteClick={handleDeleteProductClick}
            onCopyClick={handleCopyProductClick}
          />
        );
      case CellGroupByCreateModelForm.WATER:
        return (
          <ModelWaterCellForm
            cellList={value.cellWaters ?? []}
            errors={errors.waterCell}
            onChange={onWaterChange}
          />
        );
      case CellGroupByCreateModelForm.CUP:
        return (
          <ModelCupCellForm
            cellList={value.cellCups ?? []}
            errors={errors.cupCell}
            onChange={onCupChange}
            onAddClick={onAddCupClick}
            onDeleteClick={onDeleteCupClick}
            onCopyClick={onCopyCupClick}
          />
        );
      case CellGroupByCreateModelForm.DISPOSABLE:
        return (
          <ModelDisposableCellForm
            cellList={value.cellDisposables ?? []}
            errors={errors.disposableCell}
            onChange={onDisposableChange}
            onAddClick={onAddDisposableClick}
            onDeleteClick={onDeleteDisposableClick}
            onCopyClick={onCopyDisposableClick}
          />
        );
      default:
        return <Text>Error render cell form (CreateModelCellsForm)</Text>;
    }
  };

  return (
    <>
      <HorizontalContainer space="xs">
        {steps.map(({ label, status }) => (
          <Chips
            key={label}
            label={t(`machineControl.model.create.cells.chips.${label}`)}
            name={label}
            isSelect={selectedStep === label}
            status={status}
            onClick={handleChipsClick}
          />
        ))}
      </HorizontalContainer>
      {renderContent()}
    </>
  );
};

export default CreateModelCellsForm;
