import { AbstractApiModule } from '../../abstractApiModule';
import {
  CheckVendistaTokenDTO,
  EditMachineVendistaSettingDTO,
  EditVendistaDTO,
  MachineVendistaSettingDTO,
  VendistaTokenStatus,
} from '../../../../types/serverInterface/vendistaDTO';
import { getDataFromServer } from '../../../../helpers/getDataFromServer';

export class VendistaModule extends AbstractApiModule {
  /**
   * Изменение токена vendista
   *
   * @param data данные формы изменения токена
   * @param organizationId id организации
   */
  editVendistaToken(data: EditVendistaDTO, organizationId: number) {
    return getDataFromServer(data);
  }

  /**
   * Получение информации о состоянии токена vendista
   *
   * @param organizationId id организации
   */
  checkVendistaToken(organizationId: number): Promise<CheckVendistaTokenDTO> {
    return getDataFromServer({ statusToken: VendistaTokenStatus.NOT_WORKING, login: 'testLogin' });
  }

  /**
   * Получение номера терминала vendista автомата
   *
   * @param machineId id автомата
   */
  getMachineVendistaSetting(machineId: number): Promise<MachineVendistaSettingDTO> {
    return getDataFromServer({ termId: 80028 });
  }

  /**
   * Изменения номера терминала vendista автомата
   *
   * @param machineId id автомата
   * @param data данные формы изменения настроек vendista автомата
   */
  editMachineVendistaSetting(machineId: number, data: EditMachineVendistaSettingDTO) {
    return getDataFromServer(machineId);
  }

  /**
   * Проверка наличия интеграции vendista
   *
   * @param organizationId id организации
   */
  checkVendistaIntegration(organizationId: number) {
    return getDataFromServer(true);
  }
}
