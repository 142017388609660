import {
  EditMachinePrice,
  EditMachineStorageSnackProductCell,
  MachinePricesInfoRes,
} from '../../../../types/serverInterface/machineDTO';
import { PriceFieldError, PriceMapError, SnackCellPriceWithCellId } from './types';
import { initialFieldError } from '../../../../helpers/validateHelpers';

/**
 * Получение списка товаров и цен для снек автомата
 *
 * @param machinePricesInfo список цен продуктов в автомате
 */
export const getSnackProductsWithPrices = (
  machinePricesInfo: MachinePricesInfoRes | null,
): Record<number, SnackCellPriceWithCellId> => {
  const priceMap: Record<number, SnackCellPriceWithCellId> = {};

  machinePricesInfo?.prices.forEach((priceInfo) => {
    priceMap[priceInfo.ingredientId] = {
      cellId: priceInfo.prices[0].id,
      price: priceInfo.prices[0].price,
    };
  });

  return priceMap;
};

/**
 * Преобразование данных для post запроса
 *
 * @param fillingForm форма с наполнением снек автомата
 * @param pricesMap список товаров и цен для снек автомата
 */
export const createEditMachinePriceArray = (
  fillingForm: EditMachineStorageSnackProductCell[][],
  pricesMap: Record<number, SnackCellPriceWithCellId>,
): EditMachinePrice[] => {
  const editMachinePrices: EditMachinePrice[] = [];

  fillingForm.forEach((row) => {
    row.forEach((cell) => {
      const productId = cell.productId;

      if (productId !== null && pricesMap[productId]) {
        const priceInfo = pricesMap[productId];

        const editMachinePrice: EditMachinePrice = {
          id: priceInfo.cellId,
          ingredientId: productId,
          volume: cell.maxVolume,
          price: priceInfo.price,
        };

        editMachinePrices.push(editMachinePrice);
      }
    });
  });

  return editMachinePrices;
};

/**
 * Валидация формы изменения цены
 *
 * @param ProductCellPriceMap словарь по productId со значением cellId и priceId
 * @param fillingForm форма наполнения снек автомата
 * @param setPriceMapErrors метод перезаписи ошибок
 */
export const validatePriceMap = (
  ProductCellPriceMap: Record<number, SnackCellPriceWithCellId>,
  fillingForm: EditMachineStorageSnackProductCell[][],
  setPriceMapErrors: (error: PriceMapError) => void,
): boolean => {
  let isValid = true;
  const priceMapErrors: PriceMapError = {};

  fillingForm.flat().forEach((cell) => {
    const productId = cell.productId;

    if (productId) {
      const cell = ProductCellPriceMap[productId];
      let error: PriceFieldError = initialFieldError;

      if (cell && cell.price === 0) {
        error = {
          isError: true,
          label: 'machineControl.machine.snackFilling.table.content.warning.priceMoreThanZero',
          status: 'warning',
        };
        isValid = false;
      }

      if (cell && cell.price && cell.price < 0) {
        error = {
          isError: true,
          label: 'machineControl.machine.snackFilling.table.content.alert.negativePrice',
          status: 'alert',
        };
        isValid = false;
      }

      if (!cell || cell.price === null) {
        error = {
          isError: true,
          label: 'machineControl.machine.snackFilling.table.content.alert.noPrice',
          status: 'alert',
        };
        isValid = false;
      }

      priceMapErrors[productId] = error;
    }
  });

  setPriceMapErrors(priceMapErrors);
  return isValid;
};
