import React, { FC, useRef } from 'react';
import { MachineSnackRefillTableRowProps } from './types';
import styles from './MachineSnackRefillTableRow.module.scss';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { TextField } from '@consta/uikit/TextField';
import { getInputNumberValue } from '../../../../../../helpers/ inputHelpers';
import HorizontalContainer from '../../../../../../components/HorizontalContainer';
import { useTranslation } from 'react-i18next';
import DivWithTooltip from '../../../../../../components/withTooltip/DivWithTooltip';

/**
 * Одна ячейка в остатках снек автомата
 */
const MachineSnackRefillTableRow: FC<MachineSnackRefillTableRowProps> = ({
  info,
  onChangeVolume,
}) => {
  const { t } = useTranslation();

  const productTextRef = useRef<HTMLDivElement>(null);

  const brandName = info?.snackBrandName ? info.snackBrandName : null;
  const productName = info?.snackProductName ? info.snackProductName : null;
  const isTextTruncated =
    (productTextRef.current?.scrollWidth || 0) > (productTextRef.current?.clientWidth || 0);

  // Обработчики
  const handleVolumeChange = ({ value }: { value: string | null }) => {
    const numericValue = value === '' || value === null ? 0 : Number(value);
    const validatedValue = numericValue < 0 ? 0 : numericValue;
    const finalValue = info.maxVolume ? Math.min(validatedValue, info.maxVolume) : validatedValue;
    onChangeVolume(info.id)(finalValue);
  };

  const renderContent = () => (
    <>
      <div className={classNames(styles.cell, styles.cellNumberCell)}>
        <Text size="s" weight="regular">
          {info.id}
        </Text>
      </div>
      <Text className={classNames(styles.cell, styles.brandCell)} size="s" weight="regular">
        {brandName}
      </Text>
      <Text
        ref={productTextRef}
        className={classNames(styles.cell, styles.productCell, styles.text)}
        size="s"
        weight="regular"
      >
        {productName}
      </Text>
      <div className={classNames(styles.cell, styles.volumeCell)}>
        <HorizontalContainer space={0} align="center" justify="start">
          <TextField
            className={styles.textField}
            size="xs"
            value={getInputNumberValue(info.volume)}
            type="number"
            incrementButtons={false}
            onChange={handleVolumeChange}
          />
          <div className={styles.text}>
            <Text size="xs" view="secondary" weight="regular">
              /{info.maxVolume} {t('machineControl.machine.snackRefill.table.content.row.unit')}
            </Text>
          </div>
        </HorizontalContainer>
      </div>
    </>
  );

  return isTextTruncated ? (
    <DivWithTooltip
      className={styles.MachineSnackRefillTableRow}
      tooltipProps={{
        content: `${brandName} ${productName}`,
      }}
    >
      {renderContent()}
    </DivWithTooltip>
  ) : (
    <div className={styles.MachineSnackRefillTableRow}>{renderContent()}</div>
  );
};

export default MachineSnackRefillTableRow;
