import { FC } from 'react';
import styles from './MachineListStatusBar.module.scss';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { ConnectionStatus, StatusColor } from '../../../../types/serverInterface/machineDTO';
import { useTranslation } from 'react-i18next';
import { MachineListStatusBarProps } from './types';
import { SkeletonBrick } from '@consta/uikit/Skeleton';

const skeletonHeight = 64;
const skeletonLength = 5;

/**
 * Статусы автоматов на странице списка автоматов
 */
const MachineListStatusBar: FC<MachineListStatusBarProps> = ({
  isLoading,
  listCount,
  successCount,
  warningCount,
  alertCount,
  offlineCount,
  selectedStatus,
  selectedConnectionStatus,
  onSelectedStatus,
  onSelectConnectionStatus,
}) => {
  const { t } = useTranslation();

  // Обработчики
  const handleClickAll = () => {
    onSelectedStatus(null);
    onSelectConnectionStatus(null);
  };

  const handleClickSuccess = () => {
    onSelectedStatus(StatusColor.SUCCESS);
    onSelectConnectionStatus(null);
  };

  const handleClickWarning = () => {
    onSelectedStatus(StatusColor.WARNING);
    onSelectConnectionStatus(null);
  };

  const handleClickError = () => {
    onSelectedStatus(StatusColor.ERROR);
    onSelectConnectionStatus(null);
  };

  const handleClickOffline = () => {
    onSelectedStatus(null);
    onSelectConnectionStatus(ConnectionStatus.OFFLINE);
  };

  // render методы
  const renderStatusCard = (
    count: number,
    label: string,
    color: 'success' | 'warning' | 'alert' | 'secondary' | undefined,
    isSelected: boolean,
    onClick: () => void,
  ) => (
    <div className={classNames(styles.card, { [styles.selected]: isSelected })} onClick={onClick}>
      <Text className={styles.numberText} size="3xl" view={color} weight="semibold">
        {count}
      </Text>
      <Text className={styles.text} view={color} weight="semibold">
        {label}
      </Text>
    </div>
  );

  const renderStatusCards = () => (
    <>
      {renderStatusCard(
        listCount,
        t('machineControl.machine.list.statusBar.all'),
        undefined,
        selectedStatus === null,
        handleClickAll,
      )}
      {renderStatusCard(
        successCount,
        t('machineControl.machine.list.statusBar.success'),
        'success',
        selectedStatus === StatusColor.SUCCESS,
        handleClickSuccess,
      )}
      {renderStatusCard(
        warningCount,
        t('machineControl.machine.list.statusBar.warning'),
        'warning',
        selectedStatus === StatusColor.WARNING,
        handleClickWarning,
      )}
      {renderStatusCard(
        alertCount,
        t('machineControl.machine.list.statusBar.alert'),
        'alert',
        selectedStatus === StatusColor.ERROR,
        handleClickError,
      )}
      {/*{renderStatusCard(
       offlineCount,
        t('machineControl.machine.list.statusBar.offline'),
        'secondary',
        selectedConnectionStatus === ConnectionStatus.OFFLINE,
        handleClickOffline,
      )}*/}
    </>
  );

  const renderLoadingSkeletons = () =>
    Array.from({ length: skeletonLength }).map((_, index) => (
      <SkeletonBrick className={styles.loadingCard} key={index} height={skeletonHeight} />
    ));

  return (
    <div className={styles.MachineListStatusBar}>
      {!isLoading ? renderStatusCards() : renderLoadingSkeletons()}
    </div>
  );
};

export default MachineListStatusBar;
