import BaseTableSettingsBar from './BaseTableSettingsBar';
import BaseTableHeader from './BaseTableHeader';
import BaseTableData from './BaseTableData';

import { BaseTableProps } from './types';

import classNames from 'classnames';
import styles from './BaseTable.module.scss';

/**
 * Базовая таблица без стилей
 */
const BaseTable = <T,>({
  isLoading,
  withoutSettingsBar,
  withoutHeader,
  withoutData,

  rowClassName,
  tableDataClassName,
  baseTableSettingsBarClassName,
  baseTableSettingsBarActionsClassName,
  baseTableSettingsBarPaginationClassName,
  baseTableDataLoaderClassName,
  baseTableHeaderClassName,
  gridContainerClassName,

  rowHeight,

  rows,
  columns,

  renderActions,
  onRowClick,
}: BaseTableProps<T>) => {
  // render методы
  const renderBaseTableSettingsBar = () =>
    !withoutSettingsBar && (
      <BaseTableSettingsBar
        renderActions={renderActions}
        baseTableSettingsBarClassName={baseTableSettingsBarClassName}
        baseTableSettingsBarActionsClassName={baseTableSettingsBarActionsClassName}
        baseTableSettingsBarPaginationClassName={baseTableSettingsBarPaginationClassName}
      />
    );

  const renderBaseTableHeader = () =>
    !withoutHeader && (
      <BaseTableHeader
        columns={columns}
        gridContainerClassName={gridContainerClassName}
        baseTableHeaderClassName={baseTableHeaderClassName}
      />
    );

  const renderBaseTableData = () =>
    !withoutData && (
      <BaseTableData
        rowHeight={rowHeight}
        baseTableDataLoaderClassName={baseTableDataLoaderClassName}
        isLoading={isLoading}
        tableDataClassName={tableDataClassName}
        rowClassName={rowClassName}
        gridContainerClassName={gridContainerClassName}
        rows={rows}
        columns={columns}
        onRowClick={onRowClick}
      />
    );

  return (
    <div className={classNames(styles.BaseTable, isLoading && styles.isLoading)}>
      {renderBaseTableSettingsBar()}
      {renderBaseTableHeader()}
      {renderBaseTableData()}
    </div>
  );
};

export default BaseTable;
