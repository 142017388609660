import { FC, useEffect, useState } from 'react';
import {
  MachineStorageProductCellType,
  SeparatedMachineStorageType,
  StorageUnionType,
} from '../../../../../components/Machine/MachineStorage/types';
import { ProductGroup } from '../../../../../types/serverInterface/storageDTO';
import styles from './MachineFillingEdit.module.scss';
import MachineFillingEditListItem from './MachineFillingEditListItem';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { MachineStoragePageType } from '../MachineFilling';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import {
  MachineCellGroup,
  MachineCellGroupUnion,
  MachineStorageInfoProductCellFormatted,
} from '../../../../../types/serverInterface/machineDTO';
import { requiredFieldError } from '../../../../../helpers/validateHelpers';
import { FieldError } from '../../../../../types/types';
import { useAppDispatch } from '../../../../../app/hooks/store';
import { editNewMachineStorage } from '../../../../../state/machineControl/actions';
import { useTranslation } from 'react-i18next';

/**
 * Ошибка ячейки склада автоматов
 */
export type StorageFormCardError<T extends StorageUnionType> = Partial<Record<keyof T, FieldError>>;

/**
 * Мапа ошибок группы ячеек на складе автомата
 */
export type StorageFormGroupError<T extends StorageUnionType> = Record<
  number,
  StorageFormCardError<T>
>;

/**
 * Ошибки склада автомата
 */
export type StorageFormError = Record<
  MachineCellGroupUnion,
  StorageFormGroupError<MachineStorageInfoProductCellFormatted>
>;

/**
 * Дефолтное значение ошибки склада автомата
 */
const initialError: StorageFormError = {
  [ProductGroup.CONCENTRATE]: {},
  [ProductGroup.POWDER]: {},
  [ProductGroup.COFFEE]: {},
  [MachineCellGroup.WATER]: {},
  [MachineCellGroup.CUP]: {},
  [MachineCellGroup.DISPOSABLE]: {},
  [MachineCellGroup.SNACK]: {},
};

/**
 * Валидация группы продукты склада автомата
 *
 * @param storage группа склада автомата
 * @param setIsError функция изменения флага ошибки
 */
const storageProductFormValidate = (
  storage: MachineStorageProductCellType[],
  setIsError: (isError: boolean) => void,
): StorageFormCardError<MachineStorageInfoProductCellFormatted> => {
  const errors: StorageFormGroupError<MachineStorageInfoProductCellFormatted> = {};

  storage.forEach(({ info }, index) => {
    const { ingredientId, ingredientLineId, brandId } = info;

    if (!errors[index]) {
      errors[index] = {};
    }

    // TODO: тут поработать с вложенностью product и ошибками. Это тут работает плохо.
    //  Возможно стоит пересмотреть типизацию
    // if (!brandId) {
    //   setIsError(true);
    //   errors[index].brandId = requiredFieldError;
    // }
    if (!!brandId && !ingredientLineId) {
      setIsError(true);
      errors[index].ingredientLineId = requiredFieldError;
    }
    if (!!ingredientLineId && !ingredientId) {
      setIsError(true);
      errors[index].ingredientId = requiredFieldError;
    }
    // доп. валидация от основной формы временно убираю, т.к. нет отображения ошибки
    // if (!minVolume) {
    //   setIsError(true);
    //   errors[index].minVolume = requiredFieldError;
    // }
  });

  return errors;
};

/**
 * Валидация всего склада автомата
 *
 * @param storage склада автомата
 */
const storageValidate = (
  storage: SeparatedMachineStorageType,
): { errors: StorageFormError; isError: boolean } => {
  let isError = false;

  const setIsError = (newIsError: boolean) => {
    isError = isError || newIsError;
  };

  const errors = {
    [ProductGroup.POWDER]: storageProductFormValidate(
      storage.cells[ProductGroup.POWDER],
      setIsError,
    ),
    [ProductGroup.CONCENTRATE]: storageProductFormValidate(
      storage.cells[ProductGroup.CONCENTRATE],
      setIsError,
    ),
    [ProductGroup.COFFEE]: storageProductFormValidate(
      storage.cells[ProductGroup.COFFEE],
      setIsError,
    ),
    [MachineCellGroup.WATER]: {},
    [MachineCellGroup.CUP]: {},
    [MachineCellGroup.DISPOSABLE]: {},
    [MachineCellGroup.SNACK]: {},
  };

  return { errors, isError };
};

const getGroup = (
  group: MachineCellGroupUnion,
): keyof Omit<SeparatedMachineStorageType, 'cells'> => {
  switch (group) {
    case MachineCellGroup.WATER:
      return 'cellWaters';
    case MachineCellGroup.CUP:
      return 'cellCups';
    default:
      return 'cellDisposables';
  }
};

type MachineFillingEditProps = {
  /**
   * id автомата
   */
  machineId: number;
  /**
   * Склад автомата
   */
  storage: SeparatedMachineStorageType;
  /**
   * Сортированный список групп продуктов склада автомата
   */
  sortedGroup: ProductGroup[];
  onPageTypeChange: (newPageType: MachineStoragePageType) => void;
  /**
   * Обработчик перехода на следующий шаг
   */
  editStorageNextStep: () => void;
  onIsShowTabsChange: (isShow: boolean) => void;
};

const MachineFillingEdit: FC<MachineFillingEditProps> = ({
  machineId,
  storage,
  sortedGroup,
  onPageTypeChange,
  editStorageNextStep,
  onIsShowTabsChange,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [form, setForm] = useState<SeparatedMachineStorageType>(storage);
  const [storageError, setStorageError] = useState(initialError);

  useEffect(() => {
    setForm(storage);
  }, [storage]);

  // Обработчики
  const handleInputChange =
    (group: MachineCellGroupUnion) =>
    (index: number) =>
    (key: keyof MachineStorageInfoProductCellFormatted) =>
    (value: string | null) => {
      if (
        group === MachineCellGroup.CUP ||
        group === MachineCellGroup.WATER ||
        group === MachineCellGroup.DISPOSABLE
      ) {
        const formatGroup = getGroup(group);

        setForm((prevState) => ({
          ...prevState,
          [formatGroup]: [
            ...prevState[formatGroup].slice(0, index),
            {
              ...prevState[formatGroup][index],
              info: {
                ...prevState[formatGroup][index].info,
                [key]: value,
              },
            },
            ...prevState[formatGroup].slice(index + 1),
          ],
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }

      if (
        group === ProductGroup.COFFEE ||
        group == ProductGroup.CONCENTRATE ||
        group === ProductGroup.POWDER
      ) {
        setForm((prevState) => ({
          ...prevState,
          cells: {
            ...prevState.cells,
            [group]: [
              ...prevState.cells[group].slice(0, index),
              {
                ...prevState.cells[group][index],
                info: {
                  ...prevState.cells[group][index].info,
                  [key]: value,
                },
              },
              ...prevState.cells[group].slice(index + 1),
            ],
          },
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }
    };

  const handleSelectChange =
    (group: MachineCellGroupUnion) =>
    (index: number) =>
    (key: keyof MachineStorageInfoProductCellFormatted) =>
    (id: number | null) => {
      if (
        group === MachineCellGroup.CUP ||
        group === MachineCellGroup.WATER ||
        group === MachineCellGroup.DISPOSABLE
      ) {
        const formatGroup = getGroup(group);

        setForm((prevState) => ({
          ...prevState,
          [formatGroup]: [
            ...prevState[formatGroup].slice(0, index),
            {
              ...prevState[formatGroup][index],
              info: {
                ...prevState[formatGroup][index].info,
                [key]: id,
              },
            },
            ...prevState[formatGroup].slice(index + 1),
          ],
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }

      if (
        group === ProductGroup.COFFEE ||
        group == ProductGroup.CONCENTRATE ||
        group === ProductGroup.POWDER
      ) {
        setForm((prevState) => ({
          ...prevState,
          cells: {
            ...prevState.cells,
            [group]: [
              ...prevState.cells[group].slice(0, index),
              {
                ...prevState.cells[group][index],
                info: {
                  ...prevState.cells[group][index].info,
                  [key]: id,
                },
              },
              ...prevState.cells[group].slice(index + 1),
            ],
          },
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }
    };

  const handleBooleanChange =
    (group: MachineCellGroupUnion) =>
    (index: number) =>
    (key: keyof MachineStorageInfoProductCellFormatted) =>
    (checked: boolean) => {
      if (
        group === MachineCellGroup.CUP ||
        group === MachineCellGroup.WATER ||
        group === MachineCellGroup.DISPOSABLE
      ) {
        const formatGroup = getGroup(group);

        setForm((prevState) => ({
          ...prevState,
          [formatGroup]: [
            ...prevState[formatGroup].slice(0, index),
            {
              ...prevState[formatGroup][index],
              info: {
                ...prevState[formatGroup][index].info,
                [key]: checked,
              },
            },
            ...prevState[formatGroup].slice(index + 1),
          ],
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }

      if (
        group === ProductGroup.COFFEE ||
        group == ProductGroup.CONCENTRATE ||
        group === ProductGroup.POWDER
      ) {
        setForm((prevState) => ({
          ...prevState,
          cells: {
            ...prevState.cells,
            [group]: [
              ...prevState.cells[group].slice(0, index),
              {
                ...prevState.cells[group][index],
                info: {
                  ...prevState.cells[group][index].info,
                  [key]: checked,
                },
              },
              ...prevState.cells[group].slice(index + 1),
            ],
          },
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }
    };

  const handleNextEditClick = () => {
    const { errors, isError } = storageValidate(form);
    setStorageError(errors);
    onIsShowTabsChange(false);

    if (!isError) {
      dispatch(
        editNewMachineStorage({
          id: machineId,
          cells: [
            ...form.cells[ProductGroup.POWDER].map((item) => ({ ...item.info })),
            ...form.cells[ProductGroup.CONCENTRATE].map((item) => ({ ...item.info })),
            ...form.cells[ProductGroup.COFFEE].map((item) => ({ ...item.info })),
          ],
          cellWaters: form.cellWaters.map(({ info }) => ({
            ...info,
            volume: info.volume * 1000,
            maxVolume: info.maxVolume * 1000,
            minVolume: info.minVolume * 1000,
            filterVolume: info.filterVolume * 1000,
            filterMaxVolume: info.filterMaxVolume * 1000,
          })),
          cellCups: form.cellCups.map((item) => ({ ...item.info })),
          cellDisposables: form.cellDisposables.map((item) => ({ ...item.info })),
          snackCells: form.snackCells.map((item) => ({ ...item.info })),
        }),
      );

      editStorageNextStep();
    }
  };

  // render методы
  const renderHeader = () => (
    <HorizontalContainer className={styles.header} space={0}>
      <Text className={styles.numberCell} size="l" weight="semibold" view="secondary">
        {t('machineControl.machine.fillingEdit.table.header.numeroSign')}
      </Text>
      <Text className={styles.brandCell} size="l" weight="semibold" view="secondary">
        {t('machineControl.machine.fillingEdit.table.header.brand')}
      </Text>
      <Text className={styles.ingredientLineCell} size="l" weight="semibold" view="secondary">
        {t('machineControl.machine.fillingEdit.table.header.productLines')}
      </Text>
      <Text className={styles.ingredientCell} size="l" weight="semibold" view="secondary">
        {t('machineControl.machine.fillingEdit.table.header.taste')}
      </Text>
    </HorizontalContainer>
  );

  const renderActions = () => (
    <HorizontalContainer className={styles.actions}>
      <Button
        label={t('machineControl.machine.fillingEdit.next.button.label')}
        onClick={handleNextEditClick}
      />
    </HorizontalContainer>
  );

  return (
    <div className={styles.MachineFillingEdit}>
      {renderHeader()}
      {sortedGroup.map((group) =>
        form.cells[group].map((cell, index) => (
          <MachineFillingEditListItem
            key={cell.info.id}
            cell={cell}
            error={storageError[group][index]}
            onSelectChange={(key) =>
              ({ value }) =>
                handleSelectChange(group)(index)(key)(value && value.id)
              }
            onInputChange={(key) =>
              ({ value }) =>
                handleInputChange(group)(index)(key)(value)
              }
            onBooleanChange={(key) =>
              ({ checked }) =>
                handleBooleanChange(group)(index)(key)(checked)
              }
          />
        )),
      )}
      {renderActions()}
    </div>
  );
};

export default MachineFillingEdit;
