import ContentCard from '../../../../../components/ContentCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import styles from './MachineHomePageSales.module.scss';
import classNames from 'classnames';
import { IconShakerCup } from '../../../../../assets/icon/iconShakerCup';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { useAppSelector } from '../../../../../app/hooks/store';
import {
  selectMachineSaleInfoLastDate,
  selectMachineSaleInfoQty,
} from '../../../../../state/machineControl/selectors';
import { transformDate } from '../../../../../helpers/transformDate';
import { useTranslation } from 'react-i18next';

/**
 * Информация о продажах автомата
 */
const MachineHomePageSales = () => {
  const { t } = useTranslation();

  const { state: machineSaleInfoLastDate } = useAppSelector(selectMachineSaleInfoLastDate());
  const { state: machineSaleInfoQty } = useAppSelector(selectMachineSaleInfoQty());

  const { day, month, week } = { ...machineSaleInfoQty };
  const { date: dateLastSale } = { ...machineSaleInfoLastDate };

  const [date, time] = (transformDate(dateLastSale || '') || ' ').split(' ');

  return (
    <ContentCard className={styles.MachineHomePageSales}>
      <Text size="l" weight="semibold">
        {t('machineControl.machine.home.sales.title')}
      </Text>
      <HorizontalContainer align="center" space="s">
        <VerticalContainer className={styles.salesCard} space="2xs" align="center">
          <Text size="m" weight="medium" view="secondary">
            {t('machineControl.machine.home.sales.daySales.label')}
          </Text>
          <HorizontalContainer justify="center" align="center" space="2xs">
            <Text size="m" view="primary" weight="medium">
              {day}
            </Text>
            <IconShakerCup {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer className={styles.salesCard} space="2xs" align="center">
          <Text size="m" weight="medium" view="secondary">
            {t('machineControl.machine.home.sales.weekSales.label')}
          </Text>
          <HorizontalContainer justify="center" align="center" space="2xs">
            <Text size="m" view="primary" weight="medium">
              {week}
            </Text>
            <IconShakerCup {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer className={styles.salesCard} space="2xs" align="center">
          <Text size="m" weight="medium" view="secondary">
            {t('machineControl.machine.home.sales.monthSales.label')}
          </Text>
          <HorizontalContainer justify="center" align="center" space="2xs">
            <Text size="m" view="primary" weight="medium">
              {month}
            </Text>
            <IconShakerCup {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer
          className={classNames(styles.salesCard, styles.lastSaleDate)}
          space="2xs"
          align="center"
        >
          <Text size="m" weight="medium" view="secondary">
            {t('machineControl.machine.home.sales.lastSale.label')}
          </Text>
          <HorizontalContainer justify="center" align="center" space="2xs">
            <ContentCard className={styles.salesDateCard}>
              <Text size="m" view="primary" weight="medium">
                {time}
              </Text>
            </ContentCard>
            <ContentCard className={styles.salesDateCard}>
              <Text size="m" view="primary" weight="medium">
                {date}
              </Text>
            </ContentCard>
          </HorizontalContainer>
        </VerticalContainer>
      </HorizontalContainer>
    </ContentCard>
  );
};

export default MachineHomePageSales;
