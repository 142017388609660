import React, { FC } from 'react';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { MachineSnackRefillTableProps } from './types';
import ContentCard from '../../../../../components/ContentCard';
import styles from './MachineSnackRefillTable.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { MachineStorageInfoSnackCell } from '../../../../../types/serverInterface/machineDTO';
import { useTranslation } from 'react-i18next';
import MachineSnackRefillTableRow from './MachineSnackRefillTableRow';

/**
 * Таблица с остатками для снек автомата с одинаковым rowNumber
 */
const MachineSnackRefillTable: FC<MachineSnackRefillTableProps> = ({
  snackRefillTable,
  onChangeVolume,
}) => {
  const { t } = useTranslation();

  // render метод
  const renderSnackCells = (infoSnackCells: MachineStorageInfoSnackCell[], rowIndex: number) => {
    return (
      <>
        {infoSnackCells.map((info) => (
          <MachineSnackRefillTableRow key={info.id} info={info} onChangeVolume={onChangeVolume} />
        ))}
      </>
    );
  };

  return (
    <VerticalContainer space="m" className={styles.MachineSnackRefillTableWrapper}>
      {snackRefillTable.map((row, rowIndex) => (
        <ContentCard className={styles.ContentCard} key={rowIndex}>
          <div className={styles.ContentCardHeader}>
            <Text view="secondary" size="s" weight="semibold">
              {rowIndex + 1} {t('machineControl.machine.snackRefill.table.content.row.title')}
            </Text>
          </div>
          {renderSnackCells(row, rowIndex)}
        </ContentCard>
      ))}
    </VerticalContainer>
  );
};

export default MachineSnackRefillTable;
