import { FC } from 'react';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import styles from './MachineStorageListCell.module.scss';
import MachineStorageSnackMatrix from './MachineStorageSnackMatrix';
import MachineStorageDefaultScheme from './MachineStorageDefaultScheme';
import { MachineStorageListCellProps } from './types';

/**
 * Ячейка остатков в таблице списка автоматов
 */
const MachineStorageListCell: FC<MachineStorageListCellProps> = ({ machine }) => {
  const { cells, cellWaters, cellCups, snackCells } = machine;

  const isSnackMachine = snackCells?.length;

  return (
    <HorizontalContainer space="l" className={styles.MachineStorageCell}>
      {isSnackMachine ? (
        <MachineStorageSnackMatrix snackCells={snackCells} />
      ) : (
        <MachineStorageDefaultScheme cells={cells} cellWaters={cellWaters} cellCups={cellCups} />
      )}
    </HorizontalContainer>
  );
};

export default MachineStorageListCell;
