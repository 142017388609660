import { FC } from 'react';
import { Modal } from '@consta/uikit/Modal';
import classNames from 'classnames';
import styles from './DefaultFullScreenModal.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { DefaultFullScreenModalProps } from './types';
import { IconArrowLeft } from '../../assets/icon/iconArrowLeft';
import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';

/**
 * Модальное окно на весь размер экрана
 */
const DefaultFullScreenModal: FC<DefaultFullScreenModalProps> = ({
  className,
  classNameHeader,
  classNameActions,
  isOpen = true,
  modalTitle,
  position = 'top',
  onClose,
  renderActions,
  children,
  breadcrumbs,
}) => {
  // Обработчики
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Modal
      className={classNames(styles.defaultFullScreenModal, className)}
      position={position}
      isOpen={isOpen}
      onEsc={handleClose}
      onClickOutside={handleClose}
    >
      <div className={classNames(styles.header, classNameHeader)}>
        <Button
          onlyIcon
          view="ghost"
          form="round"
          size="l"
          iconLeft={IconArrowLeft as any}
          onClick={handleClose}
        />
        <div className={styles.breadcrumbsContainer}>
          {breadcrumbs && (
            <Breadcrumbs
              className={styles.breadcrumbs}
              size="s"
              items={breadcrumbs.map((breadcrumb) => ({
                ...breadcrumb,
                onClick: breadcrumb.onClick
                  ? (e: React.MouseEvent) => {
                      e.preventDefault();
                      if (breadcrumb.onClick) {
                        breadcrumb.onClick();
                      }
                    }
                  : undefined,
              }))}
            />
          )}
          <Text size="2xl" weight="semibold">
            {modalTitle}
          </Text>
        </div>
      </div>
      <div className={styles.content}>{children}</div>

      {renderActions && (
        <div className={classNames(styles.actions, classNameActions)}>{renderActions()}</div>
      )}
    </Modal>
  );
};

export default DefaultFullScreenModal;
