import { RootState } from '../../app/store';
import { EditOrganizationDTO } from '../../types/serverInterface/organizationDTO';

/**
 * Селектор получения списка организаций
 */
export const selectOrganizationsList = () => (state: RootState) => state.organization.list;

/**
 * Селектор получения организации
 */
export const selectOrganization = () => (state: RootState) => state.organization.organization;

/**
 * Селектор получения списка контактов организации
 */
export const selectOrganizationContacts = () => (state: RootState) =>
  state.organization.organizationContacts;

/**
 * Селектор получения организации для редактирования
 */
export const selectOrganizationForEdit = () => (state: RootState) => {
  const organization = state.organization.organization;

  let organizationForEdit: EditOrganizationDTO = {
    name: '',
    description: '',
  };

  if (organization) {
    const { name, description } = organization;

    organizationForEdit = {
      name,
      description,
    };
  }

  return organizationForEdit;
};

/**
 * Селектор получения id текущей организации
 */
export const selectOrganizationId = () => (state: RootState) =>
  state.organization.organization?.id || null;

/**
 * Селектор получения флага загрузки организации
 */
export const selectLoadingOrganization = () => (state: RootState) =>
  state.organization.loadingOrganization;

/**
 * Селектор получения ссылки для приглашения
 */
export const selectInviteLink = () => (state: RootState) => state.organization.inviteLink;

/**
 * Селектор получения сотрудников текущей организации
 */
export const selectEmployeesList = () => (state: RootState) => state.organization.employees;

/**
 * Селектор получения текущего сотрудника
 */
export const selectEmployeeWithKeyElement = () => (state: RootState) =>
  state.organization.employeeWithKeyElement;

/**
 * Селектор получения документов организации
 */
export const selectOrganizationDocuments = () => (state: RootState) =>
  state.organization.documentList;

/**
 * Селектор получения документов сотрудника
 */
export const selectEmployeeDocuments = () => (state: RootState) =>
  state.organization.employeeDocumentList;

/**
 * Селектор получения списка пользователей с ключом
 */
export const selectEmployeeWithKeysList = () => (state: RootState) =>
  state.organization.employeesWithKey;

/**
 * Селектор получения списка сотрудников без ключей
 */
export const selectEmployeesWithoutKey = () => (state: RootState) =>
  state.organization.employeesWithoutKey;

/**
 * Селектор получения ключа доступа к автомату
 */
export const selectMemberKey = () => (state: RootState) => state.organization.selectedMemberKey;
