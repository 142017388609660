import { errorHandler, NotificationType } from '../handlers';
import { createSlice, isRejected } from '@reduxjs/toolkit';
import {
  checkVendistaIntegrationThunk,
  checkVendistaTokenThunk,
  editMachineVendistaSettingThunk,
  editVendistaTokenThunk,
  getMachineVendistaSettingThunk,
} from './thunk';
import {
  CheckVendistaTokenDTO,
  MachineVendistaSettingDTO,
} from '../../types/serverInterface/vendistaDTO';

type StateItemType<T> = {
  state: T extends [] ? T : T | null;
  isLoading: boolean;
  isReject: boolean;
};

export type VendistaState = {
  vendista: StateItemType<CheckVendistaTokenDTO>;
  vendistaMachineSetting: StateItemType<MachineVendistaSettingDTO>;
  checkVendistaIntegration: StateItemType<boolean>;
  notifications: NotificationType[];
};

const initialState: VendistaState = {
  vendista: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  vendistaMachineSetting: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  checkVendistaIntegration: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  notifications: [],
};

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: VendistaState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const vendistaSlice = createSlice({
  name: 'vendista',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // checkVendistaTokenThunk
    builder.addCase(checkVendistaTokenThunk.pending, (state) => {
      state.vendista.isLoading = true;
      state.vendista.isReject = false;
    });

    builder.addCase(checkVendistaTokenThunk.rejected, (state) => {
      state.vendista.isLoading = false;
      state.vendista.isReject = true;
    });

    builder.addCase(checkVendistaTokenThunk.fulfilled, (state, action) => {
      state.vendista.isLoading = false;
      state.vendista.state = action.payload;
    });

    // getMachineVendistaSettingThunk
    builder.addCase(getMachineVendistaSettingThunk.pending, (state) => {
      state.vendistaMachineSetting.isLoading = true;
      state.vendistaMachineSetting.isReject = false;
    });

    builder.addCase(getMachineVendistaSettingThunk.rejected, (state) => {
      state.vendistaMachineSetting.isLoading = false;
      state.vendistaMachineSetting.isReject = true;
    });

    builder.addCase(getMachineVendistaSettingThunk.fulfilled, (state, action) => {
      state.vendistaMachineSetting.isLoading = false;
      state.vendistaMachineSetting.state = action.payload;
    });

    // checkVendistaIntegrationThunk
    builder.addCase(checkVendistaIntegrationThunk.pending, (state) => {
      state.checkVendistaIntegration.isLoading = true;
      state.checkVendistaIntegration.isReject = false;
      state.checkVendistaIntegration.state = null;
    });

    builder.addCase(checkVendistaIntegrationThunk.rejected, (state) => {
      state.checkVendistaIntegration.isLoading = false;
      state.checkVendistaIntegration.isReject = true;
    });

    builder.addCase(checkVendistaIntegrationThunk.fulfilled, (state, action) => {
      state.checkVendistaIntegration.isLoading = false;
      state.checkVendistaIntegration.state = action.payload;
    });

    builder.addMatcher(
      isRejected(
        editVendistaTokenThunk,
        checkVendistaTokenThunk,
        getMachineVendistaSettingThunk,
        editMachineVendistaSettingThunk,
        checkVendistaIntegrationThunk,
      ),
      (state, action) => {
        errorHandler(action)(addNotification(state));
      },
    );
  },
});

export const vendistaReducer = vendistaSlice.reducer;
