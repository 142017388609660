import { BaseTableSettingsBarPaginationProps } from './types';

import { FC } from 'react';

import classNames from 'classnames';
import styles from './BaseTableSettingsBarPagination.module.scss';

/**
 * Настройка страниц базовой таблицы
 */
const BaseTableSettingsBarPagination: FC<BaseTableSettingsBarPaginationProps> = ({
  baseTableSettingsBarPaginationClassName = '',
}) => {
  return (
    <div
      className={classNames(
        styles.BaseTableSettingsBarPagination,
        baseTableSettingsBarPaginationClassName,
      )}
    >
      BaseTableSettingsBarPagination
    </div>
  );
};

export default BaseTableSettingsBarPagination;
