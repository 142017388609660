import { FC } from 'react';
import { IconPageError } from '../../assets/icon/IconPageError';
import styles from './ErrorPage.module.scss';
import VerticalContainer from '../../components/VerticalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { defaultIconProps } from '../../consts/defaultIconProps';
import { useTranslation } from 'react-i18next';

/**
 * Страница с ошибками
 */
const ErrorPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.ErrorPage}>
      <VerticalContainer space="5xl" align="center">
        <IconPageError className={styles.icon} {...defaultIconProps} />
        <Text size="4xl">{t('errorPage.info.label')}</Text>
      </VerticalContainer>
      <Button label={t('errorPage.back.button.label')} />
    </div>
  );
};

export default ErrorPage;
