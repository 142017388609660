import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconRestartSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M1.5 1L2.67978 2.37641C3.13773 1.941 3.67724 1.59332 4.27222 1.35595C5.31536 0.939782 6.46824 0.886926 7.54508 1.2059C8.62193 1.52488 9.56002 2.19711 10.2082 3.11428C10.6104 3.68335 10.8864 4.32614 11.024 5H10V5.00386C9.5573 3.27668 7.99026 2 6.12502 2C5.03818 2 4.05258 2.43346 3.33166 3.13694L4.5 4.5H1L1.5 1Z" />
    <path d="M1.91734 8.88572C1.51516 8.31666 1.23912 7.67386 1.10156 7H2.12654C2.57058 8.72523 4.13669 10 6.00052 10C7.08737 10 8.07297 9.56654 8.79389 8.86306L7.62555 7.5H11.1255L10.6255 11L9.44577 9.62359C8.98782 10.059 8.44831 10.4067 7.85333 10.644C6.81019 11.0602 5.65731 11.1131 4.58047 10.7941C3.50362 10.4751 2.56552 9.80289 1.91734 8.88572Z" />
  </svg>
);

const IconRestartSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M3.85314 2.36057L2.5 0.5L1 6H6.5L5.02931 3.97781C5.8599 3.36333 6.88754 2.99999 8.00004 2.99999C10.419 2.99999 12.4368 4.71776 12.9 7H14.9283C14.7711 5.91148 14.3587 4.8686 13.7165 3.95999C12.8091 2.67595 11.4957 1.73483 9.98815 1.28826C8.48057 0.841697 6.86654 0.915695 5.40614 1.49833C4.85028 1.7201 4.32899 2.01064 3.85314 2.36057Z" />
    <path d="M2.2835 12.04C1.64135 11.1314 1.2289 10.0885 1.07178 9H3.10001C3.56327 11.2822 5.58103 13 7.99999 13C9.11249 13 10.1401 12.6367 10.9707 12.0222L9.50004 10H15L13.5 15.5L12.1469 13.6394C11.671 13.9894 11.1498 14.2799 10.5939 14.5017C9.13349 15.0843 7.51946 15.1583 6.01188 14.7117C4.5043 14.2652 3.19096 13.3241 2.2835 12.04Z" />
  </svg>
);

const IconRestartSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M2.75001 2L5.10957 4.75282C6.02548 3.882 7.1045 3.18665 8.29445 2.71191C10.3807 1.87956 12.6865 1.77385 14.8402 2.41181C16.9939 3.04976 18.8701 4.39422 20.1664 6.22855C21.3619 7.92005 22.0001 9.93725 22 12H20.0001C20.0001 7.58172 16.4183 4 12.0001 4C9.82638 4 7.85517 4.86692 6.41333 6.27387L8.75001 9H1.75001L2.75001 2Z" />
    <path d="M21 22L18.6405 19.2472C17.7245 20.118 16.6455 20.8134 15.4556 21.2881C13.3693 22.1204 11.0635 22.2261 8.90985 21.5882C6.75616 20.9502 4.87997 19.6058 3.58359 17.7714C2.38816 16.08 1.74996 14.0627 1.75 12H3.74996C3.74996 16.4183 7.33169 20 11.75 20C13.9237 20 15.8949 19.1331 17.3367 17.7261L15 15H22L21 22Z" />
  </svg>
);

export const IconRestart = createIcon({
  name: 'IconRestart',
  s: IconRestartSizeS,
  m: IconRestartSizeM,
  xs: IconRestartSizeXS,
});
