import { FC } from 'react';
import { CreateModelMatrixSchemeFormProps } from './types';
import { useTranslation } from 'react-i18next';
import { getInputNumberValue } from '../../../../../helpers/ inputHelpers';
import { CreateMatrixModel } from '../../../../../types/serverInterface/machineModelDTO';
import TextFieldWithTooltip from '../../../../../components/withTooltip/TextField';

/**
 * Шаг с созданием матрицы в форме создания модели автомата со снеком
 */
const CreateModelMatrixSchemeForm: FC<CreateModelMatrixSchemeFormProps> = ({
  value,
  error,
  onChange,
}) => {
  const { t } = useTranslation();

  const { maxCells, maxRows, maxVolume, minVolume } = value;

  // Обработчики
  const handleChange =
    (key: keyof CreateMatrixModel) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  return (
    <>
      <TextFieldWithTooltip
        tooltipProps={{
          content: !error?.maxCells?.isError
            ? t('machineControl.model.create.matrix.maxCells.tooltip.label')
            : t(error.maxCells?.label || ''),
        }}
        status={!error ? undefined : error.maxCells?.status}
        label={t('machineControl.model.create.matrix.maxCells.label')}
        type="number"
        width="full"
        incrementButtons={false}
        value={getInputNumberValue(maxCells)}
        onChange={handleChange('maxCells')}
      />
      <TextFieldWithTooltip
        tooltipProps={{
          content: !error?.maxRows?.isError
            ? t('machineControl.model.create.matrix.maxRows.tooltip.label')
            : t(error.maxRows?.label || ''),
        }}
        status={!error ? undefined : error.maxRows?.status}
        label={t('machineControl.model.create.matrix.maxRows.label')}
        type="number"
        width="full"
        incrementButtons={false}
        value={getInputNumberValue(maxRows)}
        onChange={handleChange('maxRows')}
      />
      <TextFieldWithTooltip
        tooltipProps={{
          content: !error?.maxVolume?.isError
            ? t('machineControl.model.create.matrix.maxVolume.tooltip.label')
            : t(error.maxVolume?.label || ''),
        }}
        status={!error ? undefined : error.maxVolume?.status}
        label={t('machineControl.model.create.matrix.maxVolume.label')}
        type="number"
        width="full"
        incrementButtons={false}
        value={getInputNumberValue(maxVolume)}
        onChange={handleChange('maxVolume')}
      />
      <TextFieldWithTooltip
        tooltipProps={{
          content: !error?.minVolume?.isError
            ? t('machineControl.model.create.matrix.minVolume.tooltip.label')
            : t(error.minVolume?.label || ''),
        }}
        status={!error ? undefined : error.minVolume?.status}
        label={t('machineControl.model.create.matrix.minVolume.label')}
        type="number"
        width="full"
        incrementButtons={false}
        value={getInputNumberValue(minVolume)}
        onChange={handleChange('minVolume')}
      />
    </>
  );
};

export default CreateModelMatrixSchemeForm;
