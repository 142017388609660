import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconStatsFilledSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.02263 1.125C6.94089 1.125 7.6607 1.12499 8.23176 1.18686C8.81453 1.25 9.28672 1.38116 9.68988 1.67408C9.93395 1.8514 10.1486 2.06605 10.3259 2.31012C10.6188 2.71328 10.75 3.18547 10.8131 3.76824C10.875 4.3393 10.875 5.05912 10.875 5.97736V6.02267C10.875 6.94092 10.875 7.6607 10.8131 8.23176C10.75 8.81453 10.6188 9.28672 10.3259 9.68988C10.1486 9.93395 9.93395 10.1486 9.68988 10.3259C9.28672 10.6188 8.81453 10.75 8.23176 10.8131C7.6607 10.875 6.94088 10.875 6.02264 10.875H5.97733C5.05908 10.875 4.3393 10.875 3.76824 10.8131C3.18547 10.75 2.71328 10.6188 2.31012 10.3259C2.06605 10.1486 1.8514 9.93395 1.67408 9.68988C1.38116 9.28672 1.25 8.81453 1.18686 8.23176C1.12499 7.6607 1.125 6.94089 1.125 6.02263V5.97738C1.125 5.05911 1.12499 4.3393 1.18686 3.76824C1.25 3.18547 1.38116 2.71328 1.67408 2.31012C1.85141 2.06605 2.06605 1.85141 2.31012 1.67408C2.71328 1.38116 3.18547 1.25 3.76824 1.18686C4.33931 1.12499 5.0591 1.125 5.97737 1.125H6.02263ZM6.375 3.5C6.375 3.29289 6.20711 3.125 6 3.125C5.79289 3.125 5.625 3.29289 5.625 3.5V8.5C5.625 8.70711 5.79289 8.875 6 8.875C6.20711 8.875 6.375 8.70711 6.375 8.5V3.5ZM8.375 5.5C8.375 5.29289 8.20711 5.125 8 5.125C7.79289 5.125 7.625 5.29289 7.625 5.5V8.5C7.625 8.70711 7.79289 8.875 8 8.875C8.20711 8.875 8.375 8.70711 8.375 8.5V5.5ZM4.375 6.5C4.375 6.29289 4.20711 6.125 4 6.125C3.79289 6.125 3.625 6.29289 3.625 6.5V8.5C3.625 8.70711 3.79289 8.875 4 8.875C4.20711 8.875 4.375 8.70711 4.375 8.5V6.5Z"
    />
  </svg>
);

const IconStatsFilledSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.03017 1.5C9.25453 1.49999 10.2143 1.49999 10.9757 1.58249C11.7527 1.66667 12.3823 1.84155 12.9198 2.2321C13.2453 2.46854 13.5315 2.75473 13.7679 3.08016C14.1585 3.61771 14.3333 4.24729 14.4175 5.02432C14.5 5.78573 14.5 6.74549 14.5 7.96982V8.03023C14.5 9.25456 14.5 10.2143 14.4175 10.9757C14.3333 11.7527 14.1585 12.3823 13.7679 12.9198C13.5315 13.2453 13.2453 13.5315 12.9198 13.7679C12.3823 14.1585 11.7527 14.3333 10.9757 14.4175C10.2143 14.5 9.25451 14.5 8.03018 14.5H7.96977C6.74544 14.5 5.78573 14.5 5.02432 14.4175C4.24729 14.3333 3.61771 14.1585 3.08016 13.7679C2.75473 13.5315 2.46854 13.2453 2.2321 12.9198C1.84155 12.3823 1.66667 11.7527 1.58249 10.9757C1.49999 10.2143 1.49999 9.25453 1.5 8.03017V7.96983C1.49999 6.74548 1.49999 5.78574 1.58249 5.02432C1.66667 4.24729 1.84155 3.61771 2.2321 3.08016C2.46854 2.75473 2.75473 2.46854 3.08016 2.2321C3.61771 1.84155 4.24729 1.66667 5.02432 1.58249C5.78574 1.49999 6.74547 1.49999 7.96983 1.5H8.03017ZM8.5 4.66667C8.5 4.39052 8.27614 4.16667 8 4.16667C7.72386 4.16667 7.5 4.39052 7.5 4.66667V11.3333C7.5 11.6095 7.72386 11.8333 8 11.8333C8.27614 11.8333 8.5 11.6095 8.5 11.3333V4.66667ZM11.1667 7.33333C11.1667 7.05719 10.9428 6.83333 10.6667 6.83333C10.3905 6.83333 10.1667 7.05719 10.1667 7.33333V11.3333C10.1667 11.6095 10.3905 11.8333 10.6667 11.8333C10.9428 11.8333 11.1667 11.6095 11.1667 11.3333V7.33333ZM5.83333 8.66667C5.83333 8.39052 5.60948 8.16667 5.33333 8.16667C5.05719 8.16667 4.83333 8.39052 4.83333 8.66667V11.3333C4.83333 11.6095 5.05719 11.8333 5.33333 11.8333C5.60948 11.8333 5.83333 11.6095 5.83333 11.3333V8.66667Z"
    />
  </svg>
);

const IconStatsFilledSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0453 2.25C13.8818 2.24999 15.3214 2.24999 16.4635 2.37373C17.6291 2.50001 18.5734 2.76232 19.3798 3.34815C19.8679 3.70281 20.2972 4.13209 20.6518 4.62024C21.2377 5.42656 21.5 6.37094 21.6263 7.53648C21.75 8.67859 21.75 10.1182 21.75 11.9547V12.0453C21.75 13.8818 21.75 15.3214 21.6263 16.4635C21.5 17.6291 21.2377 18.5734 20.6518 19.3798C20.2972 19.8679 19.8679 20.2972 19.3798 20.6518C18.5734 21.2377 17.6291 21.5 16.4635 21.6263C15.3214 21.75 13.8818 21.75 12.0453 21.75H11.9547C10.1182 21.75 8.67859 21.75 7.53648 21.6263C6.37094 21.5 5.42656 21.2377 4.62024 20.6518C4.13209 20.2972 3.70281 19.8679 3.34815 19.3798C2.76232 18.5734 2.50001 17.6291 2.37373 16.4635C2.24999 15.3214 2.24999 13.8818 2.25 12.0453V11.9548C2.24999 10.1182 2.24999 8.67861 2.37373 7.53648C2.50001 6.37094 2.76232 5.42656 3.34815 4.62024C3.70281 4.13209 4.13209 3.70281 4.62024 3.34815C5.42656 2.76232 6.37094 2.50001 7.53648 2.37373C8.67861 2.24999 10.1182 2.24999 11.9547 2.25H12.0453ZM12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V7ZM16.75 11C16.75 10.5858 16.4142 10.25 16 10.25C15.5858 10.25 15.25 10.5858 15.25 11V17C15.25 17.4142 15.5858 17.75 16 17.75C16.4142 17.75 16.75 17.4142 16.75 17V11ZM8.75 13C8.75 12.5858 8.41421 12.25 8 12.25C7.58579 12.25 7.25 12.5858 7.25 13V17C7.25 17.4142 7.58579 17.75 8 17.75C8.41421 17.75 8.75 17.4142 8.75 17V13Z"
    />
  </svg>
);

export const IconStatsFilled = createIcon({
  name: 'IconStatsFilled',
  xs: IconStatsFilledSizeXS,
  s: IconStatsFilledSizeS,
  m: IconStatsFilledSizeM,
});
