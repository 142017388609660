import styles from './TableRow.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useMemo } from 'react';
import classNames from 'classnames';
import { ClassicTableRowProps } from '../../ClassicTable/types';
import HorizontalContainer from '../../HorizontalContainer';

/**
 * Компонент строки таблицы вида "карточки"
 */
const TableRow = <T extends Record<string, any>, K extends keyof T>({
  row,
  rowIndex,
  columns,
  onRowClick = () => null,
}: ClassicTableRowProps<T, K>) => {
  const { rowData } = useMemo(() => {
    const rowData: {
      cell: string | JSX.Element;
      alignment: 'left' | 'right';
      isNoRender?: boolean;
      className?: string;
      width?: string;
    }[] = [];
    let count = 0;

    for (const key in columns) {
      if (columns.hasOwnProperty(key)) {
        if (!columns[key].multi) {
          rowData.push({
            cell: columns[key]?.renderCell?.(row, rowIndex) || (
              <Text size="m" className={styles.text}>
                {columns[key].getItemLabel?.(row)}
              </Text>
            ),
            alignment: columns[key].alignment || 'left',
            isNoRender: columns[key].isNoRender,
            className: columns[key].className,
            width: columns[key].width,
          });
          count++;
        }

        if (columns[key].multi) {
          const multiColumn = columns[key];

          const columnCount = multiColumn.count || 0;
          count = count + (columns[key]?.isNoRender ? 0 : columnCount);
          for (let i = 0; i < columnCount; i++) {
            const renderMultiCell = multiColumn?.renderMultiCell;
            const cellData = row[key] as any[];

            renderMultiCell &&
              rowData.push({
                cell: renderMultiCell(cellData[i], rowIndex, i),
                alignment: columns[key].alignment || 'left',
                isNoRender: columns[key].isNoRender,
                className: columns[key].className,
                width: columns[key].width,
              });
          }
        }
      }
    }

    return { rowData, count };
  }, [row, rowIndex, columns]);

  const handleRowClick = (data: T) => onRowClick(data);

  return (
    <tr className={styles.row} onClick={() => handleRowClick(row)}>
      {rowData
        .filter(({ isNoRender }) => !isNoRender)
        .map(({ cell, alignment, className }, index) => (
          <td key={index} className={classNames(styles.cell, className)}>
            <HorizontalContainer justify={alignment === 'right' ? 'end' : 'start'}>
              {cell}
            </HorizontalContainer>
          </td>
        ))}
    </tr>
  );
};

export default TableRow;
