import { FC } from 'react';
import { EmployeeDTO } from '../../../../../types/serverInterface/employeeDTO';
import DeleteModal from '../../../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';

type DeleteMemberProps = {
  /**
   * Флаг незавершённого процесса удаления
   */
  isDeleteLoading: boolean;
  /**
   * Сотрудник
   */
  member: EmployeeDTO | null;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик удаления
   */
  onDelete: () => void;
};

const DeleteMember: FC<DeleteMemberProps> = ({ member, onDelete, isDeleteLoading, onClose }) => {
  const { t } = useTranslation();

  if (!member) return null;

  const { surname, patronymic, name } = member;
  const userFullName = `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`;

  return (
    <DeleteModal
      primaryButtonLabel={t('organization.delete.member.fire.button.label')}
      isOpen={!!member}
      isDeleteLoading={isDeleteLoading}
      title={t('organization.delete.member.modal.title', { userFullName })}
      description={t('organization.delete.member.employeeDismissal.label', { userFullName })}
      withConfirmDeletion={false}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

export default DeleteMember;
