import { FC } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import ContentCard from '../ContentCard';
import { AddDocumentLink, EditDocumentLink } from '../../types/serverInterface/documentLink';
import styles from './DocumentsList.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента DocumentsList
 */
type DocumentsListProps = {
  documentsList: (AddDocumentLink | EditDocumentLink)[];
  onAddClick: () => void;
  onEditClick: (index: number) => void;
  onDeleteClick: (index: number) => void;
};

/**
 * Страница списка документов
 *
 * @param documentsList список документов
 * @param onDeleteClick обработчик нажатия на кнопку закрытия
 * @param onEditClick обработчик нажатия на кнопку редактирования
 * @param onAddClick обработчик нажатия на кнопку добавления
 */
const DocumentsList: FC<DocumentsListProps> = ({
  documentsList,
  onDeleteClick,
  onEditClick,
  onAddClick,
}) => {
  const { t } = useTranslation();

  // Обработчики
  const handleAddClick = () => onAddClick();

  const handleDeleteClick = (index: number) => () => onDeleteClick(index);

  const handleEditClick = (index: number) => () => onEditClick(index);

  if (!documentsList) return null;

  return (
    <div className={styles.documentsList}>
      <Button
        className={styles.addButton}
        label={t('components.documents.list.add.button.label')}
        view="ghost"
        onClick={handleAddClick}
      />
      {documentsList.map(({ name }, index) => (
        <div className={styles.documentCardWrapper} key={index}>
          <ContentCard className={styles.documentCard}>
            <div className={styles.content}>
              <Text>{name}</Text>
            </div>
            <div className={styles.action}>
              <Button
                onlyIcon
                iconLeft={IconEdit as any}
                view="clear"
                onClick={handleEditClick(index)}
              />
              <Button
                onlyIcon
                iconLeft={IconTrash as any}
                view="clear"
                onClick={handleDeleteClick(index)}
              />
            </div>
          </ContentCard>
        </div>
      ))}
    </div>
  );
};

export default DocumentsList;
