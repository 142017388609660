import * as Yup from 'yup';
import { MachineCellGroup, RefillMachineCell } from '../../../../types/serverInterface/machineDTO';

/**
 * Валидация строки в таблице наполнения снек автомата
 */
const refillMachineStorageScheme: Yup.ObjectSchema<RefillMachineCell> = Yup.object().shape({
  id: Yup.number().required(),
  volume: Yup.number().required('Это поле обязательное').nullable().min(0),
  group: Yup.mixed<MachineCellGroup>().oneOf([MachineCellGroup.SNACK]).required(),
});

/**
 * Валидация таблицы наполнения снек автомата
 */
export const refillMachineStorageArraySchema = Yup.array().of(refillMachineStorageScheme);
