import { FC } from 'react';
import {
  MachineModelCupCellByList,
  MachineModelDisposableCellByList,
  MachineModelProductCellByList,
  MachineModelWaterCellByList,
} from '../../../../../types/serverInterface/machineModelDTO';
import classNames from 'classnames';
import { getViewStorageItemClassName } from '../../../../../components/Machine/MachineStorage/helpers';
import styles from '../../../MachineList/MachineStorageListCell/MachineStorageDefaultScheme/MachineStorageDefaultCellItem/MachineStorageDefaultCellItem.module.scss';
import { MachineCellGroup } from '../../../../../types/serverInterface/machineDTO';

/**
 * Свйоства компонента CellsSchemeItem
 */
type CellsSchemeItemProps = {
  /**
   * Ячейка модели автомата
   */
  cell:
    | MachineModelProductCellByList
    | (MachineModelWaterCellByList & { group: MachineCellGroup; view?: undefined })
    | (MachineModelCupCellByList & { group: MachineCellGroup; view?: undefined })
    | (MachineModelDisposableCellByList & { group: MachineCellGroup; view?: undefined });
};

/**
 * Ячейка модели автомата для схемы ячеек
 */
const CellsSchemeItem: FC<CellsSchemeItemProps> = ({ cell }) => {
  return (
    <div
      className={classNames(
        styles.MachineStorageCellItem,
        styles.slider,
        getViewStorageItemClassName(cell.group, cell?.view),
      )}
    >
      <div className={classNames(styles.line)}>
        <div className={styles.point} />
        <div
          className={styles.lineSlider}
          style={{
            ['--slider-line-size' as string]: `${100}%`,
          }}
        />
      </div>
    </div>
  );
};

export default CellsSchemeItem;
