import { AppDispatch } from '../../app/store';
import {
  createBrandThunk,
  createComponentThunk,
  createCellCategoryThunk,
  createCellPurposeThunk,
  createProductLineThunk,
  createProductThunk,
  createRecipeThunk,
  createSportPitThunk,
  createTasteThunk,
  deleteBrandThunk,
  deleteComponentThunk,
  deleteCellCategoryThunk,
  deleteCellPurposeThunk,
  deleteProductLineThunk,
  deleteProductThunk,
  deleteRecipeThunk,
  deleteSportPitThunk,
  deleteTasteThunk,
  editBrandThunk,
  editComponentThunk,
  editCellCategoryThunk,
  editCellPurposeThunk,
  editLocaleThunk,
  editProductCalibrationThunk,
  editProductCharacteristicsThunk,
  editProductComponentThunk,
  editProductLineThunk,
  editProductThunk,
  editRecipeIngredientsThunk,
  editRecipeThunk,
  editSportPitThunk,
  editTasteThunk,
  getBrandListThunk,
  getBrandLocaleThunk,
  getComponentListThunk,
  getComponentLocaleByIdThunk,
  getCellCategoryByIdThunk,
  getCellCategoryListThunk,
  getCellCategoryLocaleByIdThunk,
  getCellPurposeByIdThunk,
  getCellPurposeListThunk,
  getCellPurposeLocaleByIdThunk,
  getProductByIdThunk,
  getProductLineListThunk,
  getProductLineLocaleThunk,
  getProductListThunk,
  getProductLocaleByIdThunk,
  getRecipeByIdThunk,
  getRecipeListThunk,
  getRecipeLocaleThunk,
  getSportPitByIdThunk,
  getSportPitListThunk,
  getSportPitLocaleByIdThunk,
  getTasteByIdThunk,
  getTasteListThunk,
  getTasteLocaleByIdThunk,
  getBrandListByCategoryViewThunk,
  getIngredientLineListByCategoryViewThunk,
  getIngredientListByCategoryViewThunk,
  getSnackBrandListThunk,
  createSnackBrandThunk,
  getSnackProductListThunk,
  createSnackProductThunk,
  getSnackProductThunk,
} from './thunk';
import { CreateBrandDTO, EditBrandDTO, ProductFilters } from '../../types/serverInterface/brandDTO';
import { LocaleDTO } from '../../types/serverInterface/localeDTO';
import {
  CreateProductLineDTO,
  EditProductLineDTO,
} from '../../types/serverInterface/productLineDTO';
import {
  CreateCellCategoryDTO,
  EditCellCategoryDTO,
} from '../../types/serverInterface/cellCategoryDTO';
import { CreateCellPurposeDTO, EditCellPurposeDTO } from '../../types/serverInterface/cellPurpose';
import { CreateTasteDTO, EditTasteDTO } from '../../types/serverInterface/tasteDTO';
import { CreateCellViewDTO, EditCellViewDTO } from '../../types/serverInterface/cellViewDTO';
import { CreateComponentDTO, EditComponentDTO } from '../../types/serverInterface/componentDTO';
import {
  CreateProductDTO,
  EditProductCalibrationDTO,
  EditProductCharacteristicsDTO,
  EditProductComponentDTO,
  EditProductDTO,
} from '../../types/serverInterface/productDTO';
import {
  CreateRecipeDTO,
  EditRecipeBasic,
  Ingredient,
} from '../../types/serverInterface/recipeDTO';
import {
  CreateSnackBrandDTO,
  CreateSnackProductDTO,
} from '../../types/serverInterface/SnackProductBaseDTO';

/**
 * Изменение локализации
 *
 * @param data данные формы изменения локализации
 */
export const editLocaleAction = (data: LocaleDTO) => (dispatch: AppDispatch) =>
  dispatch(editLocaleThunk(data));

/**
 * Получение списка брендов
 *
 * @param filters id организации
 */
export const getBrandListAction = (filters: ProductFilters) => (dispatch: AppDispatch) =>
  dispatch(getBrandListThunk(filters));

/**
 * Создание бренда
 *
 * @param data данные формы создания бренда
 */
export const createBrandAction = (data: CreateBrandDTO) => (dispatch: AppDispatch) =>
  dispatch(createBrandThunk(data))
    .unwrap()
    .then(() => {
      dispatch(getBrandListAction({ organizationId: data.organizationId || null }));
    });

/**
 * Изменение бренда
 *
 * @param data данные формы изменения бренда
 */
export const editBrandAction = (data: EditBrandDTO) => (dispatch: AppDispatch) =>
  dispatch(editBrandThunk(data))
    .unwrap()
    .then(() => {
      dispatch(getBrandListAction({ organizationId: data.organizationId || null }));
    });

/**
 * Удаление бренда
 *
 * @param brandId id бренда для удаления
 * @param organizationId id организации для запроса списка брендов
 */
export const deleteBrandAction =
  (brandId: number, organizationId: number | null) => (dispatch: AppDispatch) =>
    dispatch(deleteBrandThunk(brandId))
      .unwrap()
      .then(() => {
        dispatch(getBrandListAction({ organizationId: organizationId || null }));
      });

/**
 * Получение локализации бренда
 *
 * @param brandId id бренда
 */
export const getBrandLocaleAction = (brandId: number) => (dispatch: AppDispatch) =>
  dispatch(getBrandLocaleThunk(brandId));

/**
 * Получение списка линеек продуктов
 *
 * @param brandId id бренда
 * @param organizationId id организации
 */
export const getProductLineListAction =
  (brandId: number, filters: ProductFilters) => (dispatch: AppDispatch) =>
    dispatch(getProductLineListThunk({ brandId, filters }));

/**
 * Создание линейки продуктов
 *
 * @param data данные формы создания линейки продуктов
 */
export const createProductLineAction = (data: CreateProductLineDTO) => (dispatch: AppDispatch) =>
  dispatch(createProductLineThunk(data))
    .unwrap()
    .then(() =>
      dispatch(
        getProductLineListAction(data.brandId, { organizationId: data.organizationId || null }),
      ),
    );

/**
 * Изменение линейки продуктов
 *
 * @param data данные формы изменения линейки продуктов
 */
export const editProductLineAction = (data: EditProductLineDTO) => (dispatch: AppDispatch) =>
  dispatch(editProductLineThunk(data))
    .unwrap()
    .then(() =>
      dispatch(
        getProductLineListAction(data.brandId, { organizationId: data.organizationId || null }),
      ),
    );

/**
 * Удаление линейки продуктов
 *
 * @param productLineId id линейки продуктов
 * @param brandId id бренда
 * @param organizationId id организации
 */
export const deleteProductLineAction =
  (productLineId: number, brandId: number, organizationId: number | null) =>
  (dispatch: AppDispatch) =>
    dispatch(deleteProductLineThunk(productLineId))
      .unwrap()
      .then(() =>
        dispatch(getProductLineListAction(brandId, { organizationId: organizationId || null })),
      );

/**
 * Получение локализации линейки продуктов
 *
 * @param productLineId id линейки продуктов
 */
export const getProductLineLocaleAction = (productLineId: number) => (dispatch: AppDispatch) =>
  dispatch(getProductLineLocaleThunk(productLineId));

/**
 * Получение списка категорий контейнера
 */
export const getCellCategoryListAction = () => (dispatch: AppDispatch) =>
  dispatch(getCellCategoryListThunk()).unwrap();

/**
 * Получение категории контейнера
 *
 * @param cellCategoryId id категории контейнера
 */
export const getCellCategoryByIdAction = (cellCategoryId: number) => (dispatch: AppDispatch) =>
  dispatch(getCellCategoryByIdThunk(cellCategoryId)).unwrap();

/**
 * Создание категории контейнера
 *
 * @param data данные формы созданя категории контейнера
 */
export const createCellCategoryAction = (data: CreateCellCategoryDTO) => (dispatch: AppDispatch) =>
  dispatch(createCellCategoryThunk(data))
    .unwrap()
    .then(() => dispatch(getCellCategoryListAction()));

/**
 * Изменение категории контейнера
 *
 * @param data данные формы изменения категории контейнера
 * @param cellCategoryId id категории контейнера
 */
export const editCellCategoryAction =
  (data: EditCellCategoryDTO, cellCategoryId: number) => (dispatch: AppDispatch) =>
    dispatch(editCellCategoryThunk({ data, cellCategoryId: cellCategoryId }))
      .unwrap()
      .then(() => dispatch(getCellCategoryListAction()));

/**
 * Удаление категории контейнера
 *
 * @param cellCategoryId id категории контейнера
 */
export const deleteCellCategoryAction = (cellCategoryId: number) => (dispatch: AppDispatch) =>
  dispatch(deleteCellCategoryThunk(cellCategoryId))
    .unwrap()
    .then(() => dispatch(getCellCategoryListAction()));

/**
 * Получение локализации категории контейнера
 *
 * @param cellCategoryId id категории контейнера
 */
export const getCellCategoryLocaleByIdAction =
  (cellCategoryId: number) => (dispatch: AppDispatch) =>
    dispatch(getCellCategoryLocaleByIdThunk(cellCategoryId));

/**
 * Получение списка назначений контейнера
 */
export const getCellPurposeListAction = () => (dispatch: AppDispatch) =>
  dispatch(getCellPurposeListThunk()).unwrap();

/**
 * Получение назначения контейнера по id
 *
 * @param cellPurposeId id назначения контейнера
 */
export const getCellPurposeByIdAction = (cellPurposeId: number) => (dispatch: AppDispatch) =>
  dispatch(getCellPurposeByIdThunk(cellPurposeId)).unwrap();

/**
 * Создание назначения контейнера
 *
 * @param data данные формы создания назначения контейнера
 */
export const createCellPurposeAction = (data: CreateCellPurposeDTO) => (dispatch: AppDispatch) =>
  dispatch(createCellPurposeThunk(data))
    .unwrap()
    .then(() => dispatch(getCellPurposeListAction()));

/**
 * Изменение назначения контейнера
 *
 * @param data данные формы изменнения назначения контейнера
 * @param cellPurposeId id назначения контейнера
 */
export const editCellPurposeAction =
  (data: EditCellPurposeDTO, cellPurposeId: number) => (dispatch: AppDispatch) =>
    dispatch(editCellPurposeThunk({ data, cellPurposeId: cellPurposeId }))
      .unwrap()
      .then(() => dispatch(getCellPurposeListAction()));

/**
 * Удаление назначения контейнера
 *
 * @param cellPurposeId id назначения контейнера
 */
export const deleteCellPurposeAction = (cellPurposeId: number) => (dispatch: AppDispatch) =>
  dispatch(deleteCellPurposeThunk(cellPurposeId))
    .unwrap()
    .then(() => dispatch(getCellPurposeListAction()));

/**
 * Получение списка локализаций назначения контейнера
 *
 * @param cellPurposeId id назначения контейнера
 */
export const getCellPurposeLocaleByIdAction = (cellPurposeId: number) => (dispatch: AppDispatch) =>
  dispatch(getCellPurposeLocaleByIdThunk(cellPurposeId)).unwrap();

/**
 * Получение списка вкусов
 */
export const getTasteListAction = () => (dispatch: AppDispatch) => dispatch(getTasteListThunk());

/**
 * Получение вкуса по id
 *
 * @param tasteId id вкуса
 */
export const getTasteByIdAction = (tasteId: number) => (dispatch: AppDispatch) =>
  dispatch(getTasteByIdThunk(tasteId));

/**
 * Создание вкуса
 *
 * @param data данные формы создания вкуса
 */
export const createTasteAction = (data: CreateTasteDTO) => (dispatch: AppDispatch) =>
  dispatch(createTasteThunk(data))
    .unwrap()
    .then(() => dispatch(getTasteListAction()));

/**
 * Изменение вкуса
 *
 * @param data данные формы изменения вкуса
 * @param tasteId id вкуса
 */
export const editTasteAction = (data: EditTasteDTO, tasteId: number) => (dispatch: AppDispatch) =>
  dispatch(editTasteThunk({ data, tasteId }))
    .unwrap()
    .then(() => dispatch(getTasteListAction()));

/**
 * Удаление вкуса
 *
 * @param tasteId id вкуса
 */
export const deleteTasteAction = (tasteId: number) => (dispatch: AppDispatch) =>
  dispatch(deleteTasteThunk(tasteId))
    .unwrap()
    .then(() => dispatch(getTasteListAction()));

/**
 * Получение локализации по id вкуса
 *
 * @param tasteId id вкуса
 */
export const getTasteLocaleByIdAction = (tasteId: number) => (dispatch: AppDispatch) =>
  dispatch(getTasteLocaleByIdThunk(tasteId));

/**
 * Получение списка видов
 */
export const getCellViewListAction = () => (dispatch: AppDispatch) =>
  dispatch(getSportPitListThunk());

/**
 * Получение вида по id
 *
 * @param sportPitId id вида
 */
export const getCellViewByIdAction = (sportPitId: number) => (dispatch: AppDispatch) =>
  dispatch(getSportPitByIdThunk(sportPitId));

/**
 * Создание вида
 *
 * @param data данные формы создания вида
 */
export const createCellViewAction = (data: CreateCellViewDTO) => (dispatch: AppDispatch) =>
  dispatch(createSportPitThunk(data))
    .unwrap()
    .then(() => dispatch(getCellViewListAction()));

/**
 * Изменение вида спортивного питания
 *
 * @param data данные формы изменения вида спортивного питания
 * @param sportPitId id вида спортивного питания
 */
export const editCellViewAction =
  (data: EditCellViewDTO, sportPitId: number) => (dispatch: AppDispatch) =>
    dispatch(editSportPitThunk({ data, sportPitId }))
      .unwrap()
      .then(() => dispatch(getCellViewListAction()));

/**
 * Удаление вида спортивного питания
 *
 * @param sportPitId id вида спортивного питания
 */
export const deleteSportPitAction = (sportPitId: number) => (dispatch: AppDispatch) =>
  dispatch(deleteSportPitThunk(sportPitId))
    .unwrap()
    .then(() => dispatch(getCellViewListAction()));

/**
 * Получение локализации вида спортивного питания
 *
 * @param sportPitId id вида спортивного питания
 */
export const getSportPitLocaleByIdAction = (sportPitId: number) => (dispatch: AppDispatch) =>
  dispatch(getSportPitLocaleByIdThunk(sportPitId));

/**
 * Получение списка возможных компонентов состава
 */
export const getComponentListAction = () => (dispatch: AppDispatch) =>
  dispatch(getComponentListThunk());

/**
 * Получение локализации возможного компонента состава
 *
 * @param componentId id компонента состава
 */
export const getComponentLocaleByIdAction = (componentId: number) => (dispatch: AppDispatch) =>
  dispatch(getComponentLocaleByIdThunk(componentId));

/**
 * Создание возможного компонента состава
 *
 * @param data данные формы создания возможного компонента состава
 */
export const createComponentAction = (data: CreateComponentDTO) => (dispatch: AppDispatch) =>
  dispatch(createComponentThunk(data))
    .unwrap()
    .then(() => dispatch(getComponentListAction()));

/**
 * Изменения возможного компонента состава
 *
 * @param data даннные формы изменения возможного компонента состава
 * @param componentId id возможного компонента состава
 */
export const editComponentAction =
  (data: EditComponentDTO, componentId: number) => (dispatch: AppDispatch) =>
    dispatch(editComponentThunk({ data, componentId }))
      .unwrap()
      .then(() => dispatch(getComponentListAction()));

/**
 * Удаление возможного компонента состава
 *
 * @param componentId id возможного компонента состава
 */
export const deleteComponentAction = (componentId: number) => (dispatch: AppDispatch) =>
  dispatch(deleteComponentThunk(componentId))
    .unwrap()
    .then(() => dispatch(getComponentListAction()));

/**
 * Получение списка продуктов в линейке продуктов
 *
 * @param productLineId id линейки продуктов
 * @param organizationId id организации
 */
export const getProductListAction =
  (productLineId: number, filters: ProductFilters) => (dispatch: AppDispatch) =>
    dispatch(getProductListThunk({ productLineId, filters }));

/**
 * Получение детальной информации о продукте
 *
 * @param productId id продукта
 */
export const getProductByIdAction = (productId: number) => (dispatch: AppDispatch) =>
  dispatch(getProductByIdThunk(productId));

/**
 * Получение локализации продукта
 *
 * @param productId id продукта
 */
export const getProductLocaleByIdAction = (productId: number) => (dispatch: AppDispatch) =>
  dispatch(getProductLocaleByIdThunk(productId));

/**
 * Создание продукта
 *
 * @param data данные формы создания продукта
 * @param productLineId id линейки продукта
 * @param organizationId id организации
 */
export const createProductAction =
  (data: CreateProductDTO, productLineId: number, organizationId: number | null) =>
  (dispatch: AppDispatch) =>
    dispatch(createProductThunk(data))
      .unwrap()
      .then(() => dispatch(getProductListAction(productLineId, { organizationId })));

/**
 * Изменение продукта
 *
 * @param data данные формы изменения продукта
 * @param productId id продукта
 */
export const editProductAction =
  (data: EditProductDTO, productId: number) => (dispatch: AppDispatch) =>
    dispatch(editProductThunk({ data, productId }))
      .unwrap()
      .then(() => dispatch(getProductByIdAction(productId)));

/**
 * Удаление продукта
 *
 * @param productId id продукта
 * @param productLineId id линейки продукта
 * @param organizationId id организации
 */
export const deleteProductAction =
  (productId: number, productLineId: number, organizationId: number | null) =>
  (dispatch: AppDispatch) =>
    dispatch(deleteProductThunk(productId))
      .unwrap()
      .then(() => dispatch(getProductListAction(productLineId, { organizationId })));

/**
 * Изменения состава продукта
 *
 * @param data данные формы изменения состава продукта
 * @param productId id продукта
 */
export const editProductComponentAction =
  (data: EditProductComponentDTO, productId: number) => (dispatch: AppDispatch) =>
    dispatch(editProductComponentThunk({ data, productId }))
      .unwrap()
      .then(() => dispatch(getProductByIdAction(productId)));

/**
 * Изменение характеристик (тэгов) продукта
 *
 * @param data данные формы именения характеристик продукта
 * @param productId id продукта
 */
export const editProductCharacteristicsAction =
  (data: EditProductCharacteristicsDTO, productId: number) => (dispatch: AppDispatch) =>
    dispatch(editProductCharacteristicsThunk({ data, productId }))
      .unwrap()
      .then(() => dispatch(getProductByIdAction(productId)));

/**
 * Изменение калибровки родукта
 *
 * @param data данные формы изменения калибровки продукта
 * @param productId id продукта
 */
export const editProductCalibrationAction =
  (data: EditProductCalibrationDTO, productId: number) => (dispatch: AppDispatch) =>
    dispatch(editProductCalibrationThunk({ data, productId }))
      .unwrap()
      .then(() => dispatch(getProductByIdAction(productId)));

/**
 * Получение списка рецептов
 *
 * @param organizationId id организации
 */
export const getRecipeListAction = (organizationId: number | null) => (dispatch: AppDispatch) =>
  dispatch(getRecipeListThunk(organizationId));

/**
 * Получение детальной информации о рецепте
 *
 * @param recipeId id рецепта
 */
export const getRecipeByIdAction = (recipeId: number) => (dispatch: AppDispatch) =>
  dispatch(getRecipeByIdThunk(recipeId));

/**
 * Получение локализации рецепта
 *
 * @param recipeId id рецепта
 */
export const getRecipeLocaleAction = (recipeId: number) => (dispatch: AppDispatch) =>
  dispatch(getRecipeLocaleThunk(recipeId));

/**
 * Создание рецепта
 *
 * @param data данные формы создания рецепта
 * @param organizationId id организации
 */
export const createRecipeAction =
  (data: CreateRecipeDTO, organizationId: number | null) => (dispatch: AppDispatch) =>
    dispatch(createRecipeThunk(data))
      .unwrap()
      .then(() => dispatch(getRecipeListAction(organizationId)));

/**
 * Изменение рецепта
 *
 * @param data данные формы изменения рецепта
 * @param recipeId id рецепта
 */
export const editRecipeAction =
  (data: EditRecipeBasic, recipeId: number) => (dispatch: AppDispatch) =>
    dispatch(editRecipeThunk({ data, recipeId }))
      .unwrap()
      .then(() => dispatch(getRecipeByIdAction(recipeId)));

/**
 * Изменение ингредиентов рецепта
 *
 * @param data данные формы изменения ингредиентов рецепта
 * @param recipeId id рецепта
 */
export const editRecipeIngredientsAction =
  (data: Ingredient[], recipeId: number) => (dispatch: AppDispatch) =>
    dispatch(editRecipeIngredientsThunk({ data, recipeId })).unwrap();

/**
 * Удаление рецепта
 *
 * @param recipeId id рецепта
 * @param organizationId id организации
 */
export const deleteRecipeAction =
  (recipeId: number, organizationId: number | null) => (dispatch: AppDispatch) =>
    dispatch(deleteRecipeThunk(recipeId))
      .unwrap()
      .then(() => dispatch(getRecipeListAction(organizationId)));

/**
 * Получение списка брендов с фильтром по категориям и видом
 *
 * @param categoryListId список категорий для фильтра
 * @param viewsListId список видов для списка
 */
export const getBrandListByCategoryViewAction =
  (categoryListId: number[] | null, viewsListId: number[] | null) => (dispatch: AppDispatch) =>
    dispatch(getBrandListByCategoryViewThunk({ categoryListId, viewsListId })).unwrap();

/**
 * Получение списка линеек продуктов с фильтром по категориям, видам и бренду
 *
 * @param categoryListId список категорий для фильтра
 * @param viewsListId список видов для списка
 * @param brandId id бренда
 */
export const getIngredientLineListByCategoryViewAction =
  (categoryListId: number[] | null, viewsListId: number[] | null, brandId: number) =>
  (dispatch: AppDispatch) =>
    dispatch(
      getIngredientLineListByCategoryViewThunk({ categoryListId, viewsListId, brandId }),
    ).unwrap();

/**
 * Получение списка продуктов с фильтром по категориям, видам и линейкам
 *
 * @param categoryListId
 * @param viewsListId
 * @param ingredientLineIds
 */
export const getIngredientListByCategoryViewAction =
  (categoryListId: number[] | null, viewsListId: number[] | null, ingredientLineIds: number[]) =>
  (dispatch: AppDispatch) =>
    dispatch(
      getIngredientListByCategoryViewThunk({
        categoryListId,
        viewsListId,
        ingredientLineIds,
      }),
    ).unwrap();

/**
 * Получения списка брендов базы снеков
 *
 * @param organizationId id организации
 */
export const getSnackBrandListAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(getSnackBrandListThunk(organizationId));

/**
 * Создание бренда в базе снеков
 *
 * @param data данные формы создания бренда
 * @param organizationId id организации
 */
export const createSnackBrandAction =
  (data: CreateSnackBrandDTO, organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(createSnackBrandThunk({ data, organizationId }));

/**
 * Получения списка продуктов по id бренда базы снеков
 *
 * @param brandId id бренда
 */
export const getSnackProductListAction = (brandId: number) => (dispatch: AppDispatch) =>
  dispatch(getSnackProductListThunk(brandId));

/**
 * Создание продукта в базе снеков
 *
 * @param data данные формы создания
 * @param brandId id бренда
 * @param organizationId id организации
 */
export const createSnackProductAction =
  (data: CreateSnackProductDTO, brandId: number, organizationId: number) =>
  (dispatch: AppDispatch) =>
    dispatch(createSnackProductThunk(data)).then(() => {
      dispatch(getSnackBrandListAction(organizationId));
      dispatch(getSnackProductListAction(brandId));
    });

/**
 * Получение детальной информации о продукте базы снеков
 *
 * @param productId id продукта
 */
export const getSnackProductAction = (productId: number) => (dispatch: AppDispatch) =>
  dispatch(getSnackProductThunk(productId));
