import BaseTableSettingsBarPagination from './BaseTableSettingsBarPagination';
import BaseTableSettingsBarActions from './BaseTableSettingsBarActions';

import { BaseTableSettingsBarProps } from './types';

import { FC } from 'react';

import classNames from 'classnames';
import styles from './BaseTableSettingsBar.module.scss';

/**
 * Дополнительные настройки таблицы (пагинация, экспорт импорт данных)
 */
const BaseTableSettingsBar: FC<BaseTableSettingsBarProps> = ({
  baseTableSettingsBarClassName = '',
  baseTableSettingsBarActionsClassName,
  baseTableSettingsBarPaginationClassName,

  renderActions,
}) => {
  return (
    <div className={classNames(styles.BaseTableSettingsBar, baseTableSettingsBarClassName)}>
      <BaseTableSettingsBarActions
        renderActions={renderActions}
        baseTableSettingsBarActionsClassName={baseTableSettingsBarActionsClassName}
      />
      <BaseTableSettingsBarPagination
        baseTableSettingsBarPaginationClassName={baseTableSettingsBarPaginationClassName}
      />
    </div>
  );
};

export default BaseTableSettingsBar;
