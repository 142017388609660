import React, { FC, ReactNode, useMemo } from 'react';
import HorizontalContainer from '../../HorizontalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './TabBadge.module.scss';
import classNames from 'classnames';

/**
 * Описание таба
 */
export type TabProps = {
  /**
   * Заголовок таба
   */
  label?: string;
  /**
   * Дополнительное значение таба
   */
  value?: string | number;
  /**
   * Рендер метод иконки
   */
  renderIcon?: () => ReactNode;
  /**
   * Обработчик нажатия
   */
  onClick?: () => void;
  /**
   * Флаг выбранного таба
   */
  isSelect?: boolean;
  /**
   * Флаг, указывающий, нужно ли скрывать таб
   */
  isHidden?: boolean;
};

/**
 * Свойства компонента TabBadge
 */
type TabBadgeProps = TabProps & {
  /**
   * Размер таба
   */
  size: 'm' | 's' | 'xs';
  /**
   * Выключенный таб
   */
  disabled: boolean;
};

/**
 * Компонент таба
 */
const TabBadge: FC<TabBadgeProps> = ({
  size,
  isSelect,
  disabled,
  label,
  value,
  renderIcon,
  onClick,
}) => {
  const sizeClassName = useMemo(() => {
    switch (size) {
      case 'm':
        return styles.Size_m;
      case 's':
        return styles.Size_s;
      case 'xs':
        return styles.Size_xs;
      default:
        return null;
    }
  }, [size]);

  const tabView = useMemo(() => {
    if (disabled) return styles.disabled;
    if (isSelect) return styles.isSelect;
    return styles.default;
  }, [disabled, isSelect]);

  return (
    <HorizontalContainer
      className={classNames(styles.TabBadge, sizeClassName, tabView)}
      space={size === 'm' ? 'xs' : '2xs'}
      align="center"
      onClick={onClick}
    >
      {renderIcon && renderIcon()}
      <Text className={styles.text} weight="medium" size={size}>
        {label}
      </Text>
      {value && (
        <Text className={styles.valueText} weight="medium" size={size}>
          {value}
        </Text>
      )}
    </HorizontalContainer>
  );
};

export default TabBadge;
