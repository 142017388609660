import { FC, useRef, useState } from 'react';
import MenuItem from '../MenuItem';
import { IconSetting } from '../../../../assets/icon/iconSetting';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import ContextModal from '../../../ContextModal';
import VerticalContainer from '../../../VerticalContainer';
import { IconCheck } from '@consta/uikit/IconCheck';
import styles from './LocaleMenuItem.module.scss';
import { defaultIconProps } from '../../../../consts/defaultIconProps';
import { LocalMenuItemProps } from './types';
import { LanguageName } from '../../TopMenu';
import { useTranslation } from 'react-i18next';

/**
 * Элемент контекстного меню локализация
 */
const LocaleMenuItem: FC<LocalMenuItemProps> = ({ language, onLanguageChange }) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLButtonElement>(null);
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);

  // Обработчики
  const handleOpenClick = () => {
    setIsOpenContextMenu(true);
  };

  const handleClose = (event: MouseEvent) => {
    event.stopPropagation ? event.stopPropagation() : (event.cancelBubble = true);
    setIsOpenContextMenu(false);
  };

  // render методы
  const renderContextMenu = () => (
    <ContextModal
      className={styles.LocaleMenu}
      currentRef={ref}
      isOpen={isOpenContextMenu}
      align={'topLeft'}
      onClickOutside={handleClose}
    >
      <VerticalContainer space={0} isAutoWidth>
        <MenuItem
          isSmall
          label="English"
          iconLeft={
            <div className={styles.iconContainer}>
              {language === LanguageName.en && <IconCheck size="s" {...defaultIconProps} />}
            </div>
          }
          onClick={() => onLanguageChange({ value: LanguageName.en })}
        />
        <MenuItem
          isSmall
          label="Русский"
          iconLeft={
            <div className={styles.iconContainer}>
              {language === LanguageName.ru && <IconCheck size="s" {...defaultIconProps} />}
            </div>
          }
          onClick={() => onLanguageChange({ value: LanguageName.ru })}
        />

        {/*<MenuItem*/}
        {/*  isSmall*/}
        {/*  label="Как в системе"*/}
        {/*  iconLeft={*/}
        {/*    <div className={styles.iconContainer}>*/}
        {/*      {theme === ThemeName.gpnDefault && <IconCheck size="s" placeholder="" />}*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*/>*/}
      </VerticalContainer>
    </ContextModal>
  );

  return (
    <>
      <MenuItem
        innerRef={ref}
        label={t('components.userSettings.menuItem.language')}
        iconLeft={<IconSetting {...defaultIconProps} />}
        iconRight={<IconArrowRight {...defaultIconProps} />}
        onClick={handleOpenClick}
      />
      {renderContextMenu()}
    </>
  );
};

export default LocaleMenuItem;
