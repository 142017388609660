import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { TextField } from '@consta/uikit/TextField';
import { Combobox } from '@consta/uikit/Combobox';
import styles from './MachineSnackFillingTableRow.module.scss';
import { MachineSnackFillingTableRowProps } from './types';
import { getInputNumberValue } from '../../../../../../../helpers/ inputHelpers';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks/store';
import { selectSnackProductListByBrandId } from '../../../../../../../state/productBase/selectors';
import { getSnackProductListAction } from '../../../../../../../state/productBase/actions';
import { useTranslation } from 'react-i18next';

/**
 * Ряд в таблице с наполнением снек автомата
 */
const MachineSnackFillingTableRow: FC<MachineSnackFillingTableRowProps> = ({
  info,
  cellIndexInRow,
  snackBrandList,
  priceWithId,
  onBrandChange,
  onProductChange,
  onPriceChange,
  cellError,
  priceError,
}) => {
  const { t } = useTranslation();

  const brandId = info?.brandId;
  const productId = info?.productId;
  const dispatch = useAppDispatch();

  const snackProductList = useAppSelector(selectSnackProductListByBrandId(brandId));

  useEffect(() => {
    if (brandId) {
      dispatch(getSnackProductListAction(brandId));
    }
  }, [dispatch, brandId]);

  const handleBrandChange = ({
    value,
  }: {
    value: { id: number } | null;
    e: React.SyntheticEvent;
  }) => {
    onBrandChange(value?.id || null);
  };

  const handleProductChange = ({
    value,
  }: {
    value: { id: number } | null;
    e: React.SyntheticEvent;
  }) => {
    onProductChange(value?.id || null);
  };

  const handlePriceChange = ({ value }: { value: string | null }) => {
    const formattedValue = Number(value);
    productId && onPriceChange(productId)(value ? (formattedValue < 0 ? 0 : formattedValue) : null);
  };

  return (
    <tr className={classNames(styles.MachineSnackFillingTableRow)}>
      <th className={classNames(styles.cell, styles.cellNumberCell)}>
        <Text size="s" weight="regular">
          {cellIndexInRow + 1}
        </Text>
      </th>
      <th className={classNames(styles.cell, styles.brandCell)}>
        <Combobox
          items={snackBrandList.brandList}
          value={snackBrandList.brandList.find(({ id }) => id === brandId)}
          getItemLabel={(item) => item.name}
          status={cellError.brandId ? 'alert' : undefined}
          onChange={handleBrandChange}
        />
      </th>
      <th className={classNames(styles.cell, styles.productCell)}>
        <Combobox
          disabled={!brandId}
          items={snackProductList?.productList || []}
          value={(snackProductList?.productList || []).find(({ id }) => id === productId)}
          getItemLabel={(item) => item.name}
          status={cellError.productId ? 'alert' : undefined}
          onChange={handleProductChange}
        />
      </th>
      <th className={classNames(styles.cell, styles.priceCell)}>
        <TextField
          width="full"
          disabled={!brandId || !productId}
          size="m"
          rightSide={t('machineControl.machine.snackFilling.table.content.price.input.label')}
          value={getInputNumberValue(priceWithId?.price)}
          onChange={handlePriceChange}
          status={priceError?.status}
        />
      </th>
      {/*<th className={classNames(styles.cell, styles.buttonCell)}>*/}
      {/*  <ContentCard view="clear" size="m" onlyIcon iconLeft={IconSetting as any} />*/}
      {/*</th>*/}
    </tr>
  );
};

export default MachineSnackFillingTableRow;
