import { AbstractApiModule } from '../../abstractApiModule';
import {
  CreateTelegramNotificationSettingDTO,
  EditTelegramNotificationSettingDTO,
  TelegramNotificationSettingDTO,
} from '../../../../types/serverInterface/notificationDTO';
import { notificationBaseUrl } from '../../../../consts';

export class NotificationModule extends AbstractApiModule {
  /**
   * Получение списка настроек телеграм уведомлений по id организации
   *
   * @param organizationId id организации
   */
  getTelegramNotificationSettingsByOrganizationId(organizationId: number) {
    // return getDataFromServer([
    //   {
    //     id: 1,
    //     botToken: 'botToken',
    //     chatId: 'chatId',
    //   },
    //   {
    //     id: 2,
    //     botToken: 'botToken2',
    //     chatId: 'chatId2',
    //   },
    // ] as TelegramNotificationSettingDTO[]);
    return this.request.get<number, TelegramNotificationSettingDTO[]>(
      `${notificationBaseUrl}/notification-telegram-settings/organization/${organizationId}`,
    );
  }

  /**
   * Создание настройки телеграм уведомления
   *
   * @param data данные формы
   */
  createTelegramNotificationSetting(data: CreateTelegramNotificationSettingDTO) {
    return this.request.post(`${notificationBaseUrl}/notification-telegram-settings/create`, data);
  }

  /**
   * Изменения настройки телеграм уведомления
   *
   * @param data данные формы
   * @param settingId id настройки
   */
  editTelegramNotificationSetting(data: EditTelegramNotificationSettingDTO, settingId: number) {
    return this.request.patch(
      `${notificationBaseUrl}/notification-telegram-settings/${settingId}`,
      data,
    );
  }

  /**
   *
   * Удаление телеграм уведомления
   *
   * @param settingId id телеграм уведомления
   */
  deleteTelegramNotificationSetting(settingId: number) {
    return this.request.delete(
      `${notificationBaseUrl}/notification-telegram-settings/${settingId}`,
    );
  }
}
