import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { createMemberValidationScheme } from './createMemberValidationScheme';
import {
  CreateEmployeeRoles,
  CreateEmployeeRolesWithOwner,
  CreateInviteEmployeeDTO,
} from '../../../../../types/serverInterface/invitationDTO';
import { createInviteEmployeeAction } from '../../../../../state/organization/actions';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import DefaultModal from '../../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { TextField } from '@consta/uikit/TextField';
import { Combobox } from '@consta/uikit/Combobox';
import { enumToArray } from '../../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { useRoleChecked } from '../../../../../helpers/roleChecked';
import { Roles } from '../../../../../types/serverInterface/cabinetDTO';
import { selectInviteLink } from '../../../../../state/organization/selectors';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconCopy } from '@consta/uikit/IconCopy';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import styles from './CreateMember.module.scss';
import { getOrganizationId } from '../../../../../helpers/getOrganizationId';

const initialFormValues = {
  name: '',
  surname: '',
  patronymic: '',
  position: CreateEmployeeRoles.TECHNICIAN,
  email: '',
  phone: '',
};

/**
 * Свойства компонента CreateMember
 */
type CreateMemberProps = {
  /**
   * Флаг открытия
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Форма регистрации пользователя
 */
const CreateMember: FC<CreateMemberProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const organizationId = getOrganizationId();
  const inviteLink = useAppSelector(selectInviteLink());

  const rolesList = useRoleChecked([Roles.DEVELOPER, Roles.MANUFACTURER])
    ? (enumToArray(CreateEmployeeRolesWithOwner) as string[])
    : (enumToArray(CreateEmployeeRoles) as string[]);

  const [isLoading, setIsLoading] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    isCreated && !inviteLink && !isCreateNew && onClose();
  }, [isCreated, inviteLink, isCreateNew, onClose]);

  const formik = useFormik({
    validationSchema: createMemberValidationScheme,
    initialValues: initialFormValues,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setIsCreateNew(false);
    setIsCreated(false);
  }, []);

  useEffect(() => {
    setIsCreated(false);
    formik.resetForm();
  }, [isOpen]);

  // Обработчики
  function handleSubmit(data: CreateInviteEmployeeDTO) {
    // TODO: тут хитрая доработка
    setIsLoading(true);

    organizationId &&
      dispatch(createInviteEmployeeAction(organizationId, data)).then(() => {
        setIsCreated(true);
        setIsLoading(false);
        setIsCreateNew(false);
        // onClose();
      });
  }

  const handleCancelButtonClick = () => {
    !isLoading && onClose();
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('organization.create.member.back.button.label')}
        view="clear"
        loading={isLoading}
        onClick={handleCancelButtonClick}
      />
      {!inviteLink || isCreateNew ? (
        <Button
          label={t('organization.create.member.signUp.button.label')}
          loading={isLoading}
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        />
      ) : (
        <Button
          label={t('organization.create.member.newEmployee.button.label')}
          onClick={(e) => {
            e.preventDefault();
            setIsCreateNew(true);
          }}
        />
      )}
    </>
  );

  return (
    <DefaultModal
      isOpen={isOpen}
      modalTitle={t('organization.create.member.modal.title')}
      renderActions={renderActions}
      onClose={handleCancelButtonClick}
    >
      {inviteLink && !isCreateNew ? (
        <VerticalContainer className={styles.textContainer}>
          <Text>{t('organization.create.member.info.signUp.label')}</Text>
          <HorizontalContainer>
            <Text className={styles.textWrap}>{inviteLink}</Text>
            <Button
              view="clear"
              iconLeft={IconCopy as any}
              size="s"
              onClick={() => {
                inviteLink && navigator?.clipboard?.writeText(inviteLink || '');
              }}
            />
          </HorizontalContainer>
        </VerticalContainer>
      ) : (
        <>
          <TextField
            label={t('organization.create.member.name.input.label')}
            name="name"
            width="full"
            status={formik.errors.name ? 'alert' : undefined}
            caption={formik.errors.name}
            value={formik.values.name}
            onChange={({ e }) => formik.handleChange(e)}
          />
          <TextField
            label={t('organization.create.member.surname.input.label')}
            name="surname"
            width="full"
            status={formik.errors.surname ? 'alert' : undefined}
            caption={formik.errors.surname}
            value={formik.values.surname}
            onChange={({ e }) => formik.handleChange(e)}
          />
          <TextField
            label={t('organization.create.member.patronymic.input.label')}
            name="patronymic"
            width="full"
            status={formik.errors.patronymic ? 'alert' : undefined}
            caption={formik.errors.patronymic}
            value={formik.values.patronymic}
            onChange={({ e }) => formik.handleChange(e)}
          />
          <Combobox
            label={t('organization.create.member.role.input.label')}
            items={rolesList}
            value={formik.values.position as string}
            getItemLabel={(position) => t(`roles.${position}`)}
            getItemKey={(position) => position}
            onChange={({ value }) => {
              formik.setValues({ ...formik.values, position: (value || '') as Roles });
            }}
          />
          <TextField
            label={t('organization.create.member.email.input.label')}
            name="email"
            width="full"
            status={formik.errors.email ? 'alert' : undefined}
            caption={formik.errors.email}
            value={formik.values.email}
            onChange={({ e }) => formik.handleChange(e)}
          />
          <TextField
            label={t('organization.create.member.phone.input.label')}
            name="phone"
            width="full"
            status={formik.errors.phone ? 'alert' : undefined}
            caption={formik.errors.phone}
            value={formik.values.phone}
            onChange={({ e }) => formik.handleChange(e)}
          />
        </>
      )}
    </DefaultModal>
  );
};

export default CreateMember;
