import BaseTableDataRowCell from './BaseTableDataRowCell';

import { BaseTableDataRowProps } from './types';

import { useMemo } from 'react';

import classNames from 'classnames';
import styles from './BaseTableDataRow.module.scss';

const defaultOnRowClick = () => {
  console.log('Не передан onRowClick');
};

/**
 * Строка базовой таблицы
 */
const BaseTableDataRow = <T,>({
  row,
  columns,
  rowIndex,
  rowClassName = '',
  gridContainerClassName = '',
  onRowClick,
}: BaseTableDataRowProps<T>) => {
  const gridTemplateColumnsStyle = useMemo(
    () => columns.map(({ columnWidth = '1fr' }) => columnWidth).join(' '),
    [columns],
  );

  // render методы
  const renderCells = () =>
    columns.map((column, index) => (
      <BaseTableDataRowCell key={index} row={row} column={column} index={index} />
    ));

  return (
    <div
      className={classNames(
        styles.BaseTableDataRow,
        onRowClick && styles.pointer,
        gridContainerClassName,
        rowClassName,
      )}
      style={{ gridTemplateColumns: gridTemplateColumnsStyle }}
      onClick={onRowClick ? () => onRowClick(row, rowIndex) : defaultOnRowClick}
    >
      {renderCells()}
    </div>
  );
};

export default BaseTableDataRow;
