import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconReceiptSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M8.589 1L7.77994 1.79239L6.97087 1L6.16181 1.79239L5.35275 1L4.54369 1.79239L3.73463 1L2.92557 1.79239L2.1165 1L1 2.0935V9.5103C1 10.3344 1.6877 11 2.52104 11H10.0453C10.5712 11 11 10.58 11 10.065V4.04279H10.377V10.065C10.377 10.2472 10.2233 10.3978 10.0372 10.3978C9.85113 10.3978 9.69741 10.2472 9.69741 10.065V2.0935L8.589 1ZM2.52104 10.3978C2.01942 10.3978 1.61489 10.0016 1.61489 9.5103V2.34707L2.1165 1.85578L2.92557 2.64818L3.73463 1.85578L4.54369 2.64818L5.35275 1.85578L6.16181 2.64818L6.97087 1.85578L7.77994 2.64818L8.589 1.85578L9.09062 2.34707V10.065C9.09062 10.1838 9.11489 10.2948 9.15534 10.3978H2.52104Z" />
  </svg>
);

const IconReceiptSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M11.6246 1L10.4919 2.10935L9.35922 1L8.22654 2.10935L7.09385 1L5.96116 2.10935L4.82848 1L3.69579 2.10935L2.56311 1L1 2.5309V12.9144C1 14.0681 1.96278 15 3.12945 15H13.6634C14.3997 15 15 14.412 15 13.691V5.2599H14.1278V13.691C14.1278 13.9461 13.9126 14.1569 13.6521 14.1569C13.3916 14.1569 13.1764 13.9461 13.1764 13.691V2.5309L11.6246 1ZM3.12945 14.1569C2.42718 14.1569 1.86084 13.6022 1.86084 12.9144V2.8859L2.56311 2.1981L3.69579 3.30745L4.82848 2.1981L5.96116 3.30745L7.09385 2.1981L8.22654 3.30745L9.35922 2.1981L10.4919 3.30745L11.6246 2.1981L12.3269 2.8859V13.691C12.3269 13.8574 12.3608 14.0127 12.4175 14.1569H3.12945Z" />
  </svg>
);

const IconReceiptSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M17.178 2L15.5599 3.58479L13.9417 2L12.3236 3.58479L10.7055 2L9.08738 3.58479L7.46926 2L5.85113 3.58479L4.23301 2L2 4.187V19.0206C2 20.6688 3.3754 22 5.04207 22H20.0906C21.1424 22 22 21.1601 22 20.13V8.08558H20.754V20.13C20.754 20.4945 20.4466 20.7956 20.0744 20.7956C19.7023 20.7956 19.3948 20.4945 19.3948 20.13V4.187L17.178 2ZM5.04207 20.7956C4.03883 20.7956 3.22977 20.0032 3.22977 19.0206V4.69414L4.23301 3.71157L5.85113 5.29635L7.46926 3.71157L9.08738 5.29635L10.7055 3.71157L12.3236 5.29635L13.9417 3.71157L15.5599 5.29635L17.178 3.71157L18.1812 4.69414V20.13C18.1812 20.3677 18.2298 20.5895 18.3107 20.7956H5.04207Z" />
  </svg>
);

export const IconReceipt = createIcon({
  name: 'IconReceipt',
  xs: IconReceiptSizeXS,
  s: IconReceiptSizeS,
  m: IconReceiptSizeM,
});
