import { FC, ReactNode } from 'react';
import styles from './ContentCard.module.scss';
import classNames from 'classnames';
import { DisplayType, displayTypeValue } from '../../pages/App';

type ContentCardProps = {
  /**
   * Контент внутри карточки
   */
  children?: ReactNode;
  /**
   * ClassName контейнера карточки
   */
  className?: string;
  /**
   * Обработчик клика по карточке
   */
  onClick?: (() => void) | React.EventHandler<React.MouseEvent>;
  /**
   * Обработчик наведения мыши на карточку
   */
  onMouseEnter?: (() => void) | React.MouseEventHandler;
  /**
   * Обработчик, вызываемый при выходе мыши из области карточки
   */
  onMouseLeave?: (() => void) | React.MouseEventHandler;
};

/**
 * Карточка для контента
 */
const ContentCard: FC<ContentCardProps> = ({
  children,
  className,
  onClick = () => {
    null;
  },
  onMouseEnter,
  onMouseLeave,
}) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  return (
    <div
      className={classNames(styles.contentCard, className, isLaptop && styles.laptop)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};

export default ContentCard;
