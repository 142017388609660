import { FC } from 'react';
import styles from './ShutdownFilter.module.scss';
import { ShutdownReasonFilters } from '../../../types/serverInterface/machineListDTO';
import { useTranslation } from 'react-i18next';
import FilterButton from '../../filters/FilterButton';
import VerticalContainer from '../../VerticalContainer';
import HorizontalContainer from '../../HorizontalContainer';
import { Radio } from '@consta/uikit/Radio';
import { Text } from '@consta/uikit/__internal__/src/components/Text';

type ShutdownFilterProps = {
  /**
   * Количество автоматов
   */
  listCount: number;
  /**
   * Количество автоматов на складе
   */
  inStockNumber: number;
  /**
   * Количество утилизированных автоматов
   */
  scrapNumber: number;
  /**
   * Количество проданных автоматов
   */
  soldNumber: number;
  /**
   * Количество автоматов на ремонте
   */
  repairNumber: number;
  /**
   * Выбранный статус
   */
  selectedShutdownStatus: ShutdownReasonFilters | null;
  /**
   * Обработчик изменения фильтра причины отключения автомата
   *
   * @param status обновлённый фильтр
   */
  onSelectShutdownStatus: (status: ShutdownReasonFilters | null) => void;
};

/**
 * Фильтр причины отключения автомата от телеметрии
 */
const ShutdownFilter: FC<ShutdownFilterProps> = ({
  repairNumber,
  selectedShutdownStatus,
  listCount,
  scrapNumber,
  soldNumber,
  inStockNumber,
  onSelectShutdownStatus,
}) => {
  const { t } = useTranslation();

  // render методы
  const renderFilter = () => (
    <VerticalContainer space="5xl" className={styles.StatusFilter}>
      <VerticalContainer space="l">
        <HorizontalContainer space="auto">
          <Radio
            label={t('machineControl.machine.list.filter.status.item.allMachines')}
            checked={selectedShutdownStatus === null}
            onChange={() => onSelectShutdownStatus(null)}
          />
          <Text size="m" view="secondary">
            {listCount}
          </Text>
        </HorizontalContainer>
        <HorizontalContainer space="auto">
          <Radio
            label={t('machineControl.machine.list.filter.status.item.inStock')}
            checked={selectedShutdownStatus === ShutdownReasonFilters.IN_STOCK}
            onChange={() => onSelectShutdownStatus(ShutdownReasonFilters.IN_STOCK)}
          />
          <Text size="m" view="secondary">
            {inStockNumber}
          </Text>
        </HorizontalContainer>
        <HorizontalContainer space="auto">
          <Radio
            label={t('machineControl.machine.list.filter.status.item.onRepair')}
            checked={selectedShutdownStatus === ShutdownReasonFilters.ON_REPAIR}
            onChange={() => onSelectShutdownStatus(ShutdownReasonFilters.ON_REPAIR)}
          />
          <Text size="m" view="secondary">
            {repairNumber}
          </Text>
        </HorizontalContainer>
      </VerticalContainer>
    </VerticalContainer>
  );

  return (
    <FilterButton
      label={t('machineControl.machine.list.filter.status.filterButton.label')}
      modalChildren={renderFilter}
    />
  );
};

export default ShutdownFilter;
