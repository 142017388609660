/**
 * dto изменения настройки интеграции vendista
 */
export type EditVendistaDTO = {
  /**
   * Токен для подключения к api vendista
   */
  token: string;
  /**
   * login пользователя vendista
   */
  login: string;
};

/**
 * Тело запроса входа в vendista
 */
export type SignInVendistaTokenReq = {
  login: string;
  password: string;
};

/**
 * Ответ запроса входа в vendista
 */
export type SignInVendistaTokenRes = {
  /**
   * Токен для подключения к api vendista
   */
  token: string;
};

/**
 * Статусы работы интеграции vendista
 */
export enum VendistaTokenStatus {
  NOT_PROVIDED = 'NOT_PROVIDED',
  UNVERIFIED = 'UNVERIFIED',
  WORKING = 'WORKING',
  NOT_WORKING = 'NOT_WORKING',
}

/**
 * Проверка наличия и статуса работы интеграции vendista
 */
export type CheckVendistaTokenDTO = {
  /**
   * Статус работы интеграции vendista
   */
  statusToken: VendistaTokenStatus;
  /**
   * login пользователя vendista
   */
  login: string;
};

/**
 * dto информации о подключении автомата к интеграции vendista
 */
export type MachineVendistaSettingDTO = {
  /**
   * id терминала
   */
  termId: number;
};

/**
 * dto изменения настройки подключения автомата к интеграции vendista
 */
export type EditMachineVendistaSettingDTO = {
  /**
   * id терминала
   */
  termId: number | null;
};
