import * as Yup from 'yup';
import { EditMachineVendistaSettingDTO } from '../../../../../types/serverInterface/vendistaDTO';

export const editMachineVendistaSettingValidationScheme: Yup.ObjectSchema<EditMachineVendistaSettingDTO> =
  Yup.object({
    termId: Yup.number()
      .required()
      .min(10000, 'error.validate.minMax.fromFiveToEight.error.label')
      .max(99999999, 'error.validate.minMax.fromFiveToEight.error.label'),
  });
