import { FC, useEffect, useMemo, useState } from 'react';
import { MachineStoragePageTypeOld } from '../../../../../components/Machine/MachineStorage/MachineStorage';
import MachineStorageScheme from '../../../../../components/Machine/MachineStorage/MachineStorageScheme';
import {
  MachineHomeCellsDTO,
  MachineHomeSnackCell,
  MachineHomeSnackCellsDTO,
} from '../../../../../types/serverInterface/machineDTO';
import { SeparatedMachineStorageHomeType } from '../../../../../components/Machine/MachineStorage/types';
import { ProductGroup } from '../../../../../types/serverInterface/storageDTO';
import MachineStorageSnackScheme from '../../../../../components/Machine/MachineStorage/MachineStorageSnackScheme';

const initialStorage: SeparatedMachineStorageHomeType = {
  cells: {
    [ProductGroup.COFFEE]: [],
    [ProductGroup.CONCENTRATE]: [],
    [ProductGroup.POWDER]: [],
  },
  cellWaters: [],
  cellCups: [],
  cellDisposables: undefined,
  snackCells: [],
};

/**
 * Сортировка групп склада по номеру контейнера
 *
 * @param storage склад
 */
export const getHomeStorageSortedGroup = (storage: SeparatedMachineStorageHomeType) => {
  const arr: Array<ProductGroup> = [];

  for (const key in storage.cells) {
    const minCellNumber = storage.cells[key as ProductGroup]?.length
      ? storage.cells[key as ProductGroup].reduce(
          (acc, item) => (!acc || item.info.cellNumber < acc ? item.info.cellNumber : acc),
          0,
        )
      : null;

    if (minCellNumber !== null) {
      arr[minCellNumber] = key as ProductGroup;
    }
  }

  return arr.splice(1).filter(Boolean);
};

/**
 * Хук для сортировки схемы наполнения снек автомата
 *
 * @param snackSchemeStorage схема наполнения автомата со снеками
 */
export const useSortedSnackSchemeStorage = (
  snackSchemeStorage: MachineHomeSnackCell[],
): [MachineHomeSnackCell[][], number[]] => {
  return useMemo(() => {
    const maxRowNumber =
      snackSchemeStorage.reduce((max, cell) => Math.max(max, cell.rowNumber), 0) || 0;

    const cellsByRow: MachineHomeSnackCell[][] = Array.from({ length: maxRowNumber + 1 }, () => []);

    snackSchemeStorage.forEach((cell) => {
      const { rowNumber } = cell;
      cellsByRow[rowNumber].push(cell);
    });

    const sortedSnackSchemeStorage = cellsByRow.filter((row) => row.length > 0);

    const maxMotorsLengths = sortedSnackSchemeStorage.map((row) =>
      row.reduce((acc, cell) => acc + cell.motorIds.length, 0),
    );

    return [sortedSnackSchemeStorage, maxMotorsLengths];
  }, [snackSchemeStorage]);
};

/**
 * Свойства компонента MachineHomaPageScheme
 */
type MachineHomePageSchemeProps = {
  /**
   * Остатки автомата
   */
  storage: MachineHomeCellsDTO & MachineHomeSnackCellsDTO;
};

/**
 * Схема остатков автомата
 */
const MachineHomePageScheme: FC<MachineHomePageSchemeProps> = ({ storage }) => {
  const [machineStorageArr, setMachineStorageArr] = useState({
    ...initialStorage,
  });

  const isSnackMachine = !!storage.snackCells?.length;

  const sortedGroup = useMemo(
    () => getHomeStorageSortedGroup(machineStorageArr),
    [machineStorageArr],
  );

  useEffect(() => {
    const separateStorageInfoByGroup = (
      storageInfoArray: MachineHomeCellsDTO & MachineHomeSnackCellsDTO,
    ) => {
      const sortedCells = [...(storageInfoArray.cells || [])];
      sortedCells.sort((a, b) => a.cellNumber - b.cellNumber);

      const separatedStorageInfo: SeparatedMachineStorageHomeType = JSON.parse(
        JSON.stringify(initialStorage),
      );

      sortedCells.forEach((produceCell) => {
        separatedStorageInfo.cells[produceCell.group]?.push({
          info: produceCell,
          isHoverStatus: false,
          isActiveStatus: false,
        });
      });

      separatedStorageInfo.cellWaters?.push({
        info: (storageInfoArray.cellWaters || [])[0],
        isHoverStatus: false,
        isActiveStatus: false,
      });

      storageInfoArray.cellCups?.forEach((cupCell) => {
        separatedStorageInfo.cellCups?.push({
          info: cupCell,
          isHoverStatus: false,
          isActiveStatus: false,
        });
      });

      storageInfoArray.snackCells?.forEach((snackCell) => {
        separatedStorageInfo.snackCells?.push({
          info: snackCell,
          isHoverStatus: false,
          isActiveStatus: false,
        });
      });

      setMachineStorageArr(separatedStorageInfo);
    };

    storage && separateStorageInfoByGroup(storage);
  }, [storage]);

  const renderDefaultScheme = () => (
    <MachineStorageScheme
      pageType={MachineStoragePageTypeOld.DETAILS_PAGE}
      storage={machineStorageArr}
      sortedGroup={sortedGroup}
      onClick={() => () => {
        null;
      }}
      onHover={() => () => {
        null;
      }}
      onHoverLeave={() => () => {
        null;
      }}
      onValueChange={() => () => () => {
        null;
      }}
    />
  );

  const snackCellsInfo = machineStorageArr.snackCells.map((snackCell) => snackCell.info);
  const [sortedSnackSchemeStorage, maxMotorsLengths] = useSortedSnackSchemeStorage(snackCellsInfo);

  const renderSnackScheme = () => (
    <MachineStorageSnackScheme
      snackSchemeStorage={sortedSnackSchemeStorage}
      maxMotorsLengths={maxMotorsLengths}
    />
  );

  return isSnackMachine ? renderSnackScheme() : renderDefaultScheme();
};

export default MachineHomePageScheme;
