import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconArrowNextSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.35375 2.35355C2.15848 2.54881 2.15848 2.8654 2.35375 3.06066L4.58597 5.29282C4.97651 5.68335 4.97651 6.31654 4.58596 6.70706L2.35356 8.93934C2.15829 9.1346 2.15828 9.45119 2.35355 9.64646C2.54881 9.84171 2.86538 9.84172 3.06064 9.64646L6.00018 6.70706C6.39073 6.31653 6.39073 5.68335 6.00019 5.29282L3.06085 2.35354C2.86558 2.15829 2.54901 2.15829 2.35375 2.35355ZM5.64623 2.35355C5.45096 2.54881 5.45096 2.8654 5.64623 3.06066L7.87845 5.29282C8.26899 5.68335 8.26899 6.31654 7.87844 6.70706L5.64604 8.93934C5.45077 9.1346 5.45076 9.45119 5.64603 9.64645C5.84129 9.84171 6.15786 9.84172 6.35313 9.64646L9.29266 6.70706C9.68321 6.31653 9.68321 5.68335 9.29267 5.29282L6.35333 2.35354C6.15806 2.15829 5.84149 2.15829 5.64623 2.35355Z"
    />
  </svg>
);

const IconArrowNextSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.70699 3.70711C2.31646 4.09763 2.31646 4.7308 2.70699 5.12132L4.87856 7.29288C5.26908 7.6834 5.26908 8.31657 4.87856 8.70709L2.70698 10.8787C2.31646 11.2692 2.31646 11.9024 2.70698 12.2929C3.09751 12.6834 3.73067 12.6834 4.1212 12.2929L7.70699 8.70709C8.09751 8.31657 8.09751 7.6834 7.70698 7.29288L4.1212 3.7071C3.73067 3.31658 3.09751 3.31658 2.70699 3.70711ZM8.29292 3.70711C7.9024 4.09763 7.9024 4.7308 8.29292 5.12132L10.4645 7.29288C10.855 7.6834 10.855 8.31657 10.4645 8.70709L8.29292 10.8787C7.9024 11.2692 7.9024 11.9024 8.29292 12.2929C8.68345 12.6834 9.31661 12.6834 9.70714 12.2929L13.2929 8.70709C13.6834 8.31657 13.6834 7.6834 13.2929 7.29288L9.70714 3.7071C9.31661 3.31658 8.68345 3.31658 8.29292 3.70711Z"
    />
  </svg>
);

const IconArrowNextSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.70699 3.70711C2.31646 4.09763 2.31646 4.7308 2.70699 5.12132L4.87856 7.29288C5.26908 7.6834 5.26908 8.31657 4.87856 8.70709L2.70698 10.8787C2.31646 11.2692 2.31646 11.9024 2.70698 12.2929C3.09751 12.6834 3.73067 12.6834 4.1212 12.2929L7.70699 8.70709C8.09751 8.31657 8.09751 7.6834 7.70698 7.29288L4.1212 3.7071C3.73067 3.31658 3.09751 3.31658 2.70699 3.70711ZM8.29292 3.70711C7.9024 4.09763 7.9024 4.7308 8.29292 5.12132L10.4645 7.29288C10.855 7.6834 10.855 8.31657 10.4645 8.70709L8.29292 10.8787C7.9024 11.2692 7.9024 11.9024 8.29292 12.2929C8.68345 12.6834 9.31661 12.6834 9.70714 12.2929L13.2929 8.70709C13.6834 8.31657 13.6834 7.6834 13.2929 7.29288L9.70714 3.7071C9.31661 3.31658 8.68345 3.31658 8.29292 3.70711Z"
    />
  </svg>
);

export const IconArrowNext = createIcon({
  name: 'IconArrowRight',
  xs: IconArrowNextSizeXS,
  s: IconArrowNextSizeS,
  m: IconArrowNextSizeM,
});
