import { FC, useEffect } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { authKKBaseUrl, baseUrlFront } from '../../../consts';

/**
 * Компонент, который выполняет перенаправление на форму авторизации при заходе на страницу.
 */
const AuthRedirect: FC = () => {
  useEffect(() => {
    window.location.assign(
      `${authKKBaseUrl}/realms/shaker-realm/protocol/openid-connect/auth?response_type=code&client_id=shaker-client&state=dsfdsfsdfd2s342&redirect_uri=${baseUrlFront}/auth/redirect/&code_challenge=KX_6fjxtW4OPGiU65J0QWBgrOJ87G5HUkzAzdftoYzw&code_challenge_method=S256`,
    );
  }, []);

  return <Text>Требуется авторизация, ожидайте редиректа</Text>;
};

export default AuthRedirect;
