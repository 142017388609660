import { FC, useEffect, useState } from 'react';
import styles from './MachineSnackFilling.module.scss';
import { MachineSnackFillingProps, PriceMapError, SnackCellPriceWithCellId } from './types';
import { useMachineSnackFilling, useSnackBrandList } from './hooks';
import MachineSnackFillingTable from './MachineSnackFillingTable';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconSave } from '../../../../assets/icon/iconSave';
import { createEditMachinePriceArray, validatePriceMap } from './helpers';
import {
  ConnectionStatus,
  EditMachineStorageDTO,
  EditMachineStorageSnackProductCell,
} from '../../../../types/serverInterface/machineDTO';
import { editMachineStorageAction } from '../../../../state/machineControl/actions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { FormikErrors, useFormik } from 'formik';
import { editSnackFillingValidationScheme } from './validationSchemes';
import { formPriceMapInitialError } from './const';
import { initialFieldError } from '../../../../helpers/validateHelpers';
import { selectMachineBaseInfo } from '../../../../state/machineControl/selectors';
import MachineOfflineBlockModal from '../MachineOfflineBlockModal';

/**
 * Страница с наполнением снек автомата
 */
const MachineSnackFilling: FC<MachineSnackFillingProps> = ({ machineId }) => {
  const dispatch = useAppDispatch();

  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());

  const [snackCellsGroupedByRowNumber, snackProductsWithPrices] = useMachineSnackFilling(machineId);
  const snackBrandList = useSnackBrandList();

  const [priceMap, setPriceMap] =
    useState<Record<number, SnackCellPriceWithCellId>>(snackProductsWithPrices);
  const [priceMapError, setPriceMapError] = useState<PriceMapError>(formPriceMapInitialError);
  const [isOpenOfflineBlockModal, setIsOpenOfflineBlockModal] = useState(false);

  const fillingForm = useFormik<EditMachineStorageSnackProductCell[][]>({
    validationSchema: editSnackFillingValidationScheme,
    initialValues: snackCellsGroupedByRowNumber,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (handleValidatePriceMap(priceMap)) {
        handleSave(values, priceMap);
      }
    },
  });

  useEffect(() => {
    if (
      fillingForm.values.length === 0 &&
      JSON.stringify(snackCellsGroupedByRowNumber) !== JSON.stringify(fillingForm.values)
    ) {
      fillingForm.setValues(snackCellsGroupedByRowNumber);
    }
  }, [snackCellsGroupedByRowNumber, fillingForm]);

  useEffect(() => {
    if (Object.keys(priceMap).length === 0 && Object.keys(snackProductsWithPrices).length > 0) {
      setPriceMap(snackProductsWithPrices);
    }
  }, [snackProductsWithPrices, priceMap]);

  // Обработчики
  const handleBrandChange =
    (rowIndex: number) => (cellIndexInRow: number) => (brandId: number | null) => {
      fillingForm.setFieldValue(`[${rowIndex}][${cellIndexInRow}].brandId`, brandId);
      fillingForm.setFieldValue(`[${rowIndex}][${cellIndexInRow}].productId`, null);
    };

  const handleProductChange =
    (rowIndex: number) => (cellIndexInRow: number) => (productId: number | null) => {
      fillingForm.setFieldValue(
        `[${rowIndex}][${cellIndexInRow}].productId`,
        productId,
        !!productId,
      );
    };

  const handlePriceChange = (productId: number) => (price: number | null) => {
    setPriceMap((prevMap) => ({
      ...prevMap,
      [productId]: { ...prevMap[productId], price },
    }));

    setPriceMapError((prevState) => ({ ...prevState, [productId]: initialFieldError }));
  };

  const handleValidatePriceMap = (priceMap: Record<number, SnackCellPriceWithCellId>) => {
    return validatePriceMap(priceMap, fillingForm.values, setPriceMapError);
  };

  const handleSave = (
    newFillingForm: EditMachineStorageSnackProductCell[][],
    newPriceMap: Record<number, SnackCellPriceWithCellId>,
  ) => {
    if (machineBaseInfo && machineBaseInfo.connectionStatus === ConnectionStatus.OFFLINE) {
      setIsOpenOfflineBlockModal(true);
      return;
    }

    const newFlatArr = newFillingForm.flat();
    const newPrices = createEditMachinePriceArray(newFillingForm, newPriceMap);

    const data: EditMachineStorageDTO = {
      cells: {
        cells: [],
        cellCups: [],
        cellWaters: [],
        cellDisposables: [],
        snackCells: newFlatArr,
      },
      prices: newPrices,
      calibrations: [],
    };

    dispatch(editMachineStorageAction(machineId, data));
  };

  const handleEditOfflineBlockModalClose = () => {
    setIsOpenOfflineBlockModal(false);
  };

  // render методы
  const renderTable = () => (
    <MachineSnackFillingTable
      groupedSnackCells={fillingForm.values}
      snackBrandList={snackBrandList}
      snackProductsWithPrices={priceMap}
      handleBrandChange={handleBrandChange}
      handleProductChange={handleProductChange}
      handlePriceChange={handlePriceChange}
      fillingFormErrors={fillingForm.errors as FormikErrors<EditMachineStorageSnackProductCell>[][]}
      priceMapErrors={priceMapError}
    />
  );

  const renderModal = () => (
    <MachineOfflineBlockModal
      isOpen={isOpenOfflineBlockModal}
      onCancel={handleEditOfflineBlockModalClose}
    />
  );

  const renderActions = () => (
    <div className={styles.buttonContainer}>
      <Button
        view="primary"
        size="m"
        onlyIcon
        iconLeft={IconSave as any}
        onClick={fillingForm.submitForm}
      />
    </div>
  );

  return (
    <form onSubmit={fillingForm.handleSubmit} className={styles.MachineSnackFilling}>
      {renderModal()}
      {renderTable()}
      {renderActions()}
    </form>
  );
};

export default MachineSnackFilling;
