import React, { FC } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useTranslation } from 'react-i18next';
import DefaultModal from '../../../../components/DefaultModal';
import AlertButton from '../../../../components/AlertButton';
import { BlockUnblockModalProps } from './types';

/**
 * Модальное окно для блокировки/разблокировки сотрудника
 */
const BlockUnblockModal: FC<BlockUnblockModalProps> = ({
  isOpen,
  isBlockAction,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const modalTitle = isBlockAction
    ? t('organization.employee.key.profile.blockModal.title')
    : t('organization.employee.key.profile.unblockModal.title');

  const modalLabel = isBlockAction
    ? t('organization.employee.key.profile.blockModal.label')
    : t('organization.employee.key.profile.unblockModal.label');

  const actionButtonLabel = isBlockAction
    ? t('organization.employee.key.profile.block.button.title')
    : t('organization.employee.key.profile.unblock.button.title');

  // render методы
  const renderActions = () => (
    <>
      <Button
        view="clear"
        label={t('organization.employee.key.profile.cancel.button.title')}
        onClick={onClose}
      />
      <AlertButton label={actionButtonLabel} onClick={onSubmit} />
    </>
  );

  return (
    <DefaultModal
      modalTitle={modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      renderActions={renderActions}
    >
      <Text>{modalLabel}</Text>
    </DefaultModal>
  );
};

export default BlockUnblockModal;
