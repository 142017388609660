import { AxiosCoreApi } from './axiosCore';
import { AuthModule } from './modules/auth';
import { UserModule } from './modules/user';
import { ProductBaseModule } from './modules/productBase';
import { MachineControlModule } from './modules/machineControl';
import { OrganizationModule } from './modules/organization';
import { PromoCodeModule } from './modules/promoCode';
import { NotificationModule } from './modules/notification';
import { VendistaModule } from './modules/vendista';

export class Api {
  private readonly request: AxiosCoreApi;

  public readonly auth: AuthModule;
  public readonly organizations: OrganizationModule;
  public readonly user: UserModule;
  public readonly productBase: ProductBaseModule;
  public readonly machineControl: MachineControlModule;
  public readonly promoCode: PromoCodeModule;
  public readonly notification: NotificationModule;
  public readonly vendista: VendistaModule;

  constructor() {
    this.request = new AxiosCoreApi();

    this.auth = new AuthModule(this.request);
    this.organizations = new OrganizationModule(this.request);
    this.user = new UserModule(this.request);
    this.productBase = new ProductBaseModule(this.request);
    this.machineControl = new MachineControlModule(this.request);
    this.promoCode = new PromoCodeModule(this.request);
    this.notification = new NotificationModule(this.request);
    this.vendista = new VendistaModule(this.request);
  }

  clearTokens(): void {
    this.request.accessToken = null;
  }

  saveToken(token: string): void {
    this.request.accessToken = token;
  }
}

export const api = new Api();
