import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconSwapSizeL = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" {...props}>
    <path d="M6.1928 9.9687C5.71951 9.49724 5.32717 8.97395 5.22063 8.30525C5.19793 8.16279 5.18652 8.01908 5.18652 7.87513C5.18652 7.73118 5.19793 7.58747 5.22063 7.44501C5.32717 6.77632 5.71951 6.25303 6.1928 5.78156C6.654 5.32214 7.30139 4.80885 8.08594 4.18681L10.1846 2.52283C10.5632 2.22259 11.1136 2.28617 11.4138 2.66483C11.7141 3.0435 11.6505 3.59385 11.2718 3.89409L9.21502 5.52491C8.39366 6.17617 7.83132 6.6237 7.4493 7.00013L22.3949 7.00013C22.8781 7.00013 23.2699 7.39188 23.2699 7.87513C23.2699 8.35838 22.8781 8.75013 22.3949 8.75013L7.4493 8.75013C7.83132 9.12657 8.39366 9.57409 9.21502 10.2253L11.2718 11.8562C11.6505 12.1564 11.7141 12.7068 11.4138 13.0854C11.1136 13.4641 10.5632 13.5277 10.1846 13.2274L8.08594 11.5634C7.30139 10.9414 6.65399 10.4281 6.1928 9.9687Z" />
    <path d="M7.22819 18.6668C6.74494 18.6668 6.35319 19.0585 6.35319 19.5418C6.35319 20.025 6.74494 20.4168 7.22819 20.4168L22.1737 20.4168C21.7917 20.7932 21.2294 21.2408 20.408 21.892L18.3512 23.5228C17.9726 23.8231 17.909 24.3734 18.2092 24.7521C18.5095 25.1308 19.0598 25.1943 19.4385 24.8941L21.5371 23.2301C22.3216 22.6081 22.969 22.0948 23.4303 21.6354C23.9035 21.1639 24.2959 20.6406 24.4024 19.9719C24.4251 19.8295 24.4365 19.6857 24.4365 19.5418C24.4365 19.3978 24.4251 19.2541 24.4024 19.1117C24.2959 18.443 23.9035 17.9197 23.4303 17.4482C22.969 16.9888 22.3216 16.4755 21.5371 15.8535L19.4385 14.1895C19.0598 13.8893 18.5095 13.9528 18.2092 14.3315C17.909 14.7102 17.9726 15.2605 18.3512 15.5608L20.408 17.1916C21.2294 17.8428 21.7917 18.2904 22.1737 18.6668L7.22819 18.6668Z" />
  </svg>
);

export const IconSwap = createIcon({
  name: 'IconSwap',
  m: IconSwapSizeL,
  s: IconSwapSizeL,
  xs: IconSwapSizeL,
});
