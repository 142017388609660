import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconCalculatorSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C2.44769 0 2 0.447716 2 1V11C2 11.5523 2.44769 12 3 12H9C9.55231 12 10 11.5523 10 11V1C10 0.447716 9.55231 0 9 0H3ZM9 1.5C9 1.22386 8.77614 1 8.5 1H3.5C3.22386 1 3 1.22386 3 1.5V3.5C3 3.77614 3.22386 4 3.5 4H8.5C8.77614 4 9 3.77614 9 3.5V1.5ZM3.5 6C3.5 5.72386 3.72386 5.5 4 5.5H5C5.27614 5.5 5.5 5.72386 5.5 6V7C5.5 7.27614 5.27614 7.5 5 7.5H4C3.72386 7.5 3.5 7.27614 3.5 7V6ZM8.5 6C8.5 5.72386 8.27614 5.5 8 5.5H7C6.72386 5.5 6.5 5.72386 6.5 6V7C6.5 7.27614 6.72386 7.5 7 7.5H8C8.27614 7.5 8.5 7.27614 8.5 7V6ZM6.5 9C6.5 8.72386 6.72386 8.5 7 8.5H8C8.27614 8.5 8.5 8.72386 8.5 9V10C8.5 10.2761 8.27614 10.5 8 10.5H7C6.72386 10.5 6.5 10.2761 6.5 10V9ZM5.5 9C5.5 8.72386 5.27614 8.5 5 8.5H4C3.72386 8.5 3.5 8.72386 3.5 9V10C3.5 10.2761 3.72386 10.5 4 10.5H5C5.27614 10.5 5.5 10.2761 5.5 10V9Z"
    />
  </svg>
);

const IconCalculatorSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C2.44769 0 2 0.447708 2 1V15C2 15.5523 2.44769 16 3 16H13C13.5523 16 14 15.5523 14 15V1C14 0.447708 13.5523 0 13 0H3ZM4.5 2C4.22386 2 4 2.22386 4 2.5V4.5C4 4.77614 4.22386 5 4.5 5H11.5C11.7761 5 12 4.77614 12 4.5V2.5C12 2.22386 11.7761 2 11.5 2H4.5ZM4 6.5C4 6.22386 4.22386 6 4.5 6H5.5C5.77614 6 6 6.22386 6 6.5V7.5C6 7.77614 5.77614 8 5.5 8H4.5C4.22386 8 4 7.77614 4 7.5V6.5ZM6 9.5C6 9.22386 5.77614 9 5.5 9H4.5C4.22386 9 4 9.22386 4 9.5V10.5C4 10.7761 4.22386 11 4.5 11H5.5C5.77614 11 6 10.7761 6 10.5V9.5ZM4 12.5C4 12.2239 4.22386 12 4.5 12H5.5C5.77614 12 6 12.2239 6 12.5V13.5C6 13.7761 5.77614 14 5.5 14H4.5C4.22386 14 4 13.7761 4 13.5V12.5ZM9 6.5C9 6.22386 8.77614 6 8.5 6H7.5C7.22386 6 7 6.22386 7 6.5V7.5C7 7.77614 7.22386 8 7.5 8H8.5C8.77614 8 9 7.77614 9 7.5V6.5ZM7 9.5C7 9.22386 7.22386 9 7.5 9H8.5C8.77614 9 9 9.22386 9 9.5V10.5C9 10.7761 8.77614 11 8.5 11H7.5C7.22386 11 7 10.7761 7 10.5V9.5ZM9 12.5C9 12.2239 8.77614 12 8.5 12H7.5C7.22386 12 7 12.2239 7 12.5V13.5C7 13.7761 7.22386 14 7.5 14H8.5C8.77614 14 9 13.7761 9 13.5V12.5ZM10 6.5C10 6.22386 10.2239 6 10.5 6H11.5C11.7761 6 12 6.22386 12 6.5V7.5C12 7.77614 11.7761 8 11.5 8H10.5C10.2239 8 10 7.77614 10 7.5V6.5ZM12 9.5C12 9.22386 11.7761 9 11.5 9H10.5C10.2239 9 10 9.22386 10 9.5V13.5C10 13.7761 10.2239 14 10.5 14H11.5C11.7761 14 12 13.7761 12 13.5V9.5Z"
    />
  </svg>
);

const IconCalculatorSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C4.44772 2 4 2.44772 4 3V21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21V3C20 2.44772 19.5523 2 19 2H5ZM18 4.5C18 4.22386 17.7761 4 17.5 4H6.5C6.22386 4 6 4.22386 6 4.5V7.5C6 7.77614 6.22386 8 6.5 8H17.5C17.7761 8 18 7.77614 18 7.5V4.5ZM7 10.5C7 10.2239 7.22386 10 7.5 10H8.5C8.77614 10 9 10.2239 9 10.5V11.5C9 11.7761 8.77614 12 8.5 12H7.5C7.22386 12 7 11.7761 7 11.5V10.5ZM9 14.5C9 14.2239 8.77614 14 8.5 14H7.5C7.22386 14 7 14.2239 7 14.5V15.5C7 15.7761 7.22386 16 7.5 16H8.5C8.77614 16 9 15.7761 9 15.5V14.5ZM7 18.5C7 18.2239 7.22386 18 7.5 18H8.5C8.77614 18 9 18.2239 9 18.5V19.5C9 19.7761 8.77614 20 8.5 20H7.5C7.22386 20 7 19.7761 7 19.5V18.5ZM13 10.5C13 10.2239 12.7761 10 12.5 10H11.5C11.2239 10 11 10.2239 11 10.5V11.5C11 11.7761 11.2239 12 11.5 12H12.5C12.7761 12 13 11.7761 13 11.5V10.5ZM11 14.5C11 14.2239 11.2239 14 11.5 14H12.5C12.7761 14 13 14.2239 13 14.5V15.5C13 15.7761 12.7761 16 12.5 16H11.5C11.2239 16 11 15.7761 11 15.5V14.5ZM13 18.5C13 18.2239 12.7761 18 12.5 18H11.5C11.2239 18 11 18.2239 11 18.5V19.5C11 19.7761 11.2239 20 11.5 20H12.5C12.7761 20 13 19.7761 13 19.5V18.5ZM15 10.5C15 10.2239 15.2239 10 15.5 10H16.5C16.7761 10 17 10.2239 17 10.5V11.5C17 11.7761 16.7761 12 16.5 12H15.5C15.2239 12 15 11.7761 15 11.5V10.5ZM17 14.5C17 14.2239 16.7761 14 16.5 14H15.5C15.2239 14 15 14.2239 15 14.5V19.5C15 19.7761 15.2239 20 15.5 20H16.5C16.7761 20 17 19.7761 17 19.5V14.5Z"
    />
  </svg>
);

export const IconCalculator = createIcon({
  name: 'IconCalculator',
  s: IconCalculatorSizeS,
  m: IconCalculatorSizeM,
  xs: IconCalculatorSizeXS,
});
