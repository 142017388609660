import { FC, useState } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import VerticalContainer from '../../../../components/VerticalContainer';
import CreateTelegramNotificationSetting from './CreateTelegramNotificationSetting';
import { TelegramNotificationSettingDTO } from '../../../../types/serverInterface/notificationDTO';
import EditTelegramNotificationSetting from './EditTelegramNotificationSetting';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import DeleteTelegramNotificationSetting from './DeleteTelegramNotificationSetting';
import ContentCard from '../../../../components/ContentCard';
import styles from './TelegramNotificationSettings.module.scss';
import { IconLogoTelegram } from '../../../../assets/icon/iconLogoTelegram';
import { defaultIconProps } from '../../../../consts/defaultIconProps';
import { IconClose } from '@consta/uikit/IconClose';
import { TelegramNotificationSettingsProps } from './types';
import { IconSettings } from '@consta/uikit/IconSettings';
import { useTranslation } from 'react-i18next';

/**
 * Страница настройки телеграм уведомлений
 */
const TelegramNotificationSettings: FC<TelegramNotificationSettingsProps> = ({
  organizationId,
  telegramNotificationList,
  isLoading,
  isOpenCreateModal,
  onCreateClose,
}) => {
  const { t } = useTranslation();

  const noNotificationsCreated = !telegramNotificationList?.length;

  const [editingSetting, setIsEditingSetting] = useState<TelegramNotificationSettingDTO | null>(
    null,
  );
  const [selectedIdForDeletion, setSelectedIdForDeletion] = useState<number | null>(null);

  // Обработчики
  const handleEditClick = () => {
    !noNotificationsCreated && setIsEditingSetting(telegramNotificationList[0]);
  };

  const handleEditClose = () => {
    setIsEditingSetting(null);
  };

  const handleDeleteClick = () => {
    !noNotificationsCreated && setSelectedIdForDeletion(telegramNotificationList[0].id);
  };

  const handleDeleteCancel = () => {
    setSelectedIdForDeletion(null);
  };

  // render методы
  const renderTelegramInfo = () => (
    <HorizontalContainer space="m">
      <IconLogoTelegram size="m" className={styles.TelegramLogo} {...defaultIconProps} />
      <VerticalContainer space={0}>
        <Text size="l" weight="medium">
          {t('integration.telegram.info.title')}
        </Text>
        <Text size="m" view="success">
          {t('integration.telegram.info.status.connected.label')}
        </Text>
      </VerticalContainer>
    </HorizontalContainer>
  );

  const renderTelegramButtons = () => (
    <HorizontalContainer space="s" align="center">
      <Button
        size="m"
        view="clear"
        onClick={handleEditClick}
        iconLeft={IconSettings as any}
        onlyIcon
      />
      <Button
        size="m"
        view="clear"
        onClick={handleDeleteClick}
        iconLeft={IconClose as any}
        onlyIcon
      />
    </HorizontalContainer>
  );

  const renderTelegramNotifications = () =>
    !isLoading && (
      <ContentCard className={styles.TelegramNotification}>
        <HorizontalContainer isAutoSpace>
          {renderTelegramInfo()}
          {renderTelegramButtons()}
        </HorizontalContainer>
      </ContentCard>
    );

  const renderCreateForm = () =>
    isOpenCreateModal && (
      <CreateTelegramNotificationSetting organizationId={organizationId} onClose={onCreateClose} />
    );

  const renderEditForm = () =>
    editingSetting && (
      <EditTelegramNotificationSetting
        organizationId={organizationId}
        editedSetting={editingSetting}
        onClose={handleEditClose}
      />
    );

  const renderDeleteModal = (organizationId: number) =>
    selectedIdForDeletion && (
      <DeleteTelegramNotificationSetting
        selectedIdForDeletion={selectedIdForDeletion}
        organizationId={organizationId}
        onClose={handleDeleteCancel}
      />
    );

  if (!organizationId) return null;

  return (
    <VerticalContainer className={styles.TelegramNotificationSettings}>
      {renderCreateForm()}
      {renderEditForm()}
      {renderDeleteModal(organizationId)}
      {renderTelegramNotifications()}
    </VerticalContainer>
  );
};

export default TelegramNotificationSettings;
