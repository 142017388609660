import { FC } from 'react';
import styles from './MachineSnackFillingTableHeader.module.scss';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useTranslation } from 'react-i18next';

/**
 * Заголовок таблицы наполнения снек автомата
 */
const MachineSnackFillingTableHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <thead className={styles.head}>
      <tr className={styles.row}>
        <th className={classNames(styles.cellHeader, styles.cellNumberCell)}>
          <Text size="m" weight="semibold" view="secondary">
            {t('machineControl.machine.snackFilling.table.header.numeroSign')}
          </Text>
        </th>
        <th className={classNames(styles.cellHeader, styles.brandCell)}>
          <Text size="m" weight="semibold" view="secondary">
            {t('machineControl.machine.snackFilling.table.header.brand')}
          </Text>
        </th>
        <th className={classNames(styles.cellHeader, styles.productCell)}>
          <Text size="m" weight="semibold" view="secondary">
            {t('machineControl.machine.snackFilling.table.header.product')}
          </Text>
        </th>
        <th className={classNames(styles.cellHeader, styles.priceCell)}>
          <Text size="m" weight="semibold" view="secondary">
            {t('machineControl.machine.snackFilling.table.header.price')}
          </Text>
        </th>
      </tr>
    </thead>
  );
};

export default MachineSnackFillingTableHeader;
