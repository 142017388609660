import { CustomFormItemNumberUnits, CustomFormType } from '../types/serverInterface/customForm';

/**
 * Получение изменённого названия элемента калибровки продукта
 *
 * @param name название
 * @param description описание
 * @param key ключи поля
 * @param unit единица измерения
 * @param cellCategoryName название категории
 */
export const getCalibrationItemFormatName = (
  name: string,
  description: string,
  key: string,
  unit: CustomFormItemNumberUnits | null | undefined,
  cellCategoryName: string,
): { name: string; description: string; unit: CustomFormItemNumberUnits | null | undefined } => {
  const isPowder = cellCategoryName === 'Порошок';

  //TODO: 10715 затащить константу локализации

  switch (key) {
    case 'DrinkVolume':
      return {
        name: 'Объём напитка',
        description: 'Объём напитка',
        unit: CustomFormItemNumberUnits.ML,
      };
    case 'Water':
      return { name: 'Вода', description: 'Вода', unit: CustomFormItemNumberUnits.ML };
    case 'Product':
      return {
        name: 'Продукт',
        description: 'Продукт',
        unit:
          cellCategoryName && isPowder ? CustomFormItemNumberUnits.G : CustomFormItemNumberUnits.ML,
      };
    case 'ConversionFactor':
      return {
        name: 'Калибровочное значение',
        description: 'Калибровочное значение',
        unit:
          cellCategoryName && isPowder
            ? CustomFormItemNumberUnits.G_IN_SEC
            : CustomFormItemNumberUnits.ML_IN_SEC,
      };
  }

  return { name, description, unit };
};

/**
 * Форматирование калибровки продукта
 *
 * @param productCalibration калибровка продукта
 * @param cellCategoryName название категории
 */
export const getModifiedArrayForFormattingProductCalibration = (
  productCalibration: CustomFormType,
  cellCategoryName: string,
): CustomFormType =>
  productCalibration?.map((item) => {
    const { name, description, unit } = getCalibrationItemFormatName(
      item.name,
      item.description,
      item.key,
      item.unit,
      cellCategoryName,
    );

    return {
      ...item,
      name,
      description,
      unit: unit as any,
    };
  });
