import {
  MachineModelDetailsDTO,
  MachineModelDTO,
  MachineModelGroup,
} from '../../../../types/serverInterface/machineModelDTO';
import { ProductGroup, ProductView } from '../../../../types/serverInterface/storageDTO';
import { StorageUnionType } from '../../../../components/Machine/MachineStorage/types';
import {
  ConnectionStatus,
  MachineBaseInfoDTO,
  MachineHomeRes,
  MachinePricesInfoRes,
  MachineStorageInfoRes,
  StatusColor,
} from '../../../../types/serverInterface/machineDTO';
import { Roles } from '../../../../types/serverInterface/cabinetDTO';
import {
  getSnackCellsByMachineHome,
  getSnackCellsByMachineStorageInfo,
} from '../../../../state/machineControl/mock';

export const machineBaseInfoMock: MachineBaseInfoDTO = {
  connectionStatus: ConnectionStatus.OFFLINE,
  status: {
    date: '22.02.2024',
    text: 'Работает стабильно',
    color: StatusColor.SUCCESS,
  },
  machine: {
    name: 'Снек автомат',
    serialNumber: 'S-12345678',
    modelName: 'Снек',
  },
};

export const machineSnackStorageInfoMock: MachineStorageInfoRes = {
  id: 999,
  snackCells: getSnackCellsByMachineStorageInfo(),
  cells: null,
  cellWaters: null,
  cellDisposables: null,
  cellCups: null,
};

export const machineSnackPricesInfoMock: MachinePricesInfoRes = {
  qtyDosage: 1,
  prices: [
    {
      prices: [
        {
          id: 1,
          price: 100,
          volume: 1,
          isBlock: false,
        },
      ],
      ingredientId: 56,
      productLineName: null,
      productName: 'Вода детская Святой Источник Спортик 0,33л Спорт негаз. ПЭТ',
      cell: [
        {
          number: 101,
          view: ProductView.DEFAULT,
        },
        {
          number: 102,
          view: ProductView.DEFAULT,
        },
        {
          number: 103,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'Nestle',
    },
    {
      prices: [
        {
          id: 2,
          price: 150,
          volume: 1,
          isBlock: false,
        },
      ],
      ingredientId: 39,
      productLineName: null,
      productName: 'Фалафель',
      cell: [
        {
          number: 104,
          view: ProductView.DEFAULT,
        },
        {
          number: 105,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'Кошерные деликатесы',
    },
    {
      prices: [
        {
          id: 3,
          price: 200,
          volume: 1,
          isBlock: false,
        },
      ],
      ingredientId: 17,
      productLineName: null,
      productName: 'Pepsi Max 0,5л ПЭТ',
      cell: [
        {
          number: 106,
          view: ProductView.DEFAULT,
        },
        {
          number: 107,
          view: ProductView.DEFAULT,
        },
        {
          number: 108,
          view: ProductView.DEFAULT,
        },
        {
          number: 109,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'PepsiCo',
    },
    {
      prices: [
        {
          id: 4,
          price: 180,
          volume: 1,
          isBlock: true,
        },
      ],
      ingredientId: 3,
      productLineName: null,
      productName: 'Молоко Простоквашино 2,5% 0,5л',
      cell: [
        {
          number: 110,
          view: ProductView.DEFAULT,
        },
        {
          number: 111,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'Nestle',
    },
    {
      prices: [
        {
          id: 5,
          price: 250,
          volume: 1,
          isBlock: false,
        },
      ],
      ingredientId: 20,
      productLineName: null,
      productName: 'Red Bull Energy Drink 0,25л ПЭТ',
      cell: [
        {
          number: 112,
          view: ProductView.DEFAULT,
        },
        {
          number: 113,
          view: ProductView.DEFAULT,
        },
        {
          number: 114,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'Mondelez',
    },
    {
      prices: [
        {
          id: 6,
          price: 140,
          volume: 1,
          isBlock: false,
        },
      ],
      ingredientId: 15,
      productLineName: null,
      productName: 'Snickers 50г',
      cell: [
        {
          number: 115,
          view: ProductView.DEFAULT,
        },
        {
          number: 116,
          view: ProductView.DEFAULT,
        },
        {
          number: 117,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'Mars',
    },
    {
      prices: [
        {
          id: 7,
          price: 180,
          volume: 1,
          isBlock: false,
        },
      ],
      ingredientId: 41,
      productLineName: null,
      productName: 'Активия клубничная 150г',
      cell: [
        {
          number: 118,
          view: ProductView.DEFAULT,
        },
        {
          number: 119,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'Долина',
    },
    {
      prices: [
        {
          id: 8,
          price: 90,
          volume: 1,
          isBlock: false,
        },
      ],
      ingredientId: 19,
      productLineName: null,
      productName: "Lay's Сметана и лук 90г",
      cell: [
        {
          number: 120,
          view: ProductView.DEFAULT,
        },
        {
          number: 121,
          view: ProductView.DEFAULT,
        },
        {
          number: 122,
          view: ProductView.DEFAULT,
        },
        {
          number: 123,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'PepsiCo',
    },
    {
      prices: [
        {
          id: 9,
          price: 210,
          volume: 1,
          isBlock: true,
        },
      ],
      ingredientId: 35,
      productLineName: null,
      productName: 'Jacobs Monarch 100г',
      cell: [
        {
          number: 124,
          view: ProductView.DEFAULT,
        },
        {
          number: 125,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'Кофе в мешках',
    },
    {
      prices: [
        {
          id: 10,
          price: 130,
          volume: 1,
          isBlock: false,
        },
      ],
      ingredientId: 51,
      productLineName: null,
      productName: 'Pringles Original 40г',
      cell: [
        {
          number: 126,
          view: ProductView.DEFAULT,
        },
        {
          number: 127,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'Pringles',
    },
    {
      prices: [
        {
          id: 11,
          price: 240,
          volume: 1,
          isBlock: false,
        },
      ],
      ingredientId: 18,
      productLineName: null,
      productName: 'Сок RICH Яблоко 1л',
      cell: [
        {
          number: 128,
          view: ProductView.DEFAULT,
        },
        {
          number: 129,
          view: ProductView.DEFAULT,
        },
      ],
      brandName: 'PepsiCo',
    },
  ],
};

export const machineHomeResMock: MachineHomeRes = {
  cells: null,
  cellWaters: null,
  cellCups: null,
  snackCells: getSnackCellsByMachineHome(),
  // logMachines: [
  //   {
  //     type: LogMachinesTypeEnum.ALERT,
  //     name: 'Error #5',
  //     description: 'Нет Стакана',
  //     date: '17.07.2024 17:45',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.WARNING,
  //     name: 'Требуется обслуживание: пополните контейнеры.',
  //     description: '',
  //     date: '17.07.2024 11:07',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.SUCCESS,
  //     name: 'Контейнеры пополнены.',
  //     description: '',
  //     date: '17.07.2024 10:34',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.WARNING,
  //     name: 'Требуется обслуживание: пополните контейнеры.',
  //     description: '',
  //     date: '17.07.2024 10:33',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.SUCCESS,
  //     name: 'Контейнеры пополнены.',
  //     description: '',
  //     date: '16.07.2024 18:14',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.WARNING,
  //     name: 'Требуется обслуживание: пополните контейнеры.',
  //     description: '',
  //     date: '16.07.2024 18:13',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.WARNING,
  //     name: 'Требуется обслуживание: пополните контейнеры.',
  //     description: '',
  //     date: '16.07.2024 18:09',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.WARNING,
  //     name: 'Требуется обслуживание: пополните контейнеры.',
  //     description: '',
  //     date: '16.07.2024 18:07',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.SUCCESS,
  //     name: 'Контейнеры пополнены.',
  //     description: '',
  //     date: '25.06.2024 17:15',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.WARNING,
  //     name: 'Требуется обслуживание: пополните контейнеры.',
  //     description: '',
  //     date: '25.06.2024 17:15',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.SUCCESS,
  //     name: 'Контейнеры пополнены.',
  //     description: '',
  //     date: '25.06.2024 17:13',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.SUCCESS,
  //     name: 'Контейнеры пополнены.',
  //     description: '',
  //     date: '25.06.2024 17:12',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.WARNING,
  //     name: 'Требуется обслуживание: пополните контейнеры.',
  //     description: '',
  //     date: '25.06.2024 17:10',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.SUCCESS,
  //     name: 'Контейнеры пополнены.',
  //     description: '',
  //     date: '25.06.2024 15:27',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.WARNING,
  //     name: 'Требуется обслуживание: пополните контейнеры.',
  //     description: '',
  //     date: '25.06.2024 15:27',
  //   },
  //   {
  //     type: LogMachinesTypeEnum.WARNING,
  //     name: 'Требуется обслуживание: пополните контейнеры.',
  //     description: '',
  //     date: '24.06.2024 16:16',
  //   },
  // ],
  histories: [
    {
      name: 'имя',
      surname: 'пользователя',
      patronymic: null,
      position: Roles.ROOT,
      date: '03.10.2024 15:00',
    },
    {
      name: 'Данил',
      surname: 'Высоковских',
      patronymic: '',
      position: Roles.ORGANIZATION_OWNER,
      date: '17.07.2024 13:27',
    },
    {
      name: 'Данил',
      surname: 'Высоковских',
      patronymic: '',
      position: Roles.ORGANIZATION_OWNER,
      date: '17.07.2024 13:27',
    },
    {
      name: 'Данил',
      surname: 'Высоковских',
      patronymic: '',
      position: Roles.ORGANIZATION_OWNER,
      date: '17.07.2024 13:25',
    },
    {
      name: 'Данил',
      surname: 'Высоковских',
      patronymic: '',
      position: Roles.ORGANIZATION_OWNER,
      date: '17.07.2024 13:25',
    },
    {
      name: 'Данил',
      surname: 'Высоковских',
      patronymic: '',
      position: Roles.ORGANIZATION_OWNER,
      date: '17.07.2024 13:25',
    },
    {
      name: 'Данил',
      surname: 'Высоковских',
      patronymic: '',
      position: Roles.ORGANIZATION_OWNER,
      date: '17.07.2024 13:25',
    },
    {
      name: 'Данил',
      surname: 'Высоковских',
      patronymic: '',
      position: Roles.ORGANIZATION_OWNER,
      date: '17.07.2024 13:25',
    },
    {
      name: 'Данил',
      surname: 'Высоковских',
      patronymic: '',
      position: Roles.ORGANIZATION_OWNER,
      date: '17.07.2024 13:25',
    },
    {
      name: 'Данил',
      surname: 'Высоковских',
      patronymic: '',
      position: Roles.ORGANIZATION_OWNER,
      date: '17.07.2024 13:25',
    },
    {
      name: 'имя',
      surname: 'пользователя',
      patronymic: null,
      position: Roles.ROOT,
      date: '08.05.2024 10:46',
    },
    {
      name: 'имя',
      surname: 'пользователя',
      patronymic: null,
      position: Roles.ROOT,
      date: '08.05.2024 10:42',
    },
    {
      name: 'Александр',
      surname: 'Нефедов',
      patronymic: 'Сергеевич',
      position: Roles.ROOT,
      date: '24.04.2024 12:20',
    },
  ],
};

export const machineFilling: Record<number, StorageUnionType[]> = {
  1: [],
  2: [],
  3: [],
};

// export const machinePrices: Record<number, MachinePricesDTO> = {
//   1: {
//     maxDosageNumber: 2,
//     products: [
//       {
//         // productId: 1,
//         name: 'Капучино',
//         cellNumbers: [
//           { cellNumber: 1, view: ProductView.PRIMARY },
//           { cellNumber: 2, view: ProductView.PRIMARY },
//         ],
//         dosage: [
//           {
//             price: 250,
//             volume: 150,
//           },
//           {
//             price: 350,
//             volume: 250,
//           },
//         ],
//       },
//       {
//         // productId: 1,
//         name: 'Латте',
//
//         cellNumbers: [{ cellNumber: 3, view: ProductView.COFFEE }],
//         dosage: [
//           {
//             price: 200,
//             volume: 150,
//           },
//           {
//             price: 300,
//             volume: 250,
//           },
//         ],
//       },
//       {
//         // productId: 1,
//         name: 'Американо',
//         cellNumbers: [
//           { cellNumber: 4, view: ProductView.SECONDARY },
//           { cellNumber: 5, view: ProductView.SECONDARY },
//           { cellNumber: 6, view: ProductView.SECONDARY },
//         ],
//         dosage: [
//           {
//             price: 200,
//             volume: 100,
//           },
//           {
//             price: 300,
//             volume: 200,
//           },
//         ],
//       },
//     ],
//   },
// };

// export const machineSalesList: MachineSalesDTO[] = [
//   {
//     id: 1,
//     name: 'Святой источник',
//     volume: 250,
//     unit: 'ML',
//     price: 150,
//     discountPrice: 145,
//     methods: [SaleMethod.QR_CODE],
//     promoCode: {
//       code: 'SHAKER2020',
//       type: TypeDiscountEnum.PERCENT,
//       amount: 5,
//     },
//     dateSale: '27.03.2024 16:03:22',
//   },
//   {
//     id: 2,
//
//     name: 'Святой источник',
//     volume: 250,
//     unit: 'ML',
//     price: 150,
//     discountPrice: 145,
//     methods: [SaleMethod.CASH],
//     promoCode: {
//       code: 'SHAKER2020',
//       type: TypeDiscountEnum.FREE,
//     },
//     dateSale: '27.03.2024 16:03:22',
//   },
//   {
//     id: 3,
//     name: 'Святой источник',
//     volume: 250,
//     unit: 'ML',
//     price: 150,
//     discountPrice: [{}],
//     methods: [SaleMethod.QR_CODE, SaleMethod.RF_ID],
//     promoCode: {
//       code: 'SHAKER2020',
//       type: TypeDiscountEnum.FIXED,
//       // amount: 5,
//     },
//     dateSale: '27.03.2024 16:03:22',
//   },
//   {
//     id: 4,
//     name: 'Святой источник',
//     volume: 250,
//     unit: 'ML',
//     price: 150,
//     // discountPrice: 145,
//     methods: [SaleMethod.QR_CODE, SaleMethod.CARD],
//     // promoCode: {
//     //   code: 'SHAKER2020',
//     //   type: TypeDiscountEnum.FIXED,
//     //   // amount: 5,
//     // },
//     dateSale: '27.03.2024 16:03:22',
//   },
// ];

export const automatsList: MachineModelDTO[] = [
  {
    id: 12,
    name: 'Тестовая большая модель',
    maxCells: null,
    maxRows: null,
    cells: [
      {
        cellNumber: 7,
        group: ProductGroup.COFFEE,
        view: ProductView.COFFEE,
      },
      {
        cellNumber: 6,
        group: ProductGroup.CONCENTRATE,
        view: ProductView.SECONDARY,
      },
      {
        cellNumber: 5,
        group: ProductGroup.CONCENTRATE,
        view: ProductView.SECONDARY,
      },
      {
        cellNumber: 3,
        group: ProductGroup.POWDER,
        view: ProductView.PRIMARY,
      },
      {
        cellNumber: 1,
        group: ProductGroup.POWDER,
        view: ProductView.PRIMARY,
      },
      {
        cellNumber: 2,
        group: ProductGroup.POWDER,
        view: ProductView.PRIMARY,
      },
      {
        cellNumber: 4,
        group: ProductGroup.CONCENTRATE,
        view: ProductView.SECONDARY,
      },
    ],
    cellCups: [
      {
        cellNumber: 10,
      },
    ],
    cellWaters: [
      {
        cellNumber: 9,
      },
      {
        cellNumber: 8,
      },
    ],
    cellDisposables: [
      {
        cellNumber: 12,
      },
    ],
  },
  {
    id: 14,
    name: 'модель 1',
    maxCells: null,
    maxRows: null,
    cells: [
      {
        cellNumber: 10,
        group: ProductGroup.POWDER,
        view: ProductView.PRIMARY,
      },
    ],
    cellCups: [],
    cellWaters: [],
    cellDisposables: [],
  },
  {
    id: 26,
    name: 'тест снек матрица',
    maxCells: 12,
    maxRows: 6,
    cells: null,
    cellCups: null,
    cellWaters: null,
    cellDisposables: null,
  },
];

export const machineInformationMock: MachineModelDetailsDTO = {
  id: 18,
  name: 'Проверка бага с количеством порций',
  qtyDosage: 1,
  groupModel: MachineModelGroup.SNACK,
  cells: null,
  cellCups: null,
  cellWaters: null,
  cellDisposables: null,
  maxRows: 12,
  maxCells: 4,
  maxVolume: 250,
  minVolume: 20,
};
