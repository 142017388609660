import { FC } from 'react';
import useSortedSnackMatrix from './hook';
import styles from './MachineStorageSnackMatrix.module.scss';
import MachineStorageSnackMatrixItem from './MachineStorageSnackMatrixItem';
import { MachineStorageSnackMatrixProps } from './types';

/**
 * Матрица с остатками товаров в снековом автомате
 */
const MachineStorageSnackMatrix: FC<MachineStorageSnackMatrixProps> = ({ snackCells }) => {
  const [sortedSnackMatrix, maxMotorsLengths] = useSortedSnackMatrix(snackCells || []);

  // render метод
  const renderGrid = () => {
    return sortedSnackMatrix.map((row, rowIndex) => (
      <div
        key={rowIndex}
        className={styles.gridRow}
        style={{
          gridTemplateColumns: `repeat(${maxMotorsLengths[rowIndex]}, 8px)`,
        }}
      >
        {row.map((cell, cellIndex) => (
          <MachineStorageSnackMatrixItem key={cellIndex} snackCell={cell} />
        ))}
      </div>
    ));
  };

  return <div className={styles.gridContainer}>{renderGrid()}</div>;
};

export default MachineStorageSnackMatrix;
