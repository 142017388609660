import { CreateTelegramNotificationSettingDTO } from '../../../../../types/serverInterface/notificationDTO';
import * as Yup from 'yup';

/**
 * Схема валидации для CreateTelegramNotificationSetting
 */
export const createTelegramNotificationValidationScheme: Yup.ObjectSchema<
  Omit<CreateTelegramNotificationSettingDTO, 'organizationId'>
> = Yup.object().shape({
  chatId: Yup.string().required(),
  botToken: Yup.string().required(),
});
