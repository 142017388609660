import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateTelegramNotificationSettingDTO,
  EditTelegramNotificationSettingDTO,
  TelegramNotificationSettingDTO,
} from '../../types/serverInterface/notificationDTO';
import { api } from '../../app/api';

/**
 * Получение списка настроек телеграм уведомлений по id организации
 *
 * @param organizationId id организации
 */
export const getTelegramNotificationSettingsByOrganizationIdThunk = createAsyncThunk<
  TelegramNotificationSettingDTO[],
  number
>('getTelegramNotificationSettingsByOrganizationId', async (organizationId) => {
  return await api.notification.getTelegramNotificationSettingsByOrganizationId(organizationId);
});

/**
 * Создание настройки телеграм уведомления
 *
 * @param data данные формы
 */
export const createTelegramNotificationSettingThunk = createAsyncThunk<
  void,
  CreateTelegramNotificationSettingDTO
>('createTelegramNotificationSetting', async (data) => {
  return await api.notification.createTelegramNotificationSetting(data);
});

/**
 * Изменения настройки телеграм уведомления
 *
 * @param data данные формы
 * @param settingId id настройки
 */
export const editTelegramNotificationSettingThunk = createAsyncThunk<
  void,
  { data: EditTelegramNotificationSettingDTO; settingId: number }
>('editTelegramNotificationSetting', async ({ data, settingId }) => {
  return await api.notification.editTelegramNotificationSetting(data, settingId);
});

/**
 *
 * Удаление телеграм уведомления
 *
 * @param settingId id телеграм уведомления
 */
export const deleteTelegramNotificationSettingThunk = createAsyncThunk<void, number>(
  'deleteTelegramNotificationSetting',
  async (settingId) => {
    return await api.notification.deleteTelegramNotificationSetting(settingId);
  },
);
