import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconQuestionSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM6.77927 3.83069C6.29328 3.18271 5.27954 3.34722 5.02341 4.11563L4.85576 4.61859C4.79026 4.81507 4.57789 4.92125 4.38141 4.85576C4.18494 4.79026 4.07875 4.57789 4.14424 4.38142L4.3119 3.87846C4.75935 2.5361 6.53029 2.24872 7.37927 3.38069C7.87658 4.04378 7.84189 4.96446 7.29608 5.58824L6.83648 6.1135C6.53898 6.4535 6.375 6.88992 6.375 7.3417V7.5C6.375 7.70711 6.20711 7.875 6 7.875C5.79289 7.875 5.625 7.70711 5.625 7.5V7.3417C5.625 6.70825 5.85492 6.09634 6.27205 5.61962L6.73165 5.09436C7.04408 4.73729 7.06394 4.21026 6.77927 3.83069ZM6 9.5C6.27614 9.5 6.5 9.27614 6.5 9C6.5 8.72386 6.27614 8.5 6 8.5C5.72386 8.5 5.5 8.72386 5.5 9C5.5 9.27614 5.72386 9.5 6 9.5Z"
      fill="black"
    />
  </svg>
);

const IconQuestionSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6666 8.00004C14.6666 11.6819 11.6819 14.6667 7.99998 14.6667C4.31808 14.6667 1.33331 11.6819 1.33331 8.00004C1.33331 4.31814 4.31808 1.33337 7.99998 1.33337C11.6819 1.33337 14.6666 4.31814 14.6666 8.00004ZM9.039 5.10763C8.39103 4.24366 7.03937 4.463 6.69786 5.48754L6.47432 6.15816C6.387 6.42013 6.10384 6.56171 5.84187 6.47438C5.57989 6.38706 5.43831 6.1039 5.52564 5.84193L5.74918 5.17132C6.34578 3.38151 8.70703 2.99833 9.839 4.50763C10.5021 5.39174 10.4558 6.61932 9.72809 7.45102L9.11529 8.15137C8.71862 8.6047 8.49998 9.1866 8.49998 9.78897V10C8.49998 10.2762 8.27612 10.5 7.99998 10.5C7.72384 10.5 7.49998 10.2762 7.49998 10V9.78897C7.49998 8.94438 7.80654 8.12849 8.36271 7.49287L8.97551 6.79252C9.39209 6.31643 9.41857 5.61372 9.039 5.10763ZM7.99998 12.6667C8.36817 12.6667 8.66665 12.3682 8.66665 12C8.66665 11.6319 8.36817 11.3334 7.99998 11.3334C7.63179 11.3334 7.33331 11.6319 7.33331 12C7.33331 12.3682 7.63179 12.6667 7.99998 12.6667Z"
      fill="black"
    />
  </svg>
);

const IconQuestionSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13.5585 7.66138C12.5866 6.36543 10.5591 6.69444 10.0468 8.23126L9.71151 9.23717C9.58053 9.63013 9.15579 9.8425 8.76283 9.71152C8.36987 9.58053 8.1575 9.15579 8.28849 8.76283L8.62379 7.75691C9.5187 5.0722 13.0606 4.49743 14.7585 6.76138C15.7532 8.08755 15.6838 9.92892 14.5922 11.1765L13.673 12.227C13.078 12.907 12.75 13.7798 12.75 14.6834V15C12.75 15.4142 12.4142 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15V14.6834C11.25 13.4165 11.7098 12.1927 12.5441 11.2392L13.4633 10.1887C14.0882 9.47458 14.1279 8.42052 13.5585 7.66138ZM12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19Z"
      fill="black"
    />
  </svg>
);

export const IconQuestion = createIcon({
  name: 'IconQuestion',
  s: IconQuestionSizeS,
  m: IconQuestionSizeM,
  xs: IconQuestionSizeXS,
});
