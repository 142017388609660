import { FC } from 'react';
import { ActivityEmployeeWithKeyElementDTO } from '../../../../../types/serverInterface/employeeWithKeyElementDTO';
import ContentCard from '../../../../../components/ContentCard';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { IconWatchStroked } from '../../../../../assets/icon/IconWatchStroked';
import { IconCalendar } from '../../../../../assets/icon/iconCalendar';
import { DisplayType, displayTypeValue } from '../../../../App';
import styles from './MemberKeyActive.module.scss';
import { IconAutomat } from '../../../../../assets/icon/iconAutomat';
import { useTranslation } from 'react-i18next';

type MemberKeyActiveProps = {
  activity: ActivityEmployeeWithKeyElementDTO;
};

const MemberKeyActive: FC<MemberKeyActiveProps> = ({ activity }) => {
  const { t } = useTranslation();

  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const { day, month, week, lastActivity } = { ...activity };

  const [date, time] = (lastActivity || ' ').split(' ');

  return (
    <ContentCard className={classNames(styles.MemberKeyActive, isLaptop && styles.laptop)}>
      <Text size={isLaptop ? 'l' : 'xl'} weight="semibold">
        {t('organization.employee.key.activity.title')}
      </Text>
      <HorizontalContainer align="center" space={isLaptop ? 's' : 'l'}>
        <VerticalContainer className={styles.activeCard} space="2xs" align="center">
          <Text size={isLaptop ? 'm' : 'l'} view="secondary">
            {t('organization.employee.key.activity.dayActivity.label')}
          </Text>
          <HorizontalContainer justify="center" align="center" space="xs">
            <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
              {day}
            </Text>
            <IconAutomat {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer className={styles.activeCard} space="2xs" align="center">
          <Text size={isLaptop ? 'm' : 'l'} view="secondary">
            {t('organization.employee.key.activity.weekActivity.label')}
          </Text>
          <HorizontalContainer justify="center" align="center" space="xs">
            <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
              {week}
            </Text>
            <IconAutomat {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer className={styles.activeCard} space="2xs" align="center">
          <Text size={isLaptop ? 'm' : 'l'} view="secondary">
            {t('organization.employee.key.activity.monthActivity.label')}
          </Text>
          <HorizontalContainer justify="center" align="center" space="xs">
            <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
              {month}
            </Text>
            <IconAutomat {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer
          className={classNames(styles.activeCard, styles.lastSaleDate)}
          space="2xs"
          align="center"
        >
          <Text size={isLaptop ? 'm' : 'l'} className={styles.text} view="secondary">
            {t('organization.employee.key.activity.lastActivity.label')}
          </Text>
          <HorizontalContainer justify="center" align="center" space="m">
            <HorizontalContainer space="2xs" align="center">
              <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
                {date}
              </Text>
              <IconCalendar className={styles.iconCalendar} {...defaultIconProps} size="s" />
            </HorizontalContainer>
            <HorizontalContainer space="2xs" align="center">
              <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
                {time}
              </Text>
              <IconWatchStroked {...defaultIconProps} size="s" />
            </HorizontalContainer>
          </HorizontalContainer>
        </VerticalContainer>
      </HorizontalContainer>
    </ContentCard>
  );
};

export default MemberKeyActive;
