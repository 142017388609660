import { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  editMachinePricesAction,
  editMachineStorageAction,
  editNewMachineStorage,
  getMachinePricesInfoAction,
} from '../../../../state/machineControl/actions';
import {
  selectMachineBaseInfo,
  selectMachinePricesInfo,
  selectMachineStorageInfo,
  selectNewStorage,
} from '../../../../state/machineControl/selectors';
import MachinePricesTable from './MachinePricesTable';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import styles from './MachinePrices.module.scss';
import { TextFieldPropStatus } from '@consta/uikit/TextField';
import { Dosage } from '../../../../types/serverInterface/storageDTO';
import {
  ConnectionStatus,
  EditMachineCalibrationsDTO,
  EditMachinePrice,
  EditMachineStorageCells,
  EditMachineStorageDTO,
  EditMachineStorageSnackProductCell,
  MachinePrice,
  MachinePriceInfo,
  MachinePricesInfoDTO,
  MachineStorageInfoFormatted,
} from '../../../../types/serverInterface/machineDTO';
import MachineOfflineBlockModal from '../MachineOfflineBlockModal';
import { CustomFormType } from '../../../../types/serverInterface/customForm';
import { useTranslation } from 'react-i18next';

const initialDosage: MachinePrice = {
  id: null,
  volume: null,
  price: null,
  isBlock: false,
};

type Error = {
  status: TextFieldPropStatus;
  label?: string;
};

type DosageError = {
  price?: Error;
  volume?: Error;
};

export type MachinePricesErrors = Record<number, Record<number, DosageError>>;

type MachinePricesProps = {
  machineId: number;
  onIsShowTabsChange: (isShow: boolean) => void;
  cancelEditStorageNextStep: () => void;
};

function processNestedArrayObjects(arr: MachinePriceInfo[]): MachinePriceInfo[] {
  const newArr: MachinePriceInfo[] = [];

  arr.forEach((item) => {
    if (Array.isArray(item.cell) && item.cell.length > 1) {
      item.cell.forEach((cell) => {
        newArr.push({ ...item, cell: [cell] });
      });
    } else {
      newArr.push(item);
    }
  });

  const finalArr = newArr.map((item) => ({
    ...item,
    prices: item.prices.map((item) => ({ ...item, isBlock: !item.volume })),
  }));

  return finalArr;
}

const getProductRowIndexMap = (prices: MachinePriceInfo[]): Record<number, number[]> => {
  const productCellMap: Record<number, number[]> = {};

  prices.forEach(({ ingredientId }, index) => {
    productCellMap[ingredientId]
      ? (productCellMap[ingredientId] = [...productCellMap[ingredientId], index])
      : (productCellMap[ingredientId] = [index]);
  });

  return productCellMap;
};

const generatePrices = ({
  newStorage,
  oldPrices,
}: {
  newStorage: MachineStorageInfoFormatted | null;
  oldStorage: MachineStorageInfoFormatted | null;
  oldPrices: MachinePricesInfoDTO | null;
}): MachinePricesInfoDTO => {
  if (oldPrices && newStorage) {
    const oldPricesByIngredientMap: Record<number, MachinePrice[]> = {};
    const oldPricesByCellMap: Record<number, MachinePrice[]> = {};

    oldPrices.prices.forEach(({ prices, cell, ingredientId }) => {
      cell.forEach((cell) => {
        oldPricesByIngredientMap[ingredientId] = prices;
        oldPricesByCellMap[cell.number] = prices;
      });
    });

    const newPrices: MachinePriceInfo[] = newStorage?.cells
      ?.map(
        ({ cellNumber, view, ingredientId, ingredientName, ingredientLineName, brandName }) => ({
          cell: [{ number: cellNumber, view }],
          prices: oldPricesByCellMap[cellNumber]?.map((item) => ({
            ...item,
            id: ingredientId && oldPricesByIngredientMap[ingredientId] ? item.id : null,
          })),
          ingredientId: ingredientId || 0,
          brandName,
          productLineName: ingredientLineName,
          productName: ingredientName,
        }),
      )
      .filter(({ ingredientId }) => ingredientId);

    return { qtyDosage: oldPrices.qtyDosage, prices: newPrices };
  }

  if (oldPrices) {
    return {
      ...oldPrices,
      prices: processNestedArrayObjects(oldPrices.prices),
    };
  }

  return { qtyDosage: 0, prices: [] };
};

// тут трансформация согласно productRowIndexMap
const getUpdatedStorageWithPrices = (
  storage: MachineStorageInfoFormatted | null,
  prices: MachinePricesInfoDTO,
  productRowIndexMap: Record<number, number[]>,
  calibrations: EditMachineCalibrationsDTO,
): EditMachineStorageDTO => {
  const formattedPrices: MachinePricesInfoDTO = { qtyDosage: prices.qtyDosage, prices: [] };

  for (const key in productRowIndexMap) {
    const firstIndex = productRowIndexMap[key][0];

    formattedPrices.prices.push(prices.prices[firstIndex]);
  }

  const pricesData: EditMachinePrice[] = prices.prices.flatMap((item) =>
    item.prices.flatMap((price): EditMachinePrice[] =>
      item.cell.map((cell) => ({
        ...price,
        ingredientId: item.ingredientId,
        // machineCellId: cell.id,
      })),
    ),
  );

  const storageData: EditMachineStorageCells = storage
    ? {
        cells: storage.cells.map(
          ({ id, minVolume, maxVolume, ingredientId, expirationTimer, isActive }) => ({
            id,
            minVolume,
            maxVolume,
            ingredientId,
            expirationTimer,
            isActive,
          }),
        ),
        cellWaters: [
          {
            id: storage.cellWaters[0].id,
            minVolume: storage.cellWaters[0].minVolume,
            maxVolume: storage.cellWaters[0].maxVolume,
            isCount: true,
            isActive: storage.cellWaters[0].isCount,
            filterValue: null,
            expirationTimer: storage.cellWaters[0].expirationTimer,
          },
          {
            id: storage.cellWaters[0].tapId,
            minVolume: null,
            maxVolume: null,
            isCount: false,
            isActive: !storage.cellWaters[0].isCount,
            filterValue: storage.cellWaters[0].maxVolume,
            expirationTimer: storage.cellWaters[0].filterTimer,
          },
        ],
        cellCups: storage.cellCups.map(({ id, maxVolume, minVolume, cupVolume, isCount }) => ({
          id,
          maxVolume,
          minVolume,
          cupVolume,
          isCount,
        })),
        cellDisposables: storage.cellDisposables.map(({ id, minVolume, isCount, name }) => ({
          id,
          minVolume,
          isCount,
          name,
        })),
        snackCells: [] as EditMachineStorageSnackProductCell[],
      }
    : ({} as EditMachineStorageCells);

  return {
    prices: pricesData.filter(({ volume }) => volume),
    cells: storageData,
    calibrations,
  };
};

const sortMachineStorage = (
  storage: MachinePricesInfoDTO,
): { prices: MachinePricesInfoDTO; productRowIndexMap: Record<number, number[]> } => {
  const sortedPrices = [...storage.prices];
  sortedPrices.sort((a, b) => a.cell[0].number - b.cell[0].number);

  const productRowIndexMap = getProductRowIndexMap(sortedPrices);

  const prices = sortedPrices
    .filter((price) => {
      return price.ingredientId;
    })
    .map((price, index, array) => {
      const { ingredientId } = price;

      const prices = array[productRowIndexMap[ingredientId][0]]?.prices || [];

      const sortedPrices = [...prices];

      sortedPrices.sort((a, b) => (b.volume || 0) - (a.volume || 0));
      // .map((dosage) => ({ ...dosage, isBlock: false }));

      return { ...price, prices: sortedPrices };
    });

  return {
    prices: {
      ...storage,
      prices,
    },

    productRowIndexMap: getProductRowIndexMap(prices),
  };
};

/**
 * Цены и объёмы автомата
 *
 * @param machineId id автомата
 */
const MachinePrices: FC<MachinePricesProps> = ({
  machineId,
  onIsShowTabsChange,
  cancelEditStorageNextStep,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { state: oldPrices } = useAppSelector(selectMachinePricesInfo());
  const newStorage = useAppSelector(selectNewStorage());
  const { state: oldStorage } = useAppSelector(selectMachineStorageInfo());
  const { prices: newPrices, productRowIndexMap } = useMemo(() => {
    const newPrices = sortMachineStorage(generatePrices({ oldPrices, oldStorage, newStorage }));

    return {
      ...newPrices,
      prices: {
        ...newPrices.prices,
        prices: newPrices.prices.prices,
      },
    };
  }, [oldPrices, oldStorage, newStorage]);
  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());

  const [isOpenOfflineBlockModal, setIsOpenOfflineBlockModal] = useState(false);
  const [isEdit, setIsEdit] = useState(Boolean(newStorage));
  const [formState, setFormState] = useState<MachinePricesInfoDTO>(newPrices);
  const [errors, setErrors] = useState<MachinePricesErrors>({});
  const [isViewedWarning, setIsViewedWarning] = useState(false);
  const [calibrations, setCalibrations] = useState<EditMachineCalibrationsDTO>([]);

  useEffect(() => {
    dispatch(getMachinePricesInfoAction(machineId));
  }, [dispatch, machineId]);

  useEffect(() => {
    newPrices && setFormState(newPrices);
  }, [newPrices]);

  useEffect(() => {
    !isEdit && dispatch(editNewMachineStorage(null));
  }, [isEdit, dispatch]);

  if (!formState) return null;

  // Обработчики
  const handleValidateMachinePrices = (): { isError: boolean; isWarning: boolean } => {
    let isError = false;
    let isWarning = false;

    formState.prices.forEach(({ prices }, rowIndex) =>
      prices.forEach(({ price, volume, isBlock }, dosageIndex) => {
        if (isBlock) return null;

        if (price !== null || volume) {
          if (price === 0) {
            isWarning = true;

            setErrors((prevState) => ({
              ...prevState,
              [rowIndex]: {
                ...prevState[rowIndex],
                [dosageIndex]: prevState[rowIndex]?.[dosageIndex]
                  ? {
                      ...prevState[rowIndex][dosageIndex],
                      price: { status: 'warning' },
                    }
                  : {
                      price: { status: 'warning' },
                    },
              },
            }));
          }

          if (price === null) {
            isError = true;

            setErrors((prevState) => ({
              ...prevState,
              [rowIndex]: {
                ...prevState[rowIndex],
                [dosageIndex]: prevState[rowIndex]?.[dosageIndex]
                  ? {
                      ...prevState[rowIndex][dosageIndex],
                      price: { status: 'alert', label: 'Не указано значение' },
                    }
                  : {
                      price: { status: 'alert', label: 'Не указано значение' },
                    },
              },
            }));
          }

          if (!volume) {
            isError = true;

            setErrors((prevState) => ({
              ...prevState,
              [rowIndex]: {
                ...prevState[rowIndex],
                [dosageIndex]: prevState[rowIndex]?.[dosageIndex]
                  ? {
                      ...prevState[rowIndex][dosageIndex],
                      volume: { status: 'alert', label: 'Не указано значение' },
                    }
                  : {
                      volume: { status: 'alert', label: 'Не указано значение' },
                    },
              },
            }));
          }
        }
      }),
    );

    return { isError, isWarning };
  };

  const handleCloseOfflineBlockModal = () => {
    setIsOpenOfflineBlockModal(false);
  };

  const handleSubmit = () => {
    const formattedFormState: MachinePricesInfoDTO = {
      ...formState,
      prices: formState.prices.map((price) => ({
        ...price,
        prices: price.prices.map(({ price, volume, isBlock, id }) => ({
          id,
          isBlock,
          price: isBlock ? null : price,
          volume: isBlock ? null : volume,
        })),
      })),
    };

    onIsShowTabsChange(true);
    if (machineBaseInfo && machineBaseInfo.connectionStatus === ConnectionStatus.OFFLINE) {
      setIsOpenOfflineBlockModal(true);
      return null;
    }

    if (newStorage) {
      return dispatch(
        editMachineStorageAction(
          machineId,
          getUpdatedStorageWithPrices(
            newStorage,
            formattedFormState,
            productRowIndexMap,
            calibrations,
          ),
        ),
      ).then(() => {
        dispatch(getMachinePricesInfoAction(machineId));
        setIsEdit(false);
        setIsOpenOfflineBlockModal(false);
      });
    }
    const pricesData = formattedFormState.prices
      .flatMap((product) =>
        product.prices.map((price) => ({ ...price, ingredientId: product.ingredientId })),
      )
      .filter(({ volume }) => volume);

    dispatch(editMachinePricesAction(machineId, { prices: pricesData, calibrations })).then(() => {
      dispatch(getMachinePricesInfoAction(machineId));
      setIsOpenOfflineBlockModal(false);
      handleEditCancel();
    });
  };

  const handleEditClick = () => {
    setIsEdit(true);
    onIsShowTabsChange(false);
  };

  // TODO: тут нужно внести изменения. Требуется опционально сделать возвращение на страницу настроек автомата. При
  //  том нужно не показывать табы
  const handleEditCancel = () => {
    setIsEdit(false);
    onIsShowTabsChange(true);
    if (newStorage) {
      cancelEditStorageNextStep();
    }
  };

  const handleEditSave = () => {
    const { isWarning, isError } = handleValidateMachinePrices();
    if (isError) {
      return console.log('error');
    }

    if (isWarning) {
      if (!isViewedWarning) {
        setIsViewedWarning(true);

        return console.log('showWarning');
      }
    }
    setIsViewedWarning(false);

    return handleSubmit();
  };

  const handleDosageChange =
    (rowIndex: number) =>
    (updatedDosageIndex: number) =>
    (key: keyof Dosage) =>
    (value: number | null) => {
      const productId = newPrices.prices?.[rowIndex]?.ingredientId || null;

      if (!productId) return null;

      const rowIndexList = productRowIndexMap[productId] || null;

      if (!rowIndexList) return null;

      rowIndexList.forEach((rowIndex) => {
        setErrors((prevState) => ({
          ...prevState,
          [rowIndex]: {
            ...prevState[rowIndex],
            [updatedDosageIndex]: prevState[rowIndex]?.[updatedDosageIndex] && {
              ...prevState[rowIndex][updatedDosageIndex],
              [key]: null,
            },
          },
        }));

        const defaultDosage = { ...initialDosage, [key]: value };

        setFormState((prevState) => {
          const updatedPrices = prevState.prices.map((item, index) => {
            if (index === rowIndex) {
              if (!item.prices || item.prices.length === 0) {
                const defaultPrices = new Array(defaultDosage);
                defaultPrices.fill(defaultDosage);
                return { ...item, prices: defaultPrices };
              }

              const updatedItem = {
                ...item,
                prices: item.prices.map((price, dosageIndex) => {
                  if (dosageIndex === updatedDosageIndex) {
                    return {
                      ...(prevState.prices[rowIndex].prices[dosageIndex] || defaultDosage),
                      [key]: value,
                    };
                  }
                  return price;
                }),
              };
              return updatedItem;
            }
            return item;
          });

          return { ...prevState, prices: updatedPrices };
        });
      });
    };

  const handleConfigChange =
    ({
      purposeConfig,
      categoryConfig,
    }: {
      categoryConfig: CustomFormType;
      purposeConfig: CustomFormType;
    }) =>
    (ingredientId: number) => {
      setCalibrations((prevState) => {
        const index = prevState.findIndex((item) => item.ingredientId === ingredientId);

        if (index !== -1) {
          // Если есть индекс, то необходимо изменить элемент массива
          const updatedCalibrations = [...prevState];
          updatedCalibrations[index] = {
            ...updatedCalibrations[index],
            purposeConfigMachine: purposeConfig,
            categoryConfigMachine: categoryConfig,
          };

          return updatedCalibrations;
        } else {
          // Если индекс не найден, то необходимо добавить элемент в конец массива
          return [
            ...prevState,
            {
              ingredientId,
              purposeConfigMachine: purposeConfig,
              categoryConfigMachine: categoryConfig,
            },
          ];
        }
      });
    };

  const handleIsBlockChange =
    (rowIndex: number) => (updatedDosageIndex: number) => (checked: boolean) => {
      // console.table({ rowIndex, updatedDosageIndex, checked });

      const productId = newPrices.prices?.[rowIndex]?.ingredientId || null;

      if (!productId) return null;

      const rowIndexList = productRowIndexMap[productId] || null;

      if (!rowIndexList) return null;

      rowIndexList.forEach((rowIndex) => {
        setErrors((prevState) => ({
          ...prevState,
          [rowIndex]: {
            ...prevState[rowIndex],
            [updatedDosageIndex]: prevState[rowIndex]?.[updatedDosageIndex] && {
              ...prevState[rowIndex][updatedDosageIndex],
              price: undefined,
              volume: undefined,
            },
          },
        }));

        const defaultDosage = { ...initialDosage, isBlock: checked };

        rowIndexList.forEach((rowIndex) => {
          setFormState((prevState) => {
            const updatedPrices = prevState.prices.map((item, index) => {
              if (index === rowIndex) {
                if (!item.prices || item.prices.length <= updatedDosageIndex) {
                  if (item.prices.length === 0) {
                    const defaultPrices = new Array(defaultDosage);
                    defaultPrices.fill(defaultDosage);
                    return { ...item, prices: defaultPrices };
                  }

                  const prices = item.prices;

                  prices.push(defaultDosage);

                  return { ...item, prices };
                }

                const updatedItem = {
                  ...item,
                  prices: item.prices.map((price, dosageIndex) => {
                    if (dosageIndex === updatedDosageIndex) {
                      return {
                        ...(prevState.prices[rowIndex].prices[dosageIndex] || defaultDosage),
                        isBlock: checked,
                      };
                    }
                    return price;
                  }),
                };

                return updatedItem;
              }
              return item;
            });

            return { ...prevState, prices: updatedPrices };
          });
        });
      });
    };

  // render методы
  const renderInfoAction = () => (
    <>
      <Button
        label={t('machineControl.machine.prices.edit.button.label')}
        onClick={handleEditClick}
      />
    </>
  );

  const renderEditAction = () => (
    <>
      <Button
        view="clear"
        label={t('machineControl.machine.prices.back.button.label')}
        onClick={handleEditCancel}
      />
      <Button
        label={t('machineControl.machine.prices.save.button.label')}
        onClick={handleEditSave}
      />
    </>
  );

  const renderAction = () => {
    if (isEdit) {
      return renderEditAction();
    }

    return renderInfoAction();
  };

  return (
    <div className={styles.MachinePrices}>
      <MachineOfflineBlockModal
        isOpen={isOpenOfflineBlockModal}
        onCancel={handleCloseOfflineBlockModal}
      />
      <MachinePricesTable
        calibrations={calibrations}
        machineId={machineId}
        machinePrices={formState}
        isEdit={isEdit}
        errors={errors}
        onDosageChange={handleDosageChange}
        onIsBlockChange={handleIsBlockChange}
        onConfigChange={handleConfigChange}
      />
      <div className={styles.actions}>{renderAction()}</div>
    </div>
  );
};

export default MachinePrices;
