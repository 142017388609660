import * as Yup from 'yup';
import { EditMachineStorageSnackProductCell } from '../../../../types/serverInterface/machineDTO';

/**
 * Валидация изменения productId и snackId в одной ячейке на странице наполнения снек автомата
 */
const editSnackFillingCell: Yup.ObjectSchema<EditMachineStorageSnackProductCell> =
  Yup.object().shape({
    id: Yup.number().required(),
    brandId: Yup.number().nullable().defined(),
    productId: Yup.number()
      .nullable()
      .defined()
      .test(function (value) {
        const { brandId } = this.parent;
        return (value === null && brandId === null) || (value !== null && brandId !== null);
      }),
    maxVolume: Yup.number().required().min(1),
    warningVolume: Yup.number().required(),
    minVolume: Yup.number().required(),
    isManuallyDisabled: Yup.boolean().required(),
  });
/**
 * Валидация изменения productId и snackId во всей схеме на странице наполнения снек автомата
 */
export const editSnackFillingValidationScheme = Yup.array().of(
  Yup.array().of(editSnackFillingCell),
);
