import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import IntegrationBaseInfo from './IntegrationBaseInfo';

/**
 * Базовая страница интеграции
 */
const IntegrationPage: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<IntegrationBaseInfo />} />
    </Routes>
  );
};

export default IntegrationPage;
