import { FC } from 'react';
import classNames from 'classnames';
import StorageSchemeCard from './StorageSchemeCard';
import styles from './StorageSchemeGroup.module.scss';
import { MachineStoragePageTypeOld } from '../MachineStorage';
import { SeparatedMachineHomeStorageUnionType, SeparatedMachineStorageUnionType } from '../types';
import { MachineCellGroupUnion } from '../../../../types/serverInterface/machineDTO';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента StorageSchemeGroup
 */
type StorageSchemeProps = {
  /**
   * Тип страницы
   */
  pageType: MachineStoragePageTypeOld;
  /**
   * Список ячеек группы
   */
  storage: SeparatedMachineStorageUnionType | SeparatedMachineHomeStorageUnionType;
  /**
   * Группа
   */
  group: MachineCellGroupUnion;
  /**
   * Флаг горизонтального расположения
   */
  isHorizontal?: boolean;
  /**
   * Обработчик нажатия на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onClick?: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчик hover на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHover?: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчики hover leave на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHoverLeave?: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчик пополнения контейнера
   */
  onValueChange?: (
    group: MachineCellGroupUnion,
  ) => (index: number) => (value: number | string | null) => void;
};

/**
 * Группа ячеек в схема склада автомата
 */
const StorageSchemeGroup: FC<StorageSchemeProps> = ({
  pageType,
  storage,
  group,
  isHorizontal = false,
  onClick = () => () => {
    null;
  },
  onHover = () => () => {
    null;
  },
  onHoverLeave = () => () => {
    null;
  },
  onValueChange = () => () => () => {
    null;
  },
}) => {
  const isRefill = pageType === MachineStoragePageTypeOld.REFILL;

  const { t } = useTranslation();

  // Обработчики
  const handleClick = (index: number) => () => {
    pageType !== MachineStoragePageTypeOld.REFILL && onClick(group)(index);
  };

  const handleHover = (index: number) => () => {
    onHover(group)(index);
  };

  const handleHoverLeave = (index: number) => () => {
    onHoverLeave(group)(index);
  };

  const handleValueChange = (index: number) => onValueChange(group)(index);

  return (
    <div className={classNames(styles.storageSchemeGroup, isRefill && styles.isRefill)}>
      <Text
        weight="semibold"
        size="m"
        className={classNames(styles.text, styles[(storage[0]?.info as any)?.view])}
      >
        {t(`machineControl.storage.${group}`)}
      </Text>
      <div className={classNames(styles.cellsContainer, isRefill && styles.isRefill)}>
        {storage?.map((storage, index) => (
          <StorageSchemeCard
            key={index}
            pageType={pageType}
            storageCell={storage}
            isHorizontal={isHorizontal}
            cellGroup={group}
            onClick={handleClick(index)}
            onHover={handleHover(index)}
            onHoverLeave={handleHoverLeave(index)}
            onValueChange={handleValueChange(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default StorageSchemeGroup;
