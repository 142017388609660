import { FC, useState } from 'react';
import DefaultModal from '../../../DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { TextField } from '@consta/uikit/TextField';
import {
  EditMachineBaseInfoDTO,
  MachineBaseInfoDTO,
} from '../../../../types/serverInterface/machineDTO';
import { useAppDispatch } from '../../../../app/hooks/store';
import { editMachineBaseInfoAction } from '../../../../state/machineControl/actions';
import { useParams } from 'react-router-dom';
import styles from './MachineNameEdit.module.scss';
import { useTranslation } from 'react-i18next';

type MachineNameEdit = {
  machineBaseInfo: MachineBaseInfoDTO;
  onClose: () => void;
};

const MachineNameEdit: FC<MachineNameEdit> = ({ machineBaseInfo, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { machineId } = useParams<{ machineId: string }>();
  const machineIdFormatted = Number(machineId);

  const [form, setForm] = useState<EditMachineBaseInfoDTO>({
    name: machineBaseInfo.machine.name,
    description: '',
  });

  // Обработчики
  const handleSubmit = () => {
    dispatch(editMachineBaseInfoAction(machineIdFormatted, form)).then(() => onClose());
  };

  const handleNameChange = ({ value }: { value: string | null }) => {
    setForm((prevState) => ({ ...prevState, name: value || '' }));
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        size="l"
        view="clear"
        label={t('machineControl.machine.name.edit.cancel.button.label')}
        onClick={onClose}
      />
      <Button
        size="l"
        label={t('machineControl.machine.name.edit.save.button.label')}
        onClick={handleSubmit}
      />
    </>
  );

  return (
    <DefaultModal
      className={styles.MachineNameEdit}
      modalTitle={t('machineControl.machine.name.edit.modal.title')}
      onClose={onClose}
      renderActions={renderActions}
    >
      <TextField
        size="l"
        className={styles.nameInput}
        label={t('machineControl.machine.name.edit.machineName.input.label')}
        value={form.name}
        onChange={handleNameChange}
      />
    </DefaultModal>
  );
};

export default MachineNameEdit;
