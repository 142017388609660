import { ClassicTableRowProps, Column } from '../../types';
import { useMemo } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import classNames from 'classnames';
import styles from './ClassicTableRow.module.scss';
import { Checkbox } from '@consta/uikit/Checkbox';

/**
 * Строка таблицы
 */
const ClassicTableRow = <T extends Record<string, any>, K extends keyof T>({
  withCheckbox,
  row,
  columns,
  rowIndex,
  onRowClick,
}: ClassicTableRowProps<T, K>) => {
  const { rowData } = useMemo(() => {
    const rowData: (Column<T, K> & { cell: React.ReactNode })[] = [];
    let count = 0;

    for (const key in columns) {
      if (columns.hasOwnProperty(key)) {
        rowData.push({
          ...columns[key],
          cell: columns[key]?.renderCell?.(row, rowIndex) || (
            <Text
              size="m"
              className={classNames(styles.text, columns[key].isLongText && styles.longText)}
            >
              {columns[key].getItemLabel?.(row)}
            </Text>
          ),
        });
        count++;
      }
    }

    return { rowData, count };
  }, [row, columns, rowIndex]);

  return (
    <tr className={styles.ClassicTableRows}>
      {withCheckbox && (
        <td className={classNames(styles.cell, styles.checkboxContainer)}>
          <Checkbox checked={false} />
        </td>
      )}
      {rowData.map(({ cell, alignment, className, fullWidth }, index) => (
        <td
          key={index}
          className={classNames(
            styles.cell,
            alignment == 'left' && styles.alignLeft,
            alignment == 'right' && styles.alignRight,
            fullWidth && styles.fullWidth,
            className,
          )}
        >
          {cell}
        </td>
      ))}
    </tr>
  );
};

export default ClassicTableRow;
