import { CreateTelegramNotificationSettingDTO } from '../../../../../types/serverInterface/notificationDTO';

/**
 * Начальное значение схемы валидации в CreateTelegramNotificationSetting
 */
export const initialFormState: CreateTelegramNotificationSettingDTO = {
  chatId: '',
  organizationId: null,
  botToken: '',
};
