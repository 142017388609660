import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconFunnelFilledSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M3.16536 1C1.98007 1 1 1.9363 1 3.11463C1 3.71132 1.25789 4.27785 1.7063 4.67717L3.37429 6.16256C3.82366 6.56274 4.07692 7.12505 4.07692 7.71153V9.85402C4.07692 10.8086 5.17204 11.3176 5.92767 10.7837L6.92319 10.0803C7.38776 9.75211 7.66667 9.22428 7.66667 8.65817V7.77106C7.66667 7.15568 7.94545 6.56833 8.43411 6.16663L10.2394 4.68263C10.7199 4.28769 11 3.70431 11 3.08639C11 1.92365 10.0329 1 8.86371 1H3.16536Z" />
  </svg>
);

const IconFunnelFilledSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M4.31497 1.5C2.77409 1.5 1.5 2.71705 1.5 4.24871C1.5 5.02432 1.83526 5.76072 2.41819 6.27978L4.58658 8.21058C5.17076 8.73074 5.5 9.46167 5.5 10.224V13.0089C5.5 14.2497 6.92365 14.9114 7.90597 14.2174L9.20015 13.3031C9.80409 12.8765 10.1667 12.1904 10.1667 11.4545V10.3014C10.1667 9.50149 10.5291 8.73801 11.1643 8.21587L13.5112 6.28688C14.1358 5.77352 14.5 5.01521 14.5 4.212C14.5 2.70062 13.2428 1.5 11.7228 1.5H4.31497Z" />
  </svg>
);

const IconFunnelFilledSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M6.47246 3.25C4.16114 3.25 2.25 5.07558 2.25 7.37307C2.25 8.53648 2.75289 9.64108 3.62728 10.4197L6.87987 13.3159C7.75613 14.0961 8.25 15.1925 8.25 16.336V20.5134C8.25 22.3745 10.3855 23.367 11.859 22.3261L13.8002 20.9547C14.7061 20.3147 15.25 19.2855 15.25 18.1817V16.4521C15.25 15.2522 15.7936 14.107 16.7465 13.3238L20.2668 10.4303C21.2037 9.66027 21.75 8.52281 21.75 7.318C21.75 5.05092 19.8642 3.25 17.5842 3.25H6.47246Z" />
  </svg>
);

export const IconFunnelFilled = createIcon({
  name: 'IconFunnelFilled',
  xs: IconFunnelFilledSizeXS,
  s: IconFunnelFilledSizeS,
  m: IconFunnelFilledSizeM,
});
