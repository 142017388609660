import { FC, useEffect, useState } from 'react';
import { MachineSnackRefillProps } from './types';
import { useMachineSnackRefill } from './hooks';
import MachineSnackRefillTable from './MachineSnackRefillTable';
import styles from './MachineSnackRefill.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import classNames from 'classnames';
import {
  ConnectionStatus,
  MachineCellGroup,
  RefillMachineCell,
} from '../../../../types/serverInterface/machineDTO';
import { refillMachineStorageAction } from '../../../../state/machineControl/actions';
import { IconSave } from '../../../../assets/icon/iconSave';
import MachineStorageSnackScheme from '../../../../components/Machine/MachineStorage/MachineStorageSnackScheme';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../components/VerticalContainer';
import { DisplayType, displayTypeValue } from '../../../App';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { useFormik } from 'formik';
import { refillMachineStorageArraySchema } from './validationScheme';
import MachineOfflineBlockModal from '../MachineOfflineBlockModal';
import { selectMachineBaseInfo } from '../../../../state/machineControl/selectors';

/**
 * Страница с остатками для снек автомата
 */
const MachineSnackRefill: FC<MachineSnackRefillProps> = ({ machineId }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;
  const dispatch = useAppDispatch();

  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());

  const [snackCellsGroupedByRowNumber, maxMotorsLengths] = useMachineSnackRefill(machineId);
  const [snackRefillTable, setSnackRefillTable] = useState(() =>
    snackCellsGroupedByRowNumber.map((row) => row.map((cell) => ({ ...cell }))),
  );
  const [isOpenOfflineBlockModal, setIsOpenOfflineBlockModal] = useState(false);

  const refillMachineCells = useFormik<RefillMachineCell[]>({
    initialValues: snackRefillTable.flat().map((cell) => ({
      id: cell.id,
      volume: cell.volume,
      group: MachineCellGroup.SNACK,
    })),
    validationSchema: refillMachineStorageArraySchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (
      refillMachineCells.values.length === 0 &&
      JSON.stringify(snackCellsGroupedByRowNumber) !== JSON.stringify(snackRefillTable)
    ) {
      const updatedValues = snackCellsGroupedByRowNumber.flat().map((cell) => ({
        id: cell.id,
        volume: cell.volume,
        group: MachineCellGroup.SNACK,
      }));
      refillMachineCells.setValues(updatedValues);
      setSnackRefillTable(
        snackCellsGroupedByRowNumber.map((row) => row.map((cell) => ({ ...cell }))),
      );
    }
  }, [snackCellsGroupedByRowNumber, refillMachineCells, snackRefillTable]);

  // Обработчики
  const handleChangeVolume = (id: number) => (newVolume: number) => {
    setSnackRefillTable((prev) =>
      prev.map((row) =>
        row.map((cell) => (cell.id === id ? { ...cell, volume: newVolume } : cell)),
      ),
    );
    const cellIndex = refillMachineCells.values.findIndex((cell) => cell.id === id);
    refillMachineCells.setFieldValue(`values[${cellIndex}].volume`, newVolume);
  };

  const handleSubmit = (values: RefillMachineCell[]): void => {
    if (machineBaseInfo && machineBaseInfo.connectionStatus === ConnectionStatus.OFFLINE) {
      setIsOpenOfflineBlockModal(true);
      return;
    }
    dispatch(refillMachineStorageAction(values, machineId));
  };

  const handleCloseOfflineBlockModal = () => {
    setIsOpenOfflineBlockModal(false);
  };

  // render методы
  const renderActions = () => (
    <div className={styles.buttonContainer}>
      <Button
        view="primary"
        size="m"
        onlyIcon
        iconLeft={IconSave as any}
        onClick={refillMachineCells.submitForm}
      />
    </div>
  );

  const renderSnackRefillTable = () => (
    <MachineSnackRefillTable
      snackRefillTable={snackRefillTable}
      onChangeVolume={handleChangeVolume}
    />
  );

  const renderSnackRefillScheme = () => (
    <MachineStorageSnackScheme
      snackSchemeStorage={snackRefillTable}
      maxMotorsLengths={maxMotorsLengths}
    />
  );

  const renderModal = () => (
    <MachineOfflineBlockModal
      isOpen={isOpenOfflineBlockModal}
      onCancel={handleCloseOfflineBlockModal}
    />
  );

  return (
    <form onSubmit={refillMachineCells.handleSubmit} className={styles.MachineSnackRefill}>
      {renderModal()}
      <HorizontalContainer
        space={isLaptop ? '3xl' : '5xl'}
        className={classNames(styles.MachineSnackRefillContent, isLaptop && styles.laptop)}
      >
        <VerticalContainer space={isLaptop ? '3xl' : '5xl'} className={styles.leftSide}>
          {renderSnackRefillTable()}
        </VerticalContainer>
        <VerticalContainer space={isLaptop ? '3xl' : '5xl'} className={styles.rightSide}>
          {renderSnackRefillScheme()}
        </VerticalContainer>
      </HorizontalContainer>
      {renderActions()}
    </form>
  );
};

export default MachineSnackRefill;
