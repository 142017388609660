import styles from './TableColumns.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import classNames from 'classnames';
import { Button } from '@consta/uikit/Button';
import { IconSortUp } from '@consta/uikit/IconSortUp';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import { ClassicTableHeaderProps, Column } from '../../ClassicTable/types';
import { SortDirection } from '../../../types/enums/sortDirection';
import HorizontalContainer from '../../HorizontalContainer';
import { IconSortDown } from '../../../assets/icon/iconSortDown';
import { IconSort } from '../../../assets/icon/iconSort';

function convertRecordToArray<T extends Record<string, any>, K extends keyof T>(
  columns: Record<K, Column<T, K>>,
) {
  const columnsList: {
    key: K;
    title: string;
    alignment: 'left' | 'right';
    sortable?: boolean;
    isNoRender?: boolean;
    className?: string;
    width?: string;
  }[] = [];
  let count = 0;

  for (const key in columns) {
    if (columns.hasOwnProperty(key)) {
      if (!columns[key]?.multi) {
        const column = {
          key,
          title: columns[key].title,
          alignment: columns[key].alignment || 'left',
          sortable: columns[key].sortable,
          isNoRender: columns[key].isNoRender,
          className: columns[key].className,
          width: columns[key].width,
        };
        columnsList.push(column);
        count++;
      } else {
        const columnCount = columns[key].count as number;

        for (let i = 0; i < columnCount; i++) {
          const column = {
            key,
            title: columns[key].title + ' ' + (i + 1),
            alignment: columns[key].alignment || 'left',
            sortable: columns[key].sortable,
            isNoRender: columns[key].isNoRender,
            className: columns[key].className,
            width: columns[key].width,
          };
          columnsList.push(column);
        }

        count = count + columnCount;
      }
    }
  }

  return { columnsList, count };
}

/**
 * Компонент шапки таблицы вида "карточки"
 *
 * @param columns колонки таблицы
 * @param sortOrder направление сортировки для столбцов
 * @param onSortClick обработчик изменения направления сортировки
 */
const TableColumns = <T extends Record<string, any>, K extends keyof T>({
  columns,
  sortOrder,
  onSortClick = () => {
    null;
  },
}: ClassicTableHeaderProps<T, K>) => {
  const { columnsList } = convertRecordToArray(columns);

  const handleSortClick = (key: K) => () => {
    if (sortOrder?.[key] === SortDirection.ASC) {
      onSortClick({ ...sortOrder, [key]: SortDirection.DESC });
    } else if (sortOrder?.[key] === SortDirection.DESC) {
      onSortClick({ ...sortOrder, [key]: null });
    } else {
      onSortClick({ ...(sortOrder as any), [key]: SortDirection.ASC });
    }
  };

  return (
    <tr className={styles.TableHeader}>
      {columnsList
        .filter((data) => !data.isNoRender)
        .map((data) => (
          <th
            key={String(data.title)}
            style={{ width: data.width }}
            className={classNames(styles.columnTitle, data.className)}
          >
            <HorizontalContainer
              justify={data.alignment === 'right' ? 'end' : 'start'}
              align="center"
              space="2xs"
            >
              <Text size="m" view="secondary">
                {String(data.title)}
              </Text>
              {data.sortable && (
                <Button
                  className={styles.buttonSort}
                  size="l"
                  onlyIcon
                  view="clear"
                  iconLeft={() => {
                    switch (sortOrder?.[data.key]) {
                      case 'asc':
                        return (<IconSortUp {...defaultIconProps} />) as any;
                      case 'desc':
                        return (<IconSortDown {...defaultIconProps} />) as any;
                      default:
                        return (
                          <IconSort className={styles.iconSort} {...defaultIconProps} />
                        ) as any;
                    }
                  }}
                  onClick={handleSortClick(data.key as K)}
                />
              )}
            </HorizontalContainer>
          </th>
        ))}
    </tr>
  );
};

export default TableColumns;
