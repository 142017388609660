import React, { FC, useEffect } from 'react';
import styles from '../../pages/App.module.scss';
import { useAppDispatch } from '../../app/hooks/store';
import UserSetting from './UserSetting';
import { getCabinetAction } from '../../state/user/actions';
import classNames from 'classnames';
import PageNavigate from './PageNavigate';
import { getOrganizationByIdAction } from '../../state/organization/actions';
import { getOrganizationId } from '../../helpers/getOrganizationId';
import { DisplayType, displayTypeValue } from '../../pages/App';
import { checkVendistaIntegrationAction } from '../../state/vendista/action';

/**
 * Возможные темы
 */
export enum ThemeName {
  gpnDefault = 'gpnDefault',
  gpnDark = 'gpnDark',
}

/**
 * Возможные языки
 */
export enum LanguageName {
  ru = 'ru',
  en = 'en',
}

/**
 * Свойства компонента TopMenu
 */
type TopMenuProps = {
  /**
   * Выбранная тема
   */
  theme: ThemeName;
  /**
   * Выбранный язык
   */
  language: LanguageName;
  /**
   * Обработчик изменения темы
   *
   * @param value новое значение темы
   */
  onThemeChange: ({ value }: { value: ThemeName }) => void;
  /**
   * Обработчик изменения языка
   *
   * @param value новое значение языка
   */
  onLanguageChange: ({ value }: { value: LanguageName }) => void;
};

/**
 * Верхнее меню
 */
const TopMenu: FC<TopMenuProps> = ({ theme, language, onThemeChange, onLanguageChange }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const dispatch = useAppDispatch();
  const organizationId = getOrganizationId();

  useEffect(() => {
    dispatch(getCabinetAction());
  }, [dispatch]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getOrganizationByIdAction(organizationId));
      dispatch(checkVendistaIntegrationAction(organizationId));
    }
  }, [dispatch, organizationId]);

  return (
    <div className={classNames(classNames(styles.contextMenuAction, isLaptop && styles.isLaptop))}>
      <PageNavigate />
      <UserSetting
        theme={theme}
        language={language}
        onThemeChange={onThemeChange}
        onLanguageChange={onLanguageChange}
      />
    </div>
  );
};

export default TopMenu;
