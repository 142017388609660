import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  deleteEmployeeAction,
  getEmployeesListAction,
} from '../../../../state/organization/actions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import TableCardWithBackground from '../../../../components/TableCardWithBackground';
import { EmployeeDTO } from '../../../../types/serverInterface/employeeDTO';
import { Roles } from '../../../../types/serverInterface/cabinetDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { useTranslation } from 'react-i18next';
import { IconTrash } from '../../../../assets/icon/iconTrash';
import DeleteMember from './DeleteMember';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { IconProfileAccepted } from '../../../../assets/icon/iconProfileAccepted';
import VerticalContainer from '../../../../components/VerticalContainer';
import CreateMember from './CreateMember';
import { selectEmployeesList } from '../../../../state/organization/selectors';

/**
 * Свойства компонента MembersTable
 */
type MembersTableProps = {
  /**
   * id организации
   */
  organizationId: number;
};

/**
 * Таблица со списком сотрудников организации
 */
const MembersTable: FC<MembersTableProps> = ({ organizationId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const employeesList = useAppSelector(selectEmployeesList());

  const [deletableMember, setDeletableMember] = useState<EmployeeDTO | null>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  useEffect(() => {
    organizationId && dispatch(getEmployeesListAction(organizationId));
  }, [dispatch, organizationId]);

  // Обработчики
  const handleDelete = () => {
    setIsDeleteLoading(true);
    deletableMember &&
      dispatch(
        deleteEmployeeAction({ organizationId, userUuid: deletableMember.uuid }, organizationId),
      ).then(() => {
        setDeletableMember(null);
        setIsDeleteLoading(false);
      });
  };

  const filterEmployeesList = useMemo(() => {
    return employeesList.filter(({ name, surname, patronymic }) =>
      `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, employeesList]);

  const handleCancelDeleteClick = () => {
    setDeletableMember(null);
  };

  const handleDeleteClick = (member: EmployeeDTO) => {
    setDeletableMember(member);
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  // render методы
  const renderTableActions = () => (
    <HorizontalContainer space="xl">
      <Button
        label={t('organization.members.signUp.button.label')}
        iconLeft={IconProfileAccepted as any}
        onClick={() => setIsCreateOpen(true)}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </HorizontalContainer>
  );

  return (
    <VerticalContainer space="3xl">
      <CreateMember isOpen={isCreateOpen} onClose={() => setIsCreateOpen(false)} />
      {renderTableActions()}
      <DeleteMember
        isDeleteLoading={isDeleteLoading}
        member={deletableMember}
        onClose={handleCancelDeleteClick}
        onDelete={handleDelete}
      />
      <TableCardWithBackground
        rows={filterEmployeesList}
        columns={{
          name: {
            key: 'name',
            title: t('organization.members.table.fullName.column.title'),
            alignment: 'left',
            width: '28%',
            getItemLabel: ({ name, surname, patronymic }) =>
              `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`,
          },
          position: {
            key: 'position',
            title: t('organization.members.table.role.column.title'),
            alignment: 'left',
            width: '25%',
            getItemLabel: ({ position }) => t(`roles.${position}`),
          },
          email: {
            key: 'email',
            title: t('organization.members.table.email.column.title'),
            alignment: 'left',
            width: '28%',
            getItemLabel: ({ email }) => email || '',
          },
          phone: {
            key: 'phone',
            title: t('organization.members.table.phone.column.title'),
            alignment: 'left',
            width: '11%',
            getItemLabel: ({ phone }) => phone,
          },
          uuid: {
            key: 'uuid',
            title: '',
            alignment: 'left',
            width: '6%',
            renderCell: (data) => (
              <HorizontalContainer space="s">
                {data.position !== Roles.ORGANIZATION_OWNER && (
                  <Button
                    onlyIcon
                    iconLeft={IconTrash as any}
                    view="clear"
                    size="m"
                    onClick={() => handleDeleteClick(data)}
                  />
                )}
              </HorizontalContainer>
            ),
          },
        }}
      />
    </VerticalContainer>
  );
};

export default MembersTable;
