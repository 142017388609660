import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from '../state/auth';
import { organizationReducer } from '../state/organization/slice';
import { userReducer } from '../state/user/slice';
import { productBaseReducer } from '../state/productBase/slice';
import { machineControlReducer } from '../state/machineControl/slice';
import { promoCodeReducer } from '../state/promoCode/slice';
import { telemetryNotificationReducer } from '../state/telemetryNotification/slice';
import { vendistaReducer } from '../state/vendista/slice';
import { frontNotificationReducer } from '../state/frontNotification/slice';

export const store = configureStore({
  devTools: true,

  reducer: {
    auth: authReducer,
    organization: organizationReducer,
    user: userReducer,
    productBase: productBaseReducer,
    machineControl: machineControlReducer,
    promoCode: promoCodeReducer,
    telemetryNotification: telemetryNotificationReducer,
    frontNotification: frontNotificationReducer,
    vendista: vendistaReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
