import { FC, useState } from 'react';
import { useAppSelector } from '../../../../../app/hooks/store';
import {
  selectMachineBaseInfo,
  selectMachineSettings,
} from '../../../../../state/machineControl/selectors';
import styles from './MachineConnect.module.scss';
import ContentCard from '../../../../../components/ContentCard';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import MachineRegistrationModal from '../../../MachineList/MachineListHeader/MachineRegistrationModal';
import { IconAutomat } from '../../../../../assets/icon/iconAutomat';
import { useTranslation } from 'react-i18next';

/**
 * Подключение автомата к телеметрии
 */
const MachineConnect: FC = () => {
  const { t } = useTranslation();

  const { state: machineSettings } = useAppSelector(selectMachineSettings());
  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());

  const [isOpenKey, setIsOpenKey] = useState(false);

  // Обработчики
  const handleOpenKey = () => {
    setIsOpenKey(true);
  };

  const handleCloseKey = () => {
    setIsOpenKey(false);
  };

  return (
    <ContentCard className={styles.MachineConnect}>
      <MachineRegistrationModal isOpenKey={isOpenKey} onClose={handleCloseKey} />
      <HorizontalContainer space="l" align="center">
        <IconAutomat size="m" {...defaultIconProps} />
        <VerticalContainer space="2xs">
          <Text size="m" weight="medium">
            {t('machineControl.machine.settings.connect.connectMachine.label')}
          </Text>
          <Text size="m" weight="regular" view="secondary">
            {t('machineControl.machine.settings.connect.operateMachine.label')}
          </Text>
        </VerticalContainer>
      </HorizontalContainer>
      <Button
        size="s"
        view="clear"
        className={styles.connectButton}
        label={t('machineControl.machine.settings.connect.connect.button.label')}
        onClick={handleOpenKey}
      />
    </ContentCard>
  );
};

export default MachineConnect;
